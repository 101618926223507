import React, { useEffect, useRef } from 'react';
import { models, factories, service, IEmbedConfiguration } from 'powerbi-client';

interface MyPowerBIProps {
  reportId: string;
  embedUrl: string;
  accessToken: string;
  width: string;
  height: string;
}

const PowerBIReport: React.FC<MyPowerBIProps> = ({ reportId, embedUrl, accessToken, width, height }) => {
  const powerbi = new service.Service(
    factories.hpmFactory,
    factories.wpmpFactory,
    factories.routerFactory
  );

  const reportContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Construct the config for embedding
    const embedConfig: IEmbedConfiguration = {
      type: 'report',
      id: reportId,
      embedUrl: embedUrl,
      accessToken: accessToken,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            visible: false
          }
        }
      }
    };

    // Embed the report
    if (reportContainerRef.current) {
      powerbi.embed(reportContainerRef.current, embedConfig);
    }

    // Cleanup on component unmount
    return () => {
      if (reportContainerRef.current) {
        powerbi.reset(reportContainerRef.current);
      }
    };

  }, [reportId, embedUrl, accessToken, powerbi]);

  // Styling for the report container
  const containerStyle: React.CSSProperties = {
    width: width,
    height: height,
    border: 'none',
    padding: '0',
    margin: '0'
  };

  return <div ref={reportContainerRef} style={containerStyle} />;
};

export default PowerBIReport;
