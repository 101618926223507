import APIStandards from "src/utils/API_standards";
import CommunicationService from "./communication_service";

class TeacherServices{

static getStandards = async () => {
   const data =  await CommunicationService.post(APIStandards.TEACHER.LIST_CLASSES, {})
   console.log("v3: teacherservices getstandards: ", data.data)
   return data.data.classlist;
  };

}


export default TeacherServices