import { Row, Col, Card, Result } from "antd";
import React from "react";
import { FcLock } from "react-icons/fc";

export default function LockedContent() {
  return (
    <Row>
      <Col span={24}>
        <Card size="small" title="">
          <Result
            icon={<FcLock scale={8} style={{ fontSize: "130px" }} />}
            title="Update available soon!"
            subTitle="This module is locked and not opened yet. Come back later to use this module."
          ></Result>
        </Card>
      </Col>
    </Row>
  );
}
