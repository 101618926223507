import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import { json } from "stream/consumers";
import { useCookies } from "react-cookie";

const UserContext = createContext({});

export const UserProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  const verifyToken = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      CommunicationService.post(APIStandards.GUEST.GET_USER_DETAILS, { token })
        .then((res: any) => {
          if (res)
          localStorage.setItem("userDetails", JSON.stringify(res.data));
          let usertype = -1
          Object.keys(res.data.userdetails.userdetail).forEach((data: any)  => {
          console.log("index.tsx userdetails 1: ", res.data.userdetails.userdetail[data].usertypes.usertypename)
          if (res.data.userdetails.userdetail[data].usertypes.usertypename === 'Teacher') usertype = 1;
          if (res.data.userdetails.userdetail[data].usertypes.usertypename === 'Student') usertype = 2;
          if (res.data.userdetails.userdetail[data].usertypes.usertypename === 'Principal') usertype = 5;
          })
          
          // redirect user to change password page if first_time_login is set true
          if(res.data.userdetails.first_time_login == true) navigate("/change_password") 
          else {
            const currentSchoolid = res.data.userdetails.schoolid;
            let currentSchoolName = "";
            const currentRoleid = res.data.userdetails.roleid;
            let currentRoleName = "";
            Object.keys(res.data.userdetails.schooldetail).forEach((sdata: any)  => {
              if (currentSchoolid === res.data.userdetails.schooldetail[sdata].schoolidkey) currentSchoolName = res.data.userdetails.schooldetail[sdata].schoolname;
              localStorage.setItem("currentSchoolid", currentSchoolid);
              localStorage.setItem("currentSchoolname", currentSchoolName);
            });
            Object.keys(res.data.userdetails.userdetail).forEach((rdata: any)  => {
              if (currentRoleid === res.data.userdetails.userdetail[rdata].rolenames.roleid) currentRoleName = res.data.userdetails.userdetail[rdata].rolenames.rolename;
                localStorage.setItem("currentRoleid", currentRoleid);
                localStorage.setItem("currentRolename", currentRoleName);
            });
            // redirect user to curresponding home page
            if (usertype === 0) navigate("u/school/home");
            if (usertype === 1) navigate("u/teacher/home");
            // if (usertype === 1) navigate(locationpath);
            if (usertype === 2) navigate("u/student/home");
            if (usertype === 5) navigate("u/principal/home");
          }
      })
        .catch((err: any) => {
          if (window.location.href.split("/")[3] == "u") logout();
        });
    }
  };

  const logout = () => {
    localStorage.clear();
    removeCookie("userDetails");
    navigate("/login");
  };

  const value = useMemo(
    () => ({
      verifyToken,
      logout,
    }),
    [cookies]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = (): any => {
  return useContext(UserContext);
};
