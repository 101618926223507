import React from "react";

export default function GoogleDocsViewer({ src }: any) {
  const getUrl = () => {
    if (src.includes(".ppt"))
      return `https://view.officeapps.live.com/op/embed.aspx?src=${src}`;
    return `https://docs.google.com/viewer?url=${src}${"&embedded=true"}`;
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe src={getUrl()} style={{ width: "100%", height: "100%" }}></iframe>
    </div>
  );
}
