import { Drawer, DrawerProps } from "antd";
import React from "react";
import { useState } from "react";
import { Sidebar } from "src/components";
import { DashboardContext } from "src/contexts/dashboard_context";

const Drawer_ = ({ menuItem }: any) => {
  const { sidebar, toggleSidebar } = React.useContext(DashboardContext);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");

  return (
    <Drawer
      placement={placement}
      closable={true}
      open={sidebar}
      onClose={toggleSidebar}
      style={{ backgroundColor: "rgb(240,240,240)" }}
      width={"300px"}
    >
      <Sidebar menuItem={menuItem} />
    </Drawer>
  );
};

export default Drawer_;
