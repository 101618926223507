import { DownloadOutlined, PlusOutlined, SaveFilled, CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import {
  FcAddImage,
  FcCollaboration,
  FcEngineering,
  FcMakeDecision,
  FcOnlineSupport,
  FcQuestions,
} from "react-icons/fc";
import {
  Button,
  Col,
  Divider,
  Form,
  List,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Module } from "module";
import React, { useEffect, useRef } from "react";
import "./content_creation.css";
import { SyllabusService } from "src/services/common_services";
import ContentAdditionModel from "../content_addition/content_addition_model";
import Quizz from "src/components/common/content_creation/quizz/quizz";
import { isTemplateMiddle } from "typescript";
import { json, text } from "stream/consumers";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import LessonCreation from "../lesson_creation/lesson_creation";
import ContentStandards from "src/utils/content_standards";
import TextArea from "antd/es/input/TextArea";
import ContentManagementServices from "src/services/content_management_services";
import CustomActivity from "src/components/common/content_creation/custom_activity/custom_activity";
import TeacherServices from "src/services/teacher_services";
import { debug } from "console";
import useState from "react-usestateref";
import CurriculumCourseTreeSelect from "src/components/common/curriculum_course_select/curriculum_course_select";

const { Text } = Typography;

export default function ContentCreationMainModal() {
  const [standard, setStandard] = useState("06");
  const [contentAdditionModelOpen, SetContentAdditionModelOpen] =
    useState(false);

  const [quizAdditionModelOpen, SetQuizAdditionModelOpen] = useState(false);

  const [syllabusDropdowns, setSyllabusDropDowns, syllabusDropdownsRef] =
    useState<any>({
      standard: [],
      subject: [],
    });

  const [dropdownSelectData, setDropdownSelectData] = useState<any>({
    syllabus: "CBSE",
  });

  const [lessonCreationModalOpen, setLessonCreationModalOpen] = useState(false);
  const [lessonData, setLessonData] = useState(true);
  const [lessonType, setLessonType] = useState(0);

  //learning outcome funtionalities defined here! TODO: Code cleaning.
  const [isLearningOutcomeInputVisible, setLearningOutcomeInputCisibility] =
    useState(false);
  const [learningOutcomes, setLearningOutcomes] = useState<any>([]);
  const [currentLearningOutcome, setCurrentLearningOutcome] = useState("");
  const [activeLearningOutcomeId, setActiveLearningOutComeId] = useState(false);
  const [learningObjectives, setLearningObjectives] = useState([]);
  const [enableAddContent, setEnableAddContent] = useState(false);

  const [handsonSteps, setHandsOnSteps] = useState([
    "Objective",
    "Materials Required.",
    "Instructions",
  ]);
  const selectRef = useRef();

  useEffect(() => {
    // Get the standards from the database and set them as the state of the component.
    console.log("content_creation_main_model get subjects: ", dropdownSelectData.syllabus)
    getSubjects();
  }, []);

  const getSubjects = () => {
    CommunicationService.post(APIStandards.PUBLIC.GET_SUBJECTS, {
      syllabus: dropdownSelectData.syllabus,
    }).then((response: any) => {
      setSyllabusDropDowns({ ...syllabusDropdowns, subject: response.data });
    });
  };

  const getDropdownList = (selectedStandard: any) => {
    CommunicationService.post(APIStandards.PUBLIC.GET_DROPDOWN_LIST, {
      ...dropdownSelectData,
      standard: selectedStandard,
    }).then((response: any) => {
      const tSyllabusDropDowns: any = {};
      response.data.forEach((data: any) => {
        tSyllabusDropDowns[data] = [];
      });
      setSyllabusDropDowns({
        standard: syllabusDropdowns["standard"],
        subject: syllabusDropdowns["subject"],
        ...tSyllabusDropDowns,
      });
      getDropdownData("standard", selectedStandard, 1);
    });
  };

  const getDropdownData = (selectedDropDown: any, event: any, idx: any) => {
    console.log({ idx, totalLength: Object.keys(syllabusDropdowns).length });
    setEnableAddContent(false);
    let k: any = { ...dropdownSelectData };
    k[selectedDropDown] = event;
    setDropdownSelectData({ ...k });
    let selectedData: any = {};
    Object.keys(k).forEach((key: string, keyIdx) => {
      if (
        key == "syllabus" ||
        key == "subject" ||
        key == "standard" ||
        keyIdx > idx + 1
      ) {
        //do nothing
      } else {
        selectedData[key] = k[key];
      }
    });

    if (idx > 3 && idx >= Object.keys(syllabusDropdowns).length - 1) {
      CommunicationService.post(APIStandards.PUBLIC.GET_SECONDARY_LIST, {
        ...k,
        selected_data: selectedData,
      }).then((response: any) => {
        setEnableAddContent(true);
        setLearningObjectives(response.data[Object.keys(response.data)[0]]);
      });

      CommunicationService.post(APIStandards.PUBLIC.GET_TERITIARY_LIST, {
        ...k,
        selected_data: selectedData,
      }).then((response: any) => {
        setLearningOutcomes([...response.data[Object.keys(response.data)[0]]]);
      });

      CommunicationService.post(APIStandards.PUBLIC.GET_TAB_LIST, {
        ...k,
        selected_data: selectedData,
      }).then((response: any) => {
        localStorage.setItem("tabs_data", JSON.stringify(response.data));
        setEnableAddContent(true);
      });
    } else {
      CommunicationService.post(APIStandards.PUBLIC.GET_DROPDOWN_VALUES, {
        ...k,
        selected_data: selectedData,
        target: [Object.keys(syllabusDropdownsRef.current)[idx + 1]],
      }).then((response: any) => {
        let m = { ...syllabusDropdownsRef.current };
        m[Object.keys(syllabusDropdownsRef.current)[idx + 1]] =
          response.data[Object.keys(syllabusDropdownsRef.current)[idx + 1]];
        setSyllabusDropDowns({ ...m });
      });
    }
  };

  const openContentModel = (learningObjective: any) => {
    let selectedLessons: any;
    dropdownSelectData["learningObjective"] = learningObjective;
    

    localStorage.setItem(
      "teacher_selected_lessons",
      JSON.stringify(dropdownSelectData)
    );
    SetContentAdditionModelOpen(true);
  };

  const getStandards = async (event: any) => {
    const classes = await TeacherServices.getStandards();
    setSyllabusDropDowns((sylabusData: any) => ({
      ...sylabusData,
      standard: classes,
    }));
  };

  const onSelectHandler = (value: string) => {
    console.log("v3: classes onSelectHandler value: ", value)
    localStorage.setItem("selectedtopic", value);
    CommunicationService.post(APIStandards.PUBLIC.GET_TOPIC_DETAILS, {
      selectedtopic: value,
    }).then((response: any) => {
      setEnableAddContent(true);
      setLearningObjectives(response.data.learningobjectives);
      setLearningOutcomes(response.data.learningoutcomes)
      localStorage.setItem("tabs_data", JSON.stringify(response.data.tabsdata));
      localStorage.setItem("topiclob_data", JSON.stringify(response.data.topiclob));
      setEnableAddContent(true);
    });
  }
  
  return (
    <div>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            Let's create some contents today! <br />
            <Typography.Text style= {{fontSize:16}}>
            Explore the menu below to discover and choose the learning topic you'd like to start with. 
            Simply click on each section (<CaretRightOutlined /> and <CaretDownOutlined />) to reveal more options until you find your specific topic of interest.
            </Typography.Text>
          </Typography.Title>
        </Col>
      </Row>
      {/* <Row className="mt-3" style={{overflowX: "auto"}}>
        <Col span={12}>
          <Space direction="horizontal">
            <Select
              className="custom-select"
              
              placeholder="Subject"
              onSelect={async (event: any) => {
                setDropdownSelectData({
                  ...dropdownSelectData,
                  subject: event,
                });
                await getStandards(event);
              }}
            >
              {syllabusDropdowns.subject.map((item: any) => {
                return <Select.Option key={item}>{item}</Select.Option>;
              })}
            </Select>

            <Select
              className="custom-select"
              
              placeholder="Standard"
              onSelect={async (event) => {
                await getDropdownList(event);
              }}
            >
              {syllabusDropdowns.standard
                .sort((a: any, b: any) => {
                  return parseInt(a, 10) - parseInt(b, 10);
                })
                .map((item: any) => {
                  return <Select.Option key={item}>{item}</Select.Option>;
                })}
            </Select>

            {Object.keys(syllabusDropdowns).map((dropdown: any, idx: any) => {
              if (dropdown == "standard" || dropdown == "subject") {
                return <></>;
              }
              
              
              return (
                <Select
                  className="custom-select"
                  
                  placeholder={`${dropdown}`}
                  onSelect={async (event) => {
                    await getDropdownData(dropdown, event, idx);
                  }}
                >
                  {syllabusDropdowns[dropdown].map((item: any) => {
                    return <Select.Option key={item}>{item}</Select.Option>;
                  })}
                </Select>
              );
            })}
          </Space>
        </Col>
      </Row> */}
      <Row>
        <Col span={12}>
          {/* <Space direction="horizontal" size={'large'} > */}
            <CurriculumCourseTreeSelect onSelect={onSelectHandler} dataType={'curriculum'}/>
          {/* </Space> */}
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <List
          style={{ width: "100%" }}
          header={
            <Typography.Title level={5}>Learning Objectives</Typography.Title>
          }
          // footer={
          //   <div>
          //     <Button
          //       type="link"
          //       onClick={() => {
          //         setLessonType(ContentStandards.LEARNING_OBJECTIVE);
          //         setLessonCreationModalOpen(true);
          //       }}
          //     >
          //       Can't find the learning objective you are looking for? Create a
          //       new one.
          //     </Button>
          //   </div>
          // }
          bordered
          dataSource={learningObjectives}
          renderItem={(item: any, idx: any) => (
            <List.Item
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <Typography.Text mark>{idx + 1}</Typography.Text>
                &nbsp; {item.learningobjectivevalue}
              </div>
              <Space direction="horizontal">
                <Tooltip placement="top" title={"Add Content"}>
                  <Button
                    disabled={!enableAddContent}
                    onClick={() => {
                      openContentModel(item);
                    }}
                    icon={<FcAddImage size={22}></FcAddImage>}
                  ></Button>
                </Tooltip>
              </Space>
            </List.Item>
          )}
        />
      </Row>
      <Divider></Divider>

      {/* Learning outcome list */}
      {Object.keys(learningOutcomes).length > 0 && (
        <Row>
          <Col span={24}>
            <List
              header={
                <Typography.Title level={5}>Learning Outcomes</Typography.Title>
              }
              bordered
              style={{ width: "100%" }}
              // footer={
              //   <div>
              //     {Object.keys(learningOutcomes).length && (
              //       <div>
              //         {" "}
              //         <div>
              //           <Button
              //             type="link"
              //             onClick={() => {
              //               setLessonType(ContentStandards.LEARNING_OUTCOME);
              //               setLessonCreationModalOpen(true);
              //             }}
              //           >
              //             Can't find the learning outcome you are looking for?
              //             Create a new one.
              //           </Button>
              //         </div>
              //       </div>
              //     )}
              //   </div>
              // }
              dataSource={learningOutcomes}
              renderItem={(item: any, idx: any) => (
                <List.Item>
                  <Typography.Text>
                    <mark> {idx + 1}</mark>. {item}
                  </Typography.Text>
                </List.Item>
              )}
            ></List>
          </Col>
        </Row>
      )}

      {/* LEGANDS */}
      <Divider></Divider>
      <Row>
        <Col span={24}>
          <Space direction="horizontal">
            <div className="horizontal-menu">
              <FcQuestions size={40}></FcQuestions>
              <Text disabled>Questons</Text>
            </div>
            <div className="horizontal-menu">
              <FcCollaboration size={40}></FcCollaboration>
              <Text disabled>Collaborate</Text>
            </div>
            <div className="horizontal-menu">
              <FcAddImage size={40}></FcAddImage>
              <Text disabled>Content</Text>
            </div>
            <div className="horizontal-menu">
              <FcMakeDecision size={40}></FcMakeDecision>
              <Text disabled>Recall</Text>
            </div>
            <div className="horizontal-menu">
              <FcOnlineSupport size={40}></FcOnlineSupport>
              <Text disabled>Trainer</Text>
            </div>
            <div className="horizontal-menu">
              <FcEngineering size={40}></FcEngineering>
              <Text disabled>Hands On</Text>
            </div>
          </Space>
        </Col>
      </Row>

      {/* CONTENT ADDITION MODAL */}
      <Modal
        width={"90%"}
        open={contentAdditionModelOpen}
        closable
        onCancel={() => {
          SetContentAdditionModelOpen(false);
        }}
        destroyOnClose={true}
        footer={null}
      >
        <ContentAdditionModel></ContentAdditionModel>
      </Modal>

      {/* LESSON CREATION MODAL */}
      <Modal
        open={lessonCreationModalOpen}
        closable={true}
        footer={null}
        onCancel={() => {
          setLessonCreationModalOpen(false);
        }}
      >
        <LessonCreation
          lessonType={lessonType}
          onSave={(data: any) => {
            if (lessonType == 0) {
              setSyllabusDropDowns({
                ...syllabusDropdowns,
                chapters: [
                  ...syllabusDropdowns.chapters,
                  {
                    chapter_no: data.lessonNumber,
                    chapter_name: data.lessonName,
                  },
                ],
              });
              setTimeout(() => {
                setDropdownSelectData({
                  ...dropdownSelectData,
                  chapter_number: data.lessonNumber,
                });
              }, 500);
            }

            if (lessonType == 1) {
              setSyllabusDropDowns({
                ...syllabusDropdowns,
                chapters: [
                  ...syllabusDropdowns.chapters,
                  {
                    topic_code: data.lessonNumber,
                    topic: data.lessonName,
                  },
                ],
              });
              setTimeout(() => {
                setDropdownSelectData({
                  ...dropdownSelectData,
                  topic_number: data.lessonNumber,
                });
              }, 500);
            }

            if (lessonType == 1) {
              setSyllabusDropDowns({
                ...syllabusDropdowns,
                topics: [
                  ...syllabusDropdowns.topics,
                  {
                    topic_code: data.lessonNumber,
                    topic: data.lessonName,
                  },
                ],
              });
              setTimeout(() => {
                setDropdownSelectData({
                  ...dropdownSelectData,
                  topic_number: data.lessonNumber,
                });
              }, 500);
            }

            if (lessonType == ContentStandards.LEARNING_OBJECTIVE) {
              setSyllabusDropDowns({
                ...syllabusDropdowns,
                learningObjectives: [
                  ...syllabusDropdowns.learningObjectives,
                  {
                    learning_objective_code: data.lessonNumber,
                    learning_objective: data.lessonName,
                  },
                ],
              });
            }

            setLessonCreationModalOpen(false);
          }}
        ></LessonCreation>
      </Modal>
    </div>
  );
}
