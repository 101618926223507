import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import React from "react";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoIosRemoveCircle, IoMdAdd } from "react-icons/io";
import { MailTwoTone, SendOutlined } from "@ant-design/icons";
import { validate_email } from "src/utils/validations";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";

const { Text } = Typography;

export default function AddTeacherModal() {
  const [teachers, setListofTeachers] = useState([""]);
  const [apiLoading, setApiLoading] = useState(false);

  const sendInvitations = () => {
    // check all mail ids are valid
    let valid = true;
    teachers.forEach((item: any) => {
      if (!validate_email(item)) {
        valid = false;
      }
    });
    if (!valid) {
      message.error(
        "Some of the email ids are not valid. Please verify that all the entered mail ids are valid."
      );
    } else {
      setApiLoading(true);
      CommunicationService.post(APIStandards.SCHOOL.INVITE_TEACHERS, {
        emails: teachers,
      })
        .then((data: any) => {
          message.success("Invitations send to the teachers.");
          setListofTeachers([]);
        })
        .catch((err: any) => {
          message.error(
            "Failed to send invitation to some/all of teh teachers. Does the given teachers already onboarded?"
          );
        })
        .finally(() => {
          setApiLoading(false);
        });
    }
  };

  return (
    <div className="mt-0">
      {teachers.map((item: string, idx: number) => {
        return (
          <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
            <Form.Item
              style={{ width: "100%" }}
              hasFeedback
              validateStatus={
                item ? (validate_email(item) ? "success" : "error") : ""
              }
            >
              <Input
                placeholder="Please enter teacher email id."
                prefix={<MailTwoTone></MailTwoTone>}
                value={teachers[idx]}
                onChange={(event: any) => {
                  teachers[idx] = event.target.value;
                  setListofTeachers([...teachers]);
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Text
              type="warning"
              style={{ fontSize: "25px", cursor: "pointer" }}
            >
              {idx != 0 && (
                <IoIosRemoveCircle
                  className="ml-1"
                  onClick={() => {
                    let newList = teachers;
                    newList.splice(idx, 1);
                    setListofTeachers([...newList]);
                  }}
                />
              )}
            </Text>
          </div>
        );
      })}
      <Row>
        <Col xs={24}>
          <Button
            block
            icon={<IoMdAdd></IoMdAdd>}
            onClick={() => {
              setListofTeachers([...teachers, ""]);
            }}
            className="mt-3"
          >
            &nbsp; Add Row
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={24}>
          <Button
            className="float-right"
            type="primary"
            icon={<SendOutlined></SendOutlined>}
            onClick={sendInvitations}
            disabled={apiLoading}
            loading={apiLoading}
          >
            Send Invitations
          </Button>
        </Col>
      </Row>
    </div>
  );
}
