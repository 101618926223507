import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Login from "./views/guest/login/login";
import ChangePassword from "./views/guest/change_password/change_password";
import ForgotPassword from "./views/guest/forgot_password/forgot_password";
import { App } from "antd";
import { useAuth } from "src/hooks/auth";
import { ProtectRoutes } from "./hooks/protect_routes";
import SchoolMaster from "./views/school/master/school_master";
import Home from "./views/school/home/home";
import Teachers from "./views/school/teachers/teachers";
import TeacherSubjectMapping from "./views/school/teacher_subject_mapping/teacher_subject_mapping";
import TeacherMaster from "./views/teacher/master/teacher_master";
import TeacherHome from "./views/teacher/home/teacher_home";
import Classes from "./views/teacher/classes/classes";
import LockedContent from "./components/common/layout/locked_content/locked_content";
import ClassTeacherMappings from "./views/school/class_teacher_mappings/class_teacher_mappings";
import StudentMaster from "./views/student/master/studentMaster";
import Subjects from "./views/student/subjects/subjects";
import Lessons from "./views/student/lessons/lessons";
import LessonPlan from "./views/teacher/lesson_plan/lesson_plan";
import Course from "./views/student/course/course";
import TeacherCourse from "./views/teacher/teacher_course/teacher_course";
import Courses from "./views/teacher/courses/courses";
import PrincipalMaster from "./views/principal/master/principal_master";
import PrincipalHome from "./views/principal/home/principal_home";
import StudentHome from "./views/student/home/student_home";
import TeacherReports from "./views/teacher/reports/teacher_report";

function Main() {
  const { verifyToken } = useAuth();

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <App>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/g" element={<Login />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/u" element={<ProtectRoutes />}>
          <Route path="/u/school" element={<SchoolMaster />}>
            <Route path="/u/school/home" element={<Home />} />
            <Route path="/u/school/teachers" element={<Teachers />} />
            <Route
              path="/u/school/subjects"
              element={<TeacherSubjectMapping />}
            />
            <Route
              path="/u/school/classes"
              element={<ClassTeacherMappings />}
            />
          </Route>
          <Route path="/u/principal" element={<PrincipalMaster />}>
            <Route
              path="/u/principal/home"
              element={<PrincipalHome></PrincipalHome>}
            />
            <Route path="/u/principal/classes" 
            element={<Classes></Classes>} />
            <Route
              path="/u/principal/lesson-plan"
              element={<LessonPlan></LessonPlan>}
            />
            <Route path="/u/principal/courses" element={<Courses></Courses>} />
            <Route
              path="/u/principal/projects"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/principal/intership"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/principal/products"
              element={<LockedContent></LockedContent>}
            />
          </Route>
          <Route path="/u/teacher" element={<TeacherMaster />}>
            <Route
              path="/u/teacher/home"
              element={<TeacherHome></TeacherHome>}
            />
            <Route path="/u/teacher/classes" element={<Classes></Classes>} />
            <Route
              path="/u/teacher/lesson-plan"
              element={<LessonPlan></LessonPlan>}
            />
            <Route path="/u/teacher/courses" element={<Courses></Courses>} />
            <Route
              path="/u/teacher/projects"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/teacher/intership"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/teacher/products"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/teacher/reports"
              element={<TeacherReports></TeacherReports>}
            />
          </Route>
          <Route path="/u/student" element={<StudentMaster />}>
            <Route
              path="/u/student/home"
              element={<StudentHome></StudentHome>}
            />
            <Route path="/u/student/subjects" element={<Subjects></Subjects>} />
            <Route path="/u/student/courses" element={<Course></Course>} />
            <Route
              path="/u/student/lessons/:lessonId"
              element={<Lessons></Lessons>}
            />
            <Route
              path="/u/student/projects"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/student/intership"
              element={<LockedContent></LockedContent>}
            />
            <Route
              path="/u/student/products"
              element={<LockedContent></LockedContent>}
            />
          </Route>
        </Route>
      </Routes>
    </App>
  );
}

export default Main;
