import { Button, Divider, Input, Space, message, Steps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Buffer } from 'buffer';


export type ChallengeReviewData = {
    objective: string;
    maxMarks: number;
    reviewComments: string;
    reviewMarks: number;
    contentid_id:number;
    fileType: string,
    fileContent: string,
}[];


export default function ChallengeReview({ onSave, data }: any) {
    console.log("v3: handsonreviewdata data: ", data);
  const [challengeReviewData, setChallengeReviewData] = useState<ChallengeReviewData>(
     [{
        objective: JSON.parse(data.content)[0],
        maxMarks:parseFloat(JSON.parse(data.content)[1].replace(/[^\d.]/g, '')),
        contentid_id: data._id,
        fileType: data.file_format,
        fileContent: data.file_content,
        reviewComments: "",
        reviewMarks: 0,
    }])



  const [index, setIndex] = useState(0);

  const next = () => {
    setIndex(index + 1);
  };

  const prev = () => {
    setIndex(index - 1);
  };

  const onChange = (value: number) => {
    console.log('onChange:', value);
    setIndex(value);
  };
  

  console.log("v3: quixreview quizdata: ", challengeReviewData);

  const saveHandsOnReview = () => {
    
      const hasEmptyFields = challengeReviewData[0].reviewComments === undefined || challengeReviewData[0].reviewComments.trim() === '' || challengeReviewData[0].reviewMarks === undefined;

    
        const hasWrongValue = challengeReviewData[0].reviewMarks < 0 || challengeReviewData[0].reviewMarks > challengeReviewData[0].maxMarks

      if (hasEmptyFields) {
        message.error('Please fill in all the review comments and marks before saving.');
        return;
      }
      if (hasWrongValue) {
        message.error('Review Marks should be between 0 and max marks.');
        return;
      }

      setChallengeReviewData(
        [{...challengeReviewData[0], fileContent : "" }]
      ) 
    onSave(challengeReviewData);
  };

  const downloadBlobFile = () => {
    const file_bytes = Buffer.from(challengeReviewData[0].fileContent, 'base64')
    let fileType = '';
    let fileExtension = '';
    switch (challengeReviewData[0].fileType.toLowerCase()) {
      case 'png':
        fileType = 'image/png';
        fileExtension = 'png';
        break;
      case 'jpg':
      case 'jpeg':
        fileType = 'image/jpeg';
        fileExtension = 'jpg';
        break;
      case 'mp4':
        fileType = 'video/mp4';
        fileExtension = 'mp4';
        break;
      case 'mov':
        fileType = 'video/quicktime';
        fileExtension = 'mov';
        break;
      case 'mkv':
        fileType = 'video/x-matroska';
        fileExtension = 'mkv';
        break;
      case 'm4v':
        fileType = 'video/x-m4v';
        fileExtension = 'm4v';
        break;
      case 'avi':
        fileType = 'video/avi';
        fileExtension = 'avi';
        break;
      case 'flv':
        fileType = 'video/x-flv';
        fileExtension = 'flv';
        break;
      case 'zip':
        fileType = 'application/zip';
        fileExtension = 'zip';
        break;
      case 'rar':
        fileType = 'application/rar';
        fileExtension = 'rar';
        break;
      case 'txt':
        fileType = 'text/plain';
        fileExtension = 'txt';
        break;
      default:
        fileType = 'application/octet-stream'; // default to binary file type
        fileExtension = 'bin';
    }
    // file object
    const file = new Blob([file_bytes], {type: fileType});

    // anchor link
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = `filename.${fileExtension}`;

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
  return (
    <div>
      {
        <div>
          <Space direction="vertical" style={{ width: '100%' }}>

          <h3 style={{fontWeight:"bold"}}>Download the file submitted for completing Challenge Activity</h3>
          <Button type="primary" shape="round" icon={<DownloadOutlined />}id="downloadBtn" onClick={downloadBlobFile} value="download">Download</Button>
          <h3 style={{fontWeight:"bold"}}>Max marks:  </h3><div>{challengeReviewData[0].maxMarks }</div>
          <h3 style={{fontWeight:"bold"}}>Objective: </h3><div dangerouslySetInnerHTML={{ __html:challengeReviewData[0].objective }} />
            <h2 style={{fontWeight:"bold"}}>Enter Review comments:</h2>
            <Input.TextArea
              value={challengeReviewData[0].reviewComments}
              onChange={(e) => setChallengeReviewData(
                [{...challengeReviewData[0], reviewComments : e.target.value }]
              )}
              placeholder="Review Comments"
              required={true}
              title="Please enter review comments"
            />
            
            <h2 style={{fontWeight:"bold"}}>Enter Review marks:</h2>
            <Input
              value={challengeReviewData[0].reviewMarks}
              onChange={(e) => setChallengeReviewData(
                [{...challengeReviewData[0], reviewMarks: parseInt(e.target.value) }]
              )}
              type="number"
              min={0}
              max={challengeReviewData[0].maxMarks}
              placeholder="Review Marks"
              required={true}
              title="Please enter review marks"
            />
      <Button type="primary" onClick={saveHandsOnReview} style={{ marginTop: '16px',float:'right' }}>
        Save
      </Button>
          </Space>
        </div>
      
      }
    </div>
  );
}