import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./preview_modal.css";
import {
  Button,
  Card,
  Divider,
  List,
  Result,
  Space,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import ContentStandards from "src/utils/content_standards";
import ContentManagementServices from "src/services/content_management_services";
import GoogleDocsViewer from "./google_docs_viewer";
import YoutubeViewer from "./youtube_viewer";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import "./viewer_styles.css";
import user_types from "src/utils/user_types";
import QuizViewer from "./quiz_viewer";
import HandsOnViewer from "./hands_on_viewer";
import ChallengeViewer from "./challenge_viewer";
// import DisplayLearningOutcomes from "./display_learning_outcomes";

export default function PreviewModal({ lessons, selectData, selecteddivision, selectedclass }: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails") || "{}").type
  );
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [uploadedHandsOnfilename, setUploadedHandsOnfilename] = useState("");
  console.log("v3: preview_modal lessons: ", lessons)
  console.log("v3: preview_modal selectData: ", selectData)

  useEffect(() => {
    setActiveIndex(0);
    console.log("v3: preview_modal currentUser: ", currentUser)
    if (currentUser == user_types.Teacher)
      setIsCompleted(
        Boolean(localStorage.getItem("teaching_lesson_completion_status"))
      );
    if (currentUser == user_types.Student) {
      if (selectData.syllabus === "CBSE") {
        CommunicationService.post(APIStandards.STUDENT.GET_LEARNING_STATUS, {
          academicyear: "2024-2025",
          pedagogy: "CBSE",
          semester: "India School 1 Year Semester",
          selectedtopic: localStorage.getItem("selectedtopic"),
          studentlearningsessionid: localStorage.getItem("studentlearningsessionid"),
        }).then((data: any) => {
          localStorage.setItem(
            "learning_lesson_completion_status",
            data.data.studentsessiondetails["sessionstatus"]
          );
          setIsCompleted(data.data.studentsessiondetails["sessionstatus"]);
        });
      }
      else if (selectData.syllabus === "Course"){
        CommunicationService.post(APIStandards.STUDENT.GET_LEARNING_STATUS, {
          academicyear: "S2024-S2025",
          pedagogy: "STEM",
          semester: "Steamcube School 1 Year Semester",
          selectedtopic: localStorage.getItem("selectedtopic"),
          studentlearningsessionid: localStorage.getItem("studentlearningsessionid"),
        }).then((data: any) => {
          localStorage.setItem(
            "learning_lesson_completion_status",
            data.data.studentsessiondetails["sessionstatus"]
          );
          setIsCompleted(data.data.studentsessiondetails["sessionstatus"]);
        });
      }
    }
    else if (currentUser == user_types.Teacher) {
      if (selectData.syllabus === "CBSE") {
        CommunicationService.post(APIStandards.TEACHER.GET_LEARNING_STATUS, {
          academicyear: "2024-2025",
          pedagogy: "CBSE",
          semester: "India School 1 Year Semester",
          selectedtopic: localStorage.getItem("selectedtopic"),
          selecteddivision: selecteddivision,
          selectedclass: selectedclass,
          teacherteachingsessionid: localStorage.getItem("teacherteachingsessionid"),
        }).then((data: any) => {
          localStorage.setItem(
            "learning_lesson_completion_status",
            data.data.teachersessiondetails["sessionstatus"]
          );
          setIsCompleted(data.data.teachersessiondetails["sessionstatus"]);
        });
      }
      else if (selectData.syllabus === "Course"){
        CommunicationService.post(APIStandards.TEACHER.GET_LEARNING_STATUS, {
          academicyear: "S2024-S2025",
          pedagogy: "STEM",
          semester: "Steamcube School 1 Year Semester",
          selectedtopic: localStorage.getItem("selectedtopic"),
          selecteddivision: selecteddivision,
          selectedclass: selectedclass,
          teacherteachingsessionid: localStorage.getItem("teacherteachingsessionid"),
        }).then((data: any) => {
          localStorage.setItem(
            "learning_lesson_completion_status",
            data.data.teachersessiondetails["sessionstatus"]
          );
          setIsCompleted(data.data.teachersessiondetails["sessionstatus"]);
        });
      }
    }
  }, [lessons, selectData]);

  const togglePause = () => {
    setIsPaused(!isPaused);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const _azurePlayer = ({cotainername, videoId}: any) => {
    // const containerName = 'steamcubeclass-courses-videos';
    const containerName = cotainername;
    const blobName = videoId;
    const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rltf&se=2024-12-31T15:16:51Z&st=2024-04-30T07:16:51Z&spr=https&sig=6uJJG%2B5Rp4Ke2qJ9acQrJnjVFxwjYy9at%2FiI499DkOE%3D';

    // const url = `https://steamcubecourses.blob.core.windows.net/${containerName}/${blobName}?${sasToken}`;
    const url = `https://steamcubeazblobcdn1.azureedge.net/${containerName}/${blobName}?${sasToken}`;
    console.log("v3: azureplayer url: ", encodeURIComponent(url))
  
  
    if (containerName.endsWith('videos')){
      return url;
    }
    else{
      return encodeURIComponent(url);
    }
  };

  const displayLearningOutcomes = (data: any) => {
    return (
      <>
        <Typography.Title level={5}>Learning Outcomes</Typography.Title>
        <Divider></Divider>

        <div className="presentation-list">
          <List
            header={null}
            footer={null}
            bordered
            dataSource={data}
            renderItem={(item: any, idx) => (
              <List.Item>
                <Typography.Text mark>{idx + 1}</Typography.Text> {item}
              </List.Item>
            )}
          />
        </div>
      </>
    );
  };

  const displayLearningObjectives = (data: any) => {
    return (
      <>
        <Typography.Title level={5}>Learning Objectives</Typography.Title>
        <Divider></Divider>

        <div className="presentation-list">
          <List
            header={null}
            footer={null}
            bordered
            dataSource={data}
            renderItem={(item: any, idx) => (
              <List.Item>
                <Typography.Text mark>{idx + 1}</Typography.Text> {item.learningobjectivevalue}
              </List.Item>
            )}
          />
        </div>
      </>
    );
  };

  const displayLesson = (lesson: any) => {
    console.log("lesson print 1: ", lesson)
    return (
      
      <Card
        bordered={false}
        style={{ height: "100%" }}
        title={
          <>
            <Typography.Title level={5}>
              Learing Objective: {lesson["learningObjective"]}
            </Typography.Title>
            <Typography.Text>Competency: {lesson["tab"].join(', ')}</Typography.Text>
          </>
        }
      >
        {(lesson["content"]["content_type"] == ContentStandards.PDF ||
          lesson["content"]["content_type"] == ContentStandards.PPT) && (
          <GoogleDocsViewer
            style={{ height: "100%" }}
            // src={`${APIStandards.SPACES_END_POINT_URL}${
            //   JSON.parse(lesson["content"]["content"])["filename"]
            // }`}
            src={_azurePlayer({cotainername: 'steamcube-content-uploads',
              videoId : JSON.parse(lesson["content"]["content"]).filename})}
          />
        )}
        {/* {lesson["content"]["content_type"] == ContentStandards.VIDEO && (
          selectData.syllabus == "CBSE" ? <YoutubeViewer content={lesson["content"]["content"]} syllabus={lesson["syllabus"]} />: 
          <video ref={videoRef} controls autoPlay width="100%" height="50%" controlsList="nodownload">
            <source src={_azurePlayer({cotainername:'steamcubeclass-courses-videos',
              videoId: JSON.parse(lesson["content"]["content"]).url})} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )} */}
        {lesson["content"]["content_type"] == ContentStandards.VIDEO && (
          <YoutubeViewer content={lesson["content"]["content"]} syllabus={selectData.syllabus} />
        )}
        {lesson["content"]["content_type"] == ContentStandards.QUIZ && (
          <>
            <Typography.Text >
              Please complete the following assesment.
            </Typography.Text>{" "}
            <br />
            <QuizViewer content={lesson["content"]} />
          </>
        )}
        {lesson["content"]["content_type"] == ContentStandards.HANDS_ON && (
          <>
            {" "}
            <Typography.Text >Hands-On Activity</Typography.Text> <br />
            <HandsOnViewer content={lesson["content"]} handsOnFileName={fetchHandsOnFileName(lesson)} uploadedHandsOnFileName={handleUpdatedHandsOnFileName}/>
          </>
        )}
         {lesson["content"]["content_type"] == ContentStandards.CHALLENGE && (
          <>
            {" "}
            <Typography.Text >Challenge Activity</Typography.Text> <br />
            {/* <ChallengeViewer content={lesson["content"] }  /> */}
            <ChallengeViewer content={lesson["content"] } uploadedHandsOnFileName={uploadedHandsOnfilename} handsOnFileName={fetchHandsOnFileName(lesson)} challengeFileName={fetchChallengeFileName(lesson)} />
          </>
        )}
      </Card>
    );
  };

  const handleUpdatedHandsOnFileName = (fileName:string) => {
  setUploadedHandsOnfilename(fileName)
  }

  const fetchHandsOnFileName = (lesson:any) => {
  // filter the contents based on the content type == 11(hands on)
  const handson = lesson["contents"].filter((obj:any)  => obj['content_type'] == 11)
  // if there is hands_on activity for the topic then return the filename
  return handson.length > 0 ? handson[0].filename : 'No_HandsOn_Activity'
  }

  const fetchChallengeFileName = (lesson:any) => {
  // filter the contents based on the content type == 11(hands on)
  const challenge = lesson["contents"].filter((obj:any)  => obj['content_type'] == 13)
  // if there is hands_on activity for the topic then return the filename
  return challenge.length > 0 ? challenge[0].filename : 'No_Challenge_Activity'

  }

  const handleConfirmation = () => {
    CommunicationService.post(
      currentUser == user_types.Teacher
        ? APIStandards.TEACHER.LOG_TEACHING
        : APIStandards.STUDENT.LOG_LEARNING,
      {
        academicyear: "2024-2025",
        pedagogy: "CBSE",
        semester: "India School 1 Year Semester",
        selectedtopic: localStorage.getItem("selectedtopic"),
        mode: "end",
        studentlearningsessionid: localStorage.getItem("studentlearningsessionid"),
        teacherteachingsessionid: localStorage.getItem("teacherteachingsessionid"),
      }
    )
      .then(() => {
        message.success(
          "Your progress has been saved successfully, you may now close this now!"
        );
        setIsCompleted(true);
        if (currentUser == user_types.Teacher){
          localStorage.setItem("teacherteachingsessionid", '');
        }
        else if (currentUser == user_types.Student){
          localStorage.setItem("studentlearningsessionid", '')
        }
        
      })
      .catch((error: any) => {
        // Handle error if needed
        console.error(error);
      });
    setShowResult(true);
  };

  const FinishScreen = () => {
    return (
      <div>
        {/* {isCompleted ? ( */}
        {showResult ? (
          <Result
            status="success"
            title={`Successfully Completed ${
              currentUser === user_types.Teacher ? "Teaching" : "Learning"
            }!`}
          />
        ) : (
          <Button
            disabled={isCompleted}
            key="confirmButton"
            size="large"
            type="primary"
            onClick={handleConfirmation}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {`Click here to confirm that ${
              currentUser === user_types.Teacher ? "Teaching" : "Learning"
            } is Completed!`}
          </Button>
        )}

        {/* ) : ( */}
        {/* <>
            <h4 style={{ fontSize: "30px", textAlign: "center" }}>
              You have completed{" "}
              {currentUser == user_types.Teacher ? " Teaching" : " Learning"}{" "}
              this Topic. Please confirm it with the below button.
            </h4>
            <Divider></Divider>
            <Button
              key="confirmButton"
              onClick={handleConfirmation}
              size="large"
              type="primary"
              block
            >
              Click Here
            </Button> */}
        {/* </> */}
        {/* )} */}
      </div>
    );
  };

  return (
    <div className="preview-container">
      <div className="content-viewer">
        {Object.keys(lessons[activeIndex]).includes("title") &&
          lessons[activeIndex]["title"] == "Learning Outcomes" &&
          displayLearningOutcomes(lessons[activeIndex]["data"])}
          {/* <DisplayLearningOutcomes data={lessons[activeIndex]["data"]} />
        } */}

        {Object.keys(lessons[activeIndex]).includes("title") &&
          lessons[activeIndex]["title"] == "Learning Objectives" &&
          displayLearningObjectives(lessons[activeIndex]["data"])}

        {Object.keys(lessons[activeIndex]).includes("tab") &&
          displayLesson(lessons[activeIndex])}

        {Object.keys(lessons[activeIndex]).includes("finished") &&
          lessons[activeIndex]["finished"] && <FinishScreen />}
      </div>
      <div className="w-100 text-center">
        {/* <Divider /> */}
        <div className="button-container">
          <Space>
            {activeIndex > 0 && (
              <Button
                type="link"
                icon={<LeftOutlined className="large-icon" />}
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
                className="large-icon"
              >
                Previous
              </Button>
            )}
            {activeIndex < lessons.length - 1 && (
              <Button
                type="link"
                onClick={() => {
                  setActiveIndex(activeIndex + 1);
                }}
                className="large-icon"
              >
                Next <RightOutlined className="large-icon" />
              </Button>
            )}
          </Space>
        </div>
      </div>
    </div>
  );
}
