import React, { useMemo } from 'react';
import PowerBIReport from './powerbireport';

interface PowerBIReportWrapperProps {
  reportId: string;
  embedUrl: string;
  accessToken: string;
}

const PowerBIReportWrapper: React.FC<PowerBIReportWrapperProps> = ({
  reportId,
  embedUrl,
  accessToken,
}) => {
  const memoizedReport = useMemo(() => {
    return (
      <PowerBIReport
        reportId={reportId}
        embedUrl={embedUrl}
        accessToken={accessToken}
        width="100%"
        height="850px"
      />
    );
  }, [reportId, embedUrl, accessToken]);

  return memoizedReport;
};

export default PowerBIReportWrapper;