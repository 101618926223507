import React, { useEffect, useState } from "react";
import "./home.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Carousel } from "antd";
import {
  AntDesignOutlined,
  LikeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Divider, Statistic, Tooltip, Typography } from "antd";
import { Avatar, List, Skeleton } from "antd";
// import {
//   Chart as ChartJS,
//   ArcElement,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   // Tooltip,
//   PointElement,
//   LineElement,
// } from "chart.js";
// import { Doughnut, Line } from "react-chartjs-2";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { Card, Col, Row, Dropdown, Button, Menu } from "antd";
import { Gi3DGlasses } from "react-icons/gi";
import {
  GrDocumentUser,
  GrDocumentPerformance,
  GrUserExpert,
} from "react-icons/gr";
import { SiGoogleclassroom } from "react-icons/si";
import { BiUser, BiPencil } from "react-icons/bi";
import {
  FaChalkboardTeacher,
  FaSchool,
  FaUserGraduate,
  FaUserTie,
} from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import communication_service from "src/services/communication_service";
import API_standards from "src/utils/API_standards";
import content_standards from "src/utils/content_standards";
import user_types from "src/utils/user_types";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ResponsiveContainer,
  Tooltip as ReTooltip,
} from "recharts";

// ChartJS.register(
//   BarElement,
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   // Tooltip,
//   PointElement,
//   LineElement
// );

interface DataType {
  gender?: string;
  name: {
    title?: string;
    first?: string;
    last?: string;
  };
  email?: string;
  picture: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  nat?: string;
  loading: boolean;
}

const count = 5;
const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat,picture&noinfo`;

const Home = () => {
  const subjects = [
    "Math",
    "Science",
    "English",
    "Hindi",
    "Malayalam",
    "Social Science",
  ];

  const [selectedSubject, setSelectedSubject] = useState("");

  const handleSubjectChange = (event: any) => {
    setSelectedSubject(event.target.value);
  };
  const [selectedClass, setSelectedClass] = useState("");

  const handleClassChange = (event: any) => {
    setSelectedClass(event.target.value);
  };

  const [dayVsCountGraphdata, setDayVsCountGraphdata] = useState<any>({});
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<DataType[]>([]);
  const [userCount, setUserCount] = useState<any>({});
  const [dueGraph, setDueGraph] = useState<any>({});
  // const [userClasses, setUserClasses] = useState<any>({});
  const [totalClasses, setTotalClasses] = useState<any>({});
  const [learningGraphData, setLearningGraphData] = useState<any>({});

  useEffect(() => {
    fetch(fakeDataUrl)
      .then((res) => res.json())
      .then((res) => {
        setInitLoading(false);
        setData(res.results);
        setList(res.results);
      });

    getUsersCount();
    getAndProcessDueData();
    getAndProcessLearningData();
    getClasses();
  }, []);

  const getUsersCount = () => {
    communication_service
      .post(API_standards.SCHOOL.GET_USER_COUNT, {})
      .then((res: any) => {
        setUserCount(res.data);
      });
  };

  const getStudentGenderCount = () => {
    communication_service
      .post(API_standards.SCHOOL.GET_STUDENT_GENDER_COUNT, {})
      .then((res: any) => {
        //setUserCount(res.data);
      });
  };

  const getClasses = () => {
    communication_service
      .post(API_standards.SCHOOL.GET_CLASSES, {})
      .then((res: any) => {
        setTotalClasses(res.data[0].totalStandards);
      });
  };

  const onLoadMore = () => {
    setLoading(true);
    setList(
      data.concat(
        [...new Array(count)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        }))
      )
    );
    fetch(fakeDataUrl)
      .then((res) => res.json())
      .then((res) => {
        const newData = data.concat(res.results);
        setData(newData);
        setList(newData);
        setLoading(false);
        window.dispatchEvent(new Event("resize"));
      });
  };

  const getAndProcessDueData = () => {
    communication_service
      .post(API_standards.SCHOOL.GET_DUE_DATA, {})
      .then((data: any) => {
        let processedDueData: any = {};
        data.data.forEach((item: any) => {
          let t_data: any = {};
          t_data["teacher_id"] = item["teacher_id"];
          t_data["name"] = item["teacher_details"][0]["name"];
          t_data["Planned"] = item["planned_count"];
          t_data["Actual"] = item["actual_count"];
          if (!Object.keys(processedDueData).includes(item["section"])) {
            processedDueData[item["section"]] = [t_data];
          } else {
            // check if teacher is there in the exisitng array
            let exisitngIdx = -1;
            processedDueData[item["section"]].forEach(
              (e_item: any, idx: number) => {
                if (e_item["teacher_id"] == t_data["teacher_id"]) {
                  exisitngIdx = idx;
                  processedDueData[item["section"]][idx]["Planned"] =
                    processedDueData[item["section"]][idx]["Planned"] +
                    t_data["Planned"];
                  processedDueData[item["section"]][idx]["Actual"] =
                    processedDueData[item["section"]][idx]["Actual"] +
                    t_data["Actual"];
                }
              }
            );
            if (exisitngIdx == -1) {
              processedDueData[item["section"]].push(t_data);
            }
          }
        });

        setDueGraph({ ...processedDueData });
        console.log(processedDueData);
      });
  };

  const getAndProcessLearningData = () => {
    communication_service
      .post(API_standards.SCHOOL.GET_LEARNING_DATA_BY_SECTION, {})
      .then((data: any) => {
        let processedData: any = {};
        data.data.forEach((item: any) => {
          let t_data: any = {};
          if (!Object.keys(processedData).includes(item["section"])) {
            processedData[item["section"]] = [
              {
                Subject: item["subject"],
                Completed: item["actual_count"],
              },
            ];
          } else {
            processedData[item["section"]].push({
              Subject: item["subject"],
              Completed: item["actual_count"],
            });
          }
        });
        setLearningGraphData({ ...processedData });
      });
  };

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button style={{ color: "rgb(0, 102, 178)" }} onClick={onLoadMore}>
          loading more
        </Button>
      </div>
    ) : null;

  const dataa = {
    labels: [
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
    ],
    datasets: [
      {
        label: "Months",
        data: [2, 4, 6, 1, 3, 2, 1, 3, 3, 4, 4, 5],
        backgroundColor: "#D8BFD8",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };

  const dataDoughnut = {
    labels: ["Girls", "Boys"],
    datasets: [
      {
        label: "Count",
        data: [180, 150],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 4,
      },
    ],
  };
  const options = {};

  const menu = (
    <Menu>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="remove">Remove</Menu.Item>
    </Menu>
  );

  const ddataa = {
    labels: [
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
    ],
    datasets: [
      {
        label: "Months",
        data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
        backgroundColor: "#BC8F8F",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };

  const labels = [
    "Content Quality",
    "Instruction",
    "Parent Engagement",
    "Resources",
    "Student Satisfaction",
  ];

  const dataLine = {
    labels: labels,
    datasets: [
      {
        label: "Evaluation",
        data: [65, 59, 80, 81, 56],
        fill: false,
        borderColor: "#FFDAB9",
        tension: 0.1,
      },
    ],
  };
  const optionss = {};

  return (
    <>
      {/* <span
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <select
          id="class"
          value={selectedClass}
          onChange={handleClassChange}
          style={{
            padding: "8px",
            border: "2px solid #800000",
            borderRadius: "4px",
          }}
        >
          <option value="">Class</option>
          <option value="prek">Pre K</option>
          <option value="lkg">L-KG</option>
          <option value="ukg">U-KG</option>
          <option value="class1">Class 1</option>
          <option value="class2">Class 2</option>
          <option value="class3">Class 3</option>
          <option value="class4">Class 4</option>
          <option value="class5">Class 5</option>
          <option value="class6">Class 6</option>
          <option value="class7">Class 7</option>
          <option value="class8">Class 8</option>
          <option value="class9">Class 9</option>
          <option value="class10">Class 10</option>
          <option value="class11">Class 11</option>
          <option value="class12">Class 12</option>
        </select>
        <select
          id="subject"
          value={selectedSubject}
          onChange={handleSubjectChange}
          style={{
            padding: "8px",
            border: "2px solid #800000",
            borderRadius: "4px",
            marginLeft: "5px",
          }}
        >
          <option value="">Subject</option>
          {subjects.map((subject, index) => (
            <option key={index} value={subject}>
              {subject}
            </option>
          ))}
        </select>
      </span> */}

      {/* Count Statistics */}

      {/* ant design card */}
      <div>
        <Row gutter={[16, 16]} className="mt-5">
          <Col span={4} xs={24} sm={12} md={8} lg={4}>
            <Card bordered={false} className="student-card">
              <Statistic
                title={
                  <Typography.Title disabled level={5}>
                    Students
                  </Typography.Title>
                }
                value={userCount[user_types.Student] || 0}
                prefix={<FaUserGraduate />}
              />
            </Card>
          </Col>
          <Col span={4} xs={24} sm={12} md={8} lg={4}>
            <Card bordered={false} className="teachers-card">
              <Statistic
                title={
                  <Typography.Title disabled level={5}>
                    Teachers
                  </Typography.Title>
                }
                value={userCount[user_types.Teacher] || 0}
                prefix={<FaChalkboardTeacher />}
              />
            </Card>
          </Col>
          <Col span={4} xs={24} sm={12} md={8} lg={4}>
            <Card bordered={false} className="classes-card">
              <Statistic
                title={
                  <Typography.Title disabled level={5}>
                    Classes
                  </Typography.Title>
                }
                value={typeof totalClasses === "object" ? 0 : totalClasses || 0}
                prefix={<SiGoogleclassroom />}
              />
            </Card>
          </Col>
          <Col span={4} xs={24} sm={12} md={8} lg={4}>
            <Card bordered={false} className="sections-card">
              <Statistic
                title={
                  <Typography.Title disabled level={5}>
                    Sections
                  </Typography.Title>
                }
                value={4}
                prefix={<FaSchool />}
              />
            </Card>
          </Col>
          <Col span={4} xs={24} sm={12} md={8} lg={4}>
            <Card bordered={false} className="parents-card">
              <Statistic
                title={
                  <Typography.Title disabled level={5}>
                    Parents
                  </Typography.Title>
                }
                value={userCount[user_types.Parent] || 0}
                prefix={<RiParentFill />}
              />
            </Card>
          </Col>
          <Col span={4} xs={24} sm={12} md={8} lg={4}>
            <Card bordered={false} className="stakeholders-card">
              <Statistic
                title={
                  <Typography.Title disabled level={5}>
                    Admin
                  </Typography.Title>
                }
                value={1}
                prefix={<FaUserTie />}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {/* Second Row */}
      <Carousel autoplay>
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Teaching: Planned vs Actual (Secondary)"}
                style={{ height: "auto" }}
              >
                {dueGraph["Secondary"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={dueGraph["Secondary"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Planned" stackId="a" fill="#8884d8" />
                      <Bar dataKey="Actual" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>

        {/* Third Row */}
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Teaching: Planned vs Actual (Middle)"}
                style={{ height: "auto" }}
              >
                {dueGraph["Middle"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={dueGraph["Middle"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Planned" stackId="a" fill="#8884d8" />
                      <Bar dataKey="Actual" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Teaching: Planned vs Actual (Preparatory)"}
                style={{ height: "auto" }}
              >
                {dueGraph["Preparatory"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={dueGraph["Preparatory"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Planned" stackId="a" fill="#8884d8" />
                      <Bar dataKey="Actual" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Teaching: Planned vs Actual (Foundational)"}
                style={{ height: "auto" }}
              >
                {dueGraph["Foundational"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={dueGraph["Foundational"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Planned" stackId="a" fill="#8884d8" />
                      <Bar dataKey="Actual" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </Carousel>

      <Carousel autoplay>
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Learning: Completed vs Subject (Secondary)"}
                style={{ height: "auto" }}
              >
                {learningGraphData["Secondary"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={learningGraphData["Secondary"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Subject" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Completed" stackId="a" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>

        {/* Third Row */}
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Learning: Completed vs Subject (Middle)"}
                style={{ height: "auto" }}
              >
                {learningGraphData["Middle"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={learningGraphData["Middle"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Subject" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Completed" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Learning: Completed vs Subject (Preparatory)"}
                style={{ height: "auto" }}
              >
                {learningGraphData["Preparatory"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={learningGraphData["Preparatory"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Subject" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Completed" stackId="a" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={15} className="mt-3">
            <Col span={24}>
              <Card
                title={"Learning: Completed vs Subject (Foundational)"}
                style={{ height: "auto" }}
              >
                {learningGraphData["Foundational"] && (
                  <ResponsiveContainer width="100%" aspect={16 / 4}>
                    <BarChart data={learningGraphData["Foundational"]}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Subject" />
                      <YAxis />
                      <ReTooltip />
                      <Legend />
                      <Bar dataKey="Completed" stackId="a" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </Carousel>
      {/* Fourth Row */}
      {/* <Row gutter={15} className="mt-3">
        <Col span={24}>
          <Card
            title={"Teaching: Planned vs Actual (Preparatory)"}
            style={{ height: "auto" }}
          >
            {dueGraph["Preparatory"] && (
              <ResponsiveContainer width="100%" aspect={16 / 4}>
                <BarChart data={dueGraph["Preparatory"]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <ReTooltip />
                  <Legend />
                  <Bar dataKey="Planned" stackId="a" fill="#8884d8" />
                  <Bar dataKey="Actual" stackId="a" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Card>
        </Col>
      </Row> */}

      {/* <Row gutter={16} className="mt-3">
        <Col span={8} style={{ marginBottom: "10px" }}>
          <div style={{ marginRight: "175px" }}>
            <Card
              title={
                <>
                  <div>
                    <span
                      style={{
                        backgroundColor: "#BC8F8F",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "5px",
                      }}
                    >
                      <GrDocumentPerformance size={25} />
                    </span>
                    <span>Performance</span>
                  </div>
                </>
              }
              bordered={false}
              style={{
                height: "300px",
                width: "500px",
                backgroundColor: "White",
                borderBottom: "4px solid #BC8F8F",
                borderColor: "#BC8F8F",
              }}
            >
              <div style={{ marginTop: "-28px" }}>
                {/* <Bar data={ddataa} options={options}></Bar> */}
      {/* </div>
            </Card>
          </div>
        </Col> */}

      {/* <Col span={8} style={{ marginBottom: "10px" }}>
          <div style={{ marginLeft: "175px" }}>
            <Card
              title={
                <>
                  <div>
                    <span
                      style={{
                        backgroundColor: "#D8BFD8",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "5px",
                      }}
                    >
                      <Gi3DGlasses size={25} />
                    </span>

                    <span style={{ color: "#696969", fontWeight: "bold" }}>
                      Attendence
                    </span>
                  </div>
                </>
              }
              bordered={false}
              style={{
                height: "300px",
                width: "550px",
                backgroundColor: "white",
                borderBottom: "4px solid #D8BFD8",
                borderColor: "#D8BFD8",
              }}
            >
              <div style={{ marginTop: "-28px" }}>
               <Bar data={dataa} options={options}></Bar> 
              </div>
            </Card>
          </div>
        </Col>
      </Row>  */}

      {/* <Row gutter={16} style={{ marginTop: "20px" }}>
        <Col span={8} style={{ marginBottom: "10px" }}>
          <div>
            <Card
              title={
                <>
                  <div>
                    <span
                      style={{
                        backgroundColor: "#40E0D0",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "5px",
                      }}
                    >
                      <GrUserExpert size={25} />
                    </span>

                    <span style={{ color: "#696969", fontWeight: "bold" }}>
                      Competency
                    </span>
                  </div>
                </>
              }
              bordered={false}
              style={{
                height: "300px",
                width: "500px",
                backgroundColor: "White",
                borderBottom: "4px solid #40E0D0",
                borderColor: "#40E0D0",
              }}
            >
              <table
                style={{
                  borderCollapse: "collapse",
                  border: "4px solid #40E0D0",
                  height: "100px",
                  width: "450px",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "2px solid #40E0D0",
                        padding: "8px",
                        fontWeight: "bold",
                        width: "30%",
                      }}
                    >
                      Proficiency
                    </th>
                    <th
                      style={{
                        border: "2px solid #40E0D0",
                        padding: "8px",
                        fontWeight: "bold",
                        width: "70%",
                      }}
                    >
                      Learners
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "2px solid #40E0D0",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      High
                    </td>
                    <td style={{ border: "2px solid #40E0D0", padding: "8px" }}>
                      <Avatar.Group>
                        <Tooltip title="Tom" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Rahul" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Rishab" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                      </Avatar.Group>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid #40E0D0",
                        padding: "8px",
                        fontWeight: "bold",
                        // fontSize: "15px",
                      }}
                    >
                      Medium
                    </td>
                    <td style={{ border: "2px solid #40E0D0", padding: "8px" }}>
                      <Avatar.Group>
                        <Tooltip title="Vivek" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Rahul" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Rishab" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                      </Avatar.Group>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: "2px solid #40E0D0",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Low
                    </td>
                    <td style={{ border: "2px solid #40E0D0", padding: "8px" }}>
                      <Avatar.Group>
                        <Tooltip title="Vivek" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Rahul" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                        <Tooltip title="Rishab" placement="top">
                          <Avatar
                            style={{
                              backgroundColor: "#40E0D0",
                              width: "20px",
                              height: "20px",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            icon={<UserOutlined />}
                          />
                        </Tooltip>
                      </Avatar.Group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </Col>

        <Col span={8} style={{ marginBottom: "10px" }}>
          <div style={{ marginLeft: "175px" }}>
            <Card
              title={
                <>
                  <div>
                    <span
                      style={{
                        backgroundColor: "#FFDAB9",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "5px",
                      }}
                    >
                      <HiOutlineMagnifyingGlass size={25} />
                    </span>
                    <span>Evaluation</span>
                  </div>
                </>
              }
              bordered={false}
              style={{
                height: "300px",
                width: "550px",
                backgroundColor: "white",
                borderBottom: "4px solid #FFDAB9",
                borderColor: "#FFDAB9",
              }}
            >
              <div style={{ marginTop: "-30px" }}>
                <Line data={dataLine} options={optionss}></Line>
              </div>
            </Card>
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default Home;
