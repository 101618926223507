import { Input, Checkbox, Button, Form, Typography, message, Row, Col, Spin } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import logo from "src/assets/images/logo/stemclass-logo-blue.png";
import { useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import { reset_object } from "src/utils/helpers";
import "./forgot_password.css";

const { Title, Text } = Typography;

const ForgotPassword: React.FC = () => {

  const [emailInfo, setEmailInfo] = useState({
    emailid: "",
    });

  const [disableGenPasswordButton, setDisableGenPasswordButton] = useState(false);
  const [generatingPassword, setGeneratingPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const forgotPassword = () => {
    setDisableGenPasswordButton(true)
    setGeneratingPassword(true)
    setLoading(true)
    if (emailInfo.emailid == "" ) {
          message.error('Enter the email address you use to login.');
        }
    else{
    CommunicationService.post(APIStandards.GUEST.FORGOT_USER_PASSWORD, { 
      "emailid": emailInfo.emailid,
      })
      .then(() => {
        message.success(
          "Login with the new temporary password that has been sent to your email to create a new password!", 3
        );
       
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/"; 
      },3500);
      
      })
      .catch((err: any) => {
      //  TO display: 'Enter the current password correctly!'
        message.error(err.response.data.error);
      setDisableGenPasswordButton(false)
      setGeneratingPassword(false)
      setLoading(false)
      })
    .catch((err: any) => {
      message.error("Error occured. Please try again!");
      setDisableGenPasswordButton(false)
      setGeneratingPassword(false)
      setLoading(false)
    });
  }

 
  };

  return (

    <div className="container">
    <div className="login-panel" style={{ width: "100%" }}>

    <Spin spinning={loading} size="large" tip="Generating Password...">
      <div>
        <Row>
          <Col span={4} offset={4}>
          <img src={logo} style={{ height: "110px", marginBottom:"30px" }} />
          </Col>
        </Row>
      <Row>
      <Col>

      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={4}>Reset your password!</Title>
        <Text style={{ fontSize: 16 }} disabled>
          Enter your email address to receive<br />  a new temperory password,<br /> which you can you use to set a new password.
        </Text>
      </div>
         <Form
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={() => {}}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
        
          <Form.Item
            label="Email"
            name="username"
            rules={[{required: true, message: "Please enter your email!" }]}
          >
            <Input
              placeholder="Email"
              prefix={<UserOutlined />}
              size="large"
              onChange={(e) => {
                setEmailInfo({ ...emailInfo, emailid: e.target.value });
              }}
            />
          </Form.Item>



          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              icon={<LoginOutlined />}
              disabled={disableGenPasswordButton}
              onClick={forgotPassword}
            >
              {/* Generate Password */}
              {generatingPassword ? 'Generating Password ...' : 'Generate Password'}
            </Button>
          </Form.Item> 
        </Form> 
            </Col>
          </Row>
        </div>
      </Spin>  
      </div>
      </div>
      
  );
};

export default ForgotPassword;
