import { Row, Col, Card, Typography, Anchor } from "antd";
import React, { useEffect, useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import axios from 'axios';
import PowerBIReportWrapper from "src/components/common/powerbi/reports/powerbireportwrapper";

export default function StudentHome() {

  const deployment_type = process.env.REACT_APP_deployment_type || "dev";
  const [reportConfig, setReportConfig] = useState({
    reportId: '',
    embedUrl: '',
    accessToken: ''
  });
  const [powerbiloading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>({});

  const fetchReportConfig = async (userDetails: any) => {
    try {
      let reportName = deployment_type.toString() === 'prod' ? "learners dashboard" : "learners dashboard-dev2-DB";
      const response = await axios.get('https://steamcubepowerbiembed.azurewebsites.net/api/httppowerbiembed',{
        params: {
          code: 'rb-5uyB5zQDSzGZkGDzI7jQxIUe0PNUwDIPVS3G0aHBwAzFuhglfkg==',
          workspaceId: '6d4ca98c-b755-4326-8543-0a77cf9bd330',
          reportName: reportName
        }
      });

      // Assuming the returned JSON structure includes `reportId`, `embedUrl`, `accessToken`
      const data = response.data;
      const modified_embedUrl = `${data.embedUrl}&filter=steamcube_x0020_vw_student_password_change/Email_x0020_Address eq '${userDetails.userdetails.username}'`;
      // steamcube vw_teaching_sessions/username

      // steamcube vw_teacher_school_credentials/Teacher Name
      // steamcube vw_teacher_school_credentials/Teacher email
      setReportConfig({
        reportId: data.reportId,
        embedUrl: modified_embedUrl,
        accessToken: data.embedToken
      });

      setLoading(false); // Set loading to false as fetch is complete
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userJson = localStorage.getItem("userDetails");
    if (userJson) {
      const userDetails = JSON.parse(userJson);
      setUserDetails(userDetails);
      fetchReportConfig(userDetails);
    }
  }, [0]);

  const getCurrentAnchor = () => '#components-anchor-demo-static';

  if (powerbiloading) {    
    return <div>Loading report...</div>;
  }

  return (
    <div>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Card size="default" title="Learner Dashboard">
            <Typography.Text style= {{fontSize:16}}>
            <p>Welcome to the Learner's Dashboard! Here you can access various functionalities related to your learning journey.</p>
            <p>If you have registered for the STEAM Ambassador Certification Program, please click on the "Courses" link in the left menu.</p>
            <p>For help on navigation and using the platform, please refer to the video link(s) below:
              <Anchor 
                affix={false}
                getCurrentAnchor={getCurrentAnchor}
                items={[
                  {
                    key: 'youtubevideo-navigationuses',
                    href: 'https://www.youtube.com/watch?v=Z6ZzuObEGeo',
                    title: (
                      <Typography.Text style={{ fontSize: 16, color: 'blue' }}>
                        Navigation and usage
                      </Typography.Text>
                    ),
                    target: '_blank',
                  }
                ]}
              
              />
              </p>
            
            </Typography.Text>
          </Card>
          <Card size="default" title="Learner Dashboard">
            <PowerBIReportWrapper
                reportId={reportConfig.reportId}
                embedUrl={reportConfig.embedUrl}
                accessToken={reportConfig.accessToken}
                // width="100%" // You can adjust this as needed
                // height="850px" // You can adjust this as needed
              />
          </Card>
        </Col>
      </Row>
     </div>
  );
}
