import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import React, { useState } from "react";
import { FaFilePdf, FaFilePowerpoint } from "react-icons/fa";
import ContentManagementServices from "src/services/content_management_services";
import APIStandards from "src/utils/API_standards";
import ContentStandards from "src/utils/content_standards";
import { json } from "stream/consumers";

export default function FileUpload({ fileType }: any) {
  const [fileName, setFileName] = useState("");

  const getContentType = (): string => {
    if (fileType == "pdf") return "application/pdf";

    if (fileType == "pptx")
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";

    return "";
  };

  const getTitle = () => {
    if (fileType == "pptx") return "Powerpoint";
    if (fileType == "pdf") return "PDF";
  };

  const getFileIcon = () => {
    if (fileType == "pptx") {
      return <FaFilePowerpoint />;
    }
    if (fileType == "pdf") {
      return <FaFilePdf />;
    }
  };

  const saveFile = () => {
    let contentType: number = 0;

    if (fileType == "pptx") {
      contentType = ContentStandards.PPT;
    }
    if (fileType == "pdf") {
      contentType = ContentStandards.PDF;
    }

    ContentManagementServices.addContent(
      contentType,
      "",
      JSON.stringify({ filename: fileName })
    )
      .then((msg) => {
        message.success("Content added successfully!");
        const closeButton: any = document.querySelectorAll(
          'button[aria-label="Close"]'
        );
        console.log({ closeButton: closeButton });
        closeButton[closeButton.length - 1].click();
      })
      .catch((err) => {
        message.error("SOmething went wring, please try again!");
      });
  };

  const props: UploadProps = {
    name: "file",
    accept: getContentType(),
    multiple: false,
    action: APIStandards.AKS_RWRITE_URL + APIStandards.PUBLIC.UPLOAD_FILE,
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: { lessons: localStorage.getItem("teacher_selected_lessons") },
    beforeUpload: (file) => {
      const valid = file.type === getContentType();
      if (!valid) {
        message.error(`${file.name} is not a ${getTitle()} file`);
      }
      return valid || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        const fileName = JSON.parse(info.file.xhr.response).content.filename;
        setFileName(fileName);
      } else if (info.file.status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "70vh",
      }}
    >
      <Row>
        <Col span={24}>
          <Typography.Title level={5}>
            Upload a {getTitle()} File to your content
          </Typography.Title>
          <Typography.Text>
            File uploaded here are visible in your prepared contents!
          </Typography.Text>
        </Col>
        <Divider />
      </Row>

      <Row>
        <Col
          span={24}
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Upload {...props}>
            <Button size="large" type="dashed" icon={getFileIcon()}>
              &nbsp; Choose File
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row>
        <Divider></Divider>
        <Col span={24}>
          <Button
            className="float-right"
            type="primary"
            disabled={!fileName}
            onClick={saveFile}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}
