import { Row, Col, Card, Typography } from "antd";
import Column from "antd/es/table/Column";
import React, { useState } from "react";
import { UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import communication_service from "src/services/communication_service";
import API_standards from "src/utils/API_standards";
import content_standards from "src/utils/content_standards";
import user_types from "src/utils/user_types";

const { Title, Text } = Typography;

export default function ChallengeViewer(props: any) {
  const [activityContent, setActivityContent] = useState<any>(
    JSON.parse(props["content"]["content"])
  );
  const [challengeFileList, setChallegeFileList] = useState<UploadFile[]>(
    props['challengeFileName']
      ?
      [{
        uid: "123",
        name: props['challengeFileName'],
        url: "#",
        status: "done"
      }]
      :
      []
  );
  const [challengeUploading, setChallengeUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);


  const getFileContentAsBase64 = async (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    })



  const isHandsOnUploaded = () => {
    // when there is no handson activity fot the topic
    if (props.handsOnFileName === 'No_HandsOn_Activity') {
      uploadChallenge();
    }
    else if (props.uploadedHandsOnFileName) {
      uploadChallenge();
    }
    else if (props.handsOnFileName.length > 0) {
      uploadChallenge();
    }
    else if (props.handsOnFileName.length == 0) {
      message.error("Please upload hands-on file first!");
    }
  }


  const uploadChallenge = async () => {

    if (!invalidFile) {
      setChallengeUploading(true);
      communication_service
        .post(API_standards.STUDENT.ADD_CONTENT, {
          content_type: content_standards.CHALLENGE,
          reference_id: props["content"]["_id"],
          selectedtopic: props["content"]["lesson_id"],
          content: JSON.stringify(activityContent),
          file_content: await getFileContentAsBase64(challengeFileList[0]),
          file_name: fileName
        })
        .then((res: any) => {
          message.success("Your file has been successfully uploaded!");
          setChallengeUploading(false);
          setChallegeFileList([{ uid: "123", name: res.data.content.filename, url: "#", status: "done" }])
        })
        .catch((err: any) => {
          message.error("Failed to upload your file, please try again!");
          setChallengeUploading(false);
        });
    }
    else {
      message.error("Upload a valid file!")
    }

  };

  const handleUploadChallenge = () => {
    isHandsOnUploaded();
  }


  const challengeProps: UploadProps = {
    accept: ".png,.jpg,.jpeg,.mp4,.mov,.mkv,.m4v,.avi,.flv,.zip,.rar",
    onRemove: (file) => {
      const index = challengeFileList.indexOf(file);
      const newFileList = challengeFileList.slice();
      newFileList.splice(index, 1);
      setChallegeFileList(newFileList);
    },
    beforeUpload: (file) => {
      // uploaded file should be less than 75 MB
      const isLt5M = file.size / 1024 / 1024 < 75;

      if (!isLt5M) {
        message.error('File msut be smaller than 75MB!');
        setInvalidFile(true)
      }
      else {
        // if valid file is uploaded then add it to the file list
        setChallegeFileList([file]);
        setFileName(file.name);
        setInvalidFile(false);
      }
      return false;
    },
    fileList: challengeFileList,
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card title={"Objective of the activity"}>
            <div dangerouslySetInnerHTML={{ __html: activityContent[0] }} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <Card title={"Submit your file for completing Challenge Activity"}>
            <div >
              <Text style={{ fontSize: 16 }}>
                Supported Files: .txt,.png,.jpg,.jpeg,.mp4,.mov,.mkv,.m4v,.avi,.flv,.zip,.rar
              </Text>
              <br />
              <Text style={{ fontSize: 16 }}>
                Supported File size: Less than 75 MB.
              </Text>
              <br />
              <Text style={{ fontSize: 16 }}>
                <span style={{ fontWeight: "bold" }}>Note:</span> <span style={{ fontStyle: "italic" }}>You can only upload one file. If you have more than one, zip it and upload.</span>
              </Text>
              <br />
              <br />
              <Upload {...challengeProps} >
                {/* <Upload > */}
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
              <Button
                icon={<CloudUploadOutlined />}
                type="primary"
                onClick={handleUploadChallenge}
                disabled={challengeFileList.length === 0 || challengeFileList.length > 1 || challengeFileList[0].status == 'done'}
                loading={challengeUploading}
                style={{ marginTop: 16 }}
              >
                {challengeUploading ? 'Uploading' : 'Start Upload'}
                {/* Start Upload */}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
