import React, { useState } from 'react';
import type { TableProps } from 'antd';
import { Form, Input, InputNumber, Popconfirm, Table, Typography, DatePicker, Row, Col, Button, Divider, Spin, message } from 'antd';
import { CaretRightOutlined, CaretDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CommunicationService from "src/services/communication_service";
import TeacherServices from "src/services/teacher_services";
import APIStandards from "src/utils/API_standards";
import "./lesson_plan.css";
import CurriculumCourseTreeSelect from 'src/components/common/curriculum_course_select/curriculum_course_select';

interface Item {
  key: string;
  chaptername: string;
  topicname: string;
  topicid: string;
  plannednoofperiods: number;
  noofperiods: number;
  startdate: dayjs.Dayjs;
  enddate: dayjs.Dayjs;
}

const originData: Item[] = [];
// for (let i = 0; i < 1; i++) {
//   originData.push({
//     key: i.toString(),
//     chaptername: `Chapter ${i}`,
//     topicname: `Topic ${i}`,
//     topicid: `topicid ${i}`,
//     plannednoofperiods: i,
//     noofperiods: i,
//     startdate: dayjs(),
//     enddate: dayjs(),
//   });
// }
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'date' | 'text';
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;
  if (inputType === 'number') {
    inputNode = <InputNumber />;
  } else if (inputType === 'date') {
    inputNode = <DatePicker
                  showTime={false}
                  format={'DD-MM-YYYY'}
                  picker='date'
                  />;
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function LessonPlan() {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [isLessonPlanLoading, setIsLessonPlanLoading] = useState(false);
  const [isLessonPlanVisible, setIsLessonPlanVisible] = useState(false);
  const [planExists, setPlanExists] = useState(false);
  const [selectedbookid, setselectedbookid] = useState('');
  const [saveLessonPlanLoading, setsaveLessonPlanLoading] = useState(false);

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ chaptername: '', topicname: '', topicid: '', plannednoofperiods: 0, noofperiods: 0, startdate: '', enddate: '', ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Chapter name',
      dataIndex: 'chaptername',
      width: '15%',
      editable: false,
    },
    {
      title: 'Topic name',
      dataIndex: 'topicname',
      width: '35%',
      editable: false,
    },
    {
      title: 'Topic Id',
      dataIndex: 'topicid',
      width: '5%',
      editable: false,
    },
    {
      title: 'Planned no. of periods',
      dataIndex: 'plannednoofperiods',
      width: '5%',
      editable: false,
      inputType: 'number',
    },
    {
      title: 'No. of periods',
      dataIndex: 'noofperiods',
      width: '5%',
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Start date',
      dataIndex: 'startdate',
      width: '10%',
      editable: true,
      inputType: 'date',
      render: (text: any, record: Item) => {
        return dayjs(record.startdate).format('DD-MM-YYYY'); // Format as string
      },
    },
    {
      title: 'End date',
      dataIndex: 'enddate',
      width: '10%',
      editable: true,
      inputType: 'date',
      render: (text: any, record: Item) => {
        return dayjs(record.enddate).format('DD-MM-YYYY'); // Format as string
      },
    },
    {
      title: 'Action?',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Update
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns: TableProps['columns'] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'noofperiods'? 'number' : col.dataIndex === 'startdate' || col.dataIndex === 'enddate'? 'date' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onSelectHandler = (value: string, classdiv: string, source: string) => {
    console.log("v3: classes onSelectHandler value: ", value)
    console.log("v3: classes onSelectHandler classdiv: ", classdiv)
    console.log("v3: classes onSelectHandler source: ", source)
    setselectedbookid(value);
    setIsLessonPlanVisible(false);
    setIsLessonPlanLoading(true);
    CommunicationService.post(
      APIStandards.TEACHER.GET_LESSON_PLAN_CHAPTER_TOPIC,
      { 
        bookid: value,
        curriculumyear: '2024-2025',
        curriculumname: 'CBSE',
      }
    )
      .then((response: any) => {
        console.log("v3: origin data: ", originData);
        console.log("v3: response data: ", response.data.subjectlist);
        const convertedData = response.data.subjectlist.map((item: any) => {
          return {
           ...item,
            startdate: dayjs(item.startdate, 'DD-MM-YYYY'),
            enddate: dayjs(item.enddate, 'DD-MM-YYYY'),
          }
        }
        );
        setPlanExists(response.data.planexists);
      setData(convertedData);
      setIsLessonPlanLoading(false);
      setIsLessonPlanVisible(true);
      })
      .catch ((error: any) => {
      console.error("Error fetching lesson plan details: ", error);
      setData(originData);
      setIsLessonPlanLoading(false);
      setIsLessonPlanVisible(true);

    });
  };

  const saveLessonPlan = async () => {
    try {
      setsaveLessonPlanLoading(true);
      const row = (await form.validateFields()) as Item;

      // Check if the form data is empty or invalid
      if (!row ||!Object.values(row).every(value => value!== null && value!== '')) {
        message.error('Please fill in all required fields before saving.');
        return;
      }
  
      const newData = [...data].map((item) => {
        return {
         ...item,
          startdate: item.startdate.format('YYYY-MM-DD'), // Convert startdate to string
          enddate: item.enddate.format('YYYY-MM-DD'), // Convert enddate to string
        };
      });
  
      // Call the new API
      const response = await CommunicationService.post(APIStandards.TEACHER.UPDATE_LESSON_PLAN, {
        lessonplandata: newData,
        bookid: selectedbookid,
        planexists: planExists,
        curriculumyear: '2024-2025',
        curriculumname: 'CBSE',
      });
  
      if (response.status === 200) {
        message.success('Lesson plan saved successfully!');
      } else {
        message.error('Failed to save lesson plan. Please try again.');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error('Failed to save lesson plan. Please try again.');
    }
    setsaveLessonPlanLoading(false);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            Let's plan the Lesson's time table for the Year!<br />
          </Typography.Title>
          <Typography.Text style= {{fontSize:16}}>
          Explore the menu below to discover and choose the Subject and Class/Grade that you would plan for. 
          Simply click on each section (<CaretRightOutlined /> and <CaretDownOutlined />) to reveal more options until you find your specific item of interest.
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <CurriculumCourseTreeSelect onSelect={onSelectHandler} dataType={'curriculum'} treeLevel={'chapters'} >
            {/* {isTutoringLoading && (
              <Spin size="small" style={{ position: 'absolute', top: '50%', left: '50%', marginLeft: -12, marginTop: -12 }} />
              )} */}
          </CurriculumCourseTreeSelect>
        </Col>
      </Row>
      <Divider></Divider>
      {/* <Row> */}
        <div className='lessonplan-app'>
            {/* <Col span={24}> */}
              {isLessonPlanLoading && (
                <Spin size="large" spinning={isLessonPlanLoading} tip="Loading..." >
                  <div style={{ marginTop: 16 }} />
                </Spin>
              )}
              { isLessonPlanVisible && (
                  <Form form={form} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      dataSource={data}
                      columns={mergedColumns}
                      rowClassName="editable-row"
                      pagination={{
                        onChange: cancel,
                      }}
                    />
                  </Form>
                )
              }
          {/* </Col> */}
        </div>
      {/* </Row> */}
      <Divider></Divider>
      <Row justify={"end"}>
        <Col span={5}>
          <Button
            type="primary"
            disabled={ !isLessonPlanVisible }
            size="large"
            block
            onClick={saveLessonPlan}
            loading={saveLessonPlanLoading}
          >
            Save Lesson plan
          </Button>
        </Col>
      </Row>
    </div>
  );
}
