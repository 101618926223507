import { Row, Col, Card, Typography } from "antd";
import Column from "antd/es/table/Column";
import React, { useState, useEffect } from "react";
import { UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import communication_service from "src/services/communication_service";
import API_standards from "src/utils/API_standards";
import content_standards from "src/utils/content_standards";
import user_types from "src/utils/user_types";

const { Title, Text } = Typography;

export default function HandsOnViewer(props: any) {
  const [activityContent, setActivityContent] = useState<any>(
    JSON.parse(props["content"]["content"])
  );

  const [handsOnFileList, setHandsOnFileList] = useState<UploadFile[]>(
    props['handsOnFileName']
      ?
      [{
        uid: "123",
        name: props['handsOnFileName'],
        url: "#",
        status: "done"
      }]
      :
      []
  );
  const [handsOnuploading, setHandsOnUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);


  const getFileContentAsBase64 = async (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    })



  const handleUploadHandsOn = async () => {

    setHandsOnUploading(true);
    if (!invalidFile) {
      communication_service
        .post(API_standards.STUDENT.ADD_CONTENT, {
          content_type: content_standards.HANDS_ON,
          reference_id: props["content"]["_id"],
          selectedtopic: props["content"]["lesson_id"],
          // content: JSON.stringify(quizContent),
          content: JSON.stringify(activityContent),
          file_content: await getFileContentAsBase64(handsOnFileList[0]),
          file_name: fileName
        })
        .then((res: any) => {
          message.success("Your file has been successfully uploaded!");
          setHandsOnUploading(false);
          setHandsOnFileList([{ uid: "123", name: res.data.content.filename, url: "#", status: "done" }])
          props.uploadedHandsOnFileName(res.data.content.filename)
        })
        .catch((err: any) => {
          message.error("Failed to upload your file, please try again!");
          setHandsOnUploading(false);
        });
    }
    else {
      message.error("Upload a valid file!")
    }

  };


  const handsOnProps: UploadProps = {
    defaultFileList: [{ uid: "123", name: "sfdsfsf", url: "#", status: "done" }, { uid: "1234", name: "ssfdfdsfsf", url: "#", status: "done" }],
    accept: ".txt,.png,.jpg,.jpeg,.mp4,.mov,.mkv,.m4v,.avi,.flv,.zip,.rar",
    onRemove: (file) => {
      const index = handsOnFileList.indexOf(file);
      const newFileList = handsOnFileList.slice();
      newFileList.splice(index, 1);
      setHandsOnFileList(newFileList);
    },
    beforeUpload: (file) => {
      // uploaded file should be less than 75 MB
      const isLt5M = file.size / 1024 / 1024 < 75;

      if (!isLt5M) {
        message.error('File must smaller than 75MB!');
        setInvalidFile(true)
      }
      else {
        // if valid file is uploaded then add it to the file list
        setHandsOnFileList([file]);
        setFileName(file.name)
        setInvalidFile(false)
      }
      return false;
    },
    fileList: handsOnFileList,
  };


  return (
    <>
      <Row>
        <Col span={24}>
          <Card title={"Objective of the activity"}>
            <div dangerouslySetInnerHTML={{ __html: activityContent[0] }} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <Card title={"Materials Required for the activity"}>
            <div dangerouslySetInnerHTML={{ __html: activityContent[1] }} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <Card title={"Instructions"}>
            <div dangerouslySetInnerHTML={{ __html: activityContent[2] }} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <Card title={"MaxMarks"}>
            <div dangerouslySetInnerHTML={{ __html: activityContent[3] }} />
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <Card title={"Submit your file for completing Hands-on Activity"} >
            <>
              <Text style={{ fontSize: 16 }}>
                Supported Files: .png,.jpg,.jpeg,.mp4,.mov,.mkv,.m4v,.avi,.flv,.zip,.rar
              </Text>
              <br />
              <Text style={{ fontSize: 16 }}>
                Supported File size: Less than 75 MB.
              </Text>
              <br />
              <Text style={{ fontSize: 16 }}>
                <span style={{ fontWeight: "bold" }}>Note:</span> <span style={{ fontStyle: "italic" }}>You can only upload one file. If you have more than one, zip it and upload.</span>
              </Text>
              <br />
              <br />
              <Upload {...handsOnProps}>
                {/* <Upload > */}
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
              <Button
                icon={<CloudUploadOutlined />}
                type="primary"
                onClick={handleUploadHandsOn}
                disabled={handsOnFileList.length === 0 || handsOnFileList.length > 1 || handsOnFileList[0].status == 'done'}
                loading={handsOnuploading}
                style={{ marginTop: 16 }}
              >
                {handsOnuploading ? 'Uploading' : 'Start Upload'}
                {/* Start Upload */}
              </Button>
              {/* {content["content"]['filename']} */}
              {/* {handsOnFileName} */}
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
}
