import {
  LikeOutlined,
  MessageOutlined,
  PlusOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Avatar, Button, List, message, Modal, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaBook } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import AssignSubject from "src/components/school/assign_subject/assign_subject";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";

type TeacherMapping = {
  _id: string;
  standard: string;
  division: string;
  subject: string;
  teacher_details: { name: string }[];
  // Other properties
};

export default function TeacherSubjectMapping() {
  const [subjectMapingModal, setSubjectMapingModal] = useState(false);
  const [teacherMappings, setTeacherMappings] = useState<TeacherMapping[]>([]);

  useEffect(() => {
    getMappings();
  }, []);

  const assignTeacher = (mappingData: any) => {
    CommunicationService.post(APIStandards.SCHOOL.ASSIGN_SUBJECTS, mappingData)
      .then((data: any) => {
        getMappings();
        message.success("Teacher Assigned Successfully.");
      })
      .catch((err: any) => {
        message.error("Failed to assign teacher, Please try again. ");
      });
  };

  const getMappings = () => {
    CommunicationService.post(APIStandards.SCHOOL.GET_SUBJECT_ASSIGN, {})
      .then((data: any) => {
        setTeacherMappings(data.data);
      })
      .catch((err: any) => {
        message.error("Failed to get mappings, please try again! ");
      });
  };
  const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <div>
      <List
        style={{ backgroundColor: "white" }}
        header={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={5}>
              Assign Subject, Class and divison to a Teacher
            </Typography.Title>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setSubjectMapingModal(true);
              }}
            >
              New
            </Button>
          </div>
        }
        bordered
        dataSource={teacherMappings.slice().sort((a, b) => {
          // Sort by 'standard' first, then by 'division'
          if (a.standard !== b.standard) {
            return parseInt(a.standard, 10) - parseInt(b.standard, 10);
          } else {
            return a.division.localeCompare(b.division);
          }
        })}
        renderItem={(item: any) => (
          <List.Item
            style={{ fontWeight: "bold" }}
            key={item._id}
            actions={[
              <IconText
                icon={SiGoogleclassroom}
                text={item.standard + " " + item.division}
                key="list-vertical-star-o"
              />,
              <IconText
                icon={FaBook}
                text={item.subject}
                key="list-vertical-message"
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png"
                  }
                />
              }
              title={
                <a
                  href="https://ant.design"
                  aria-disabled
                  style={{ pointerEvents: "none", cursor: "default" }}
                >
                  {item?.teacher_details[0]?.name}
                </a>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        open={subjectMapingModal}
        closable
        footer={null}
        onCancel={() => {
          setSubjectMapingModal(false);
        }}
      >
        <AssignSubject onSave={assignTeacher}></AssignSubject>
      </Modal>
    </div>
  );
}
