/**
 * It's a text editor that takes in a function to be called when the text changes and the initial text
 * to be displayed
 * @param {any}  - onChange - a function that will be called when the editor's content changes.
 * @returns A React component that renders a text editor.
 */
import React, { useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";
import textEditorOptions from "./text_editor_options";
import { Button, Modal, Switch, Space } from "antd";
import uuid from "react-native-uuid";
import html2canvas from "html2canvas";

declare var GGBApplet: any;

export default function TextEditor({ onChange, data }: any) {
  const editor = useRef<SunEditorCore>();
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };
  const sunRef: any = useRef(null);
  const [hideToolbar, setHideToolbar] = useState(false);
  const [isEqEditorOn, setIsEqEditorOn] = useState(false);
  const [ggbId, setGGBId] = useState<any>(uuid.v4());

  // Update the SunEditor options with the new hideToolbar value
  useEffect(() => {
    if (editor.current) {
      if (!hideToolbar){
        editor.current.toolbar.hide();
      }
      else{
        editor.current.toolbar.show();
      }
    }
  }, [hideToolbar]);
  
  useEffect(() => {
    console.log("v3: text_editor entering default effect: ");
    setTimeout(() => {
      const geogibra_btn_ul = document.createElement("li");
      const geogibra_btn = `<button type="button" id="invoke_geogibra"  class="se-btn se-tooltip" data-display="submenu" aria-label="Equation Editor" tabindex="-1"><svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 1920 1920">
                            <path d="M1919.989 168.955V394.95h-832.716l-476.16 1251.388-212.78-4.743-194.373-588.537H-.01V827.176h285.515l107.294 77.59L513.08 1268.89 903.857 241.802l105.6-72.847h910.532ZM1265.72 788.99l240.177 240.176 240.162-240.12 159.7 159.586-240.2 240.197 240.2 240.198-159.7 159.586-240.163-240.12-240.176 240.177-159.698-159.7 240.183-240.141-240.183-240.14 159.698-159.7Z" fill-rule="evenodd"/>
                            </svg><span class="se-tooltip-inner"><span class="se-tooltip-text">Geogibra Equation Editor</span></span></button>`;
      geogibra_btn_ul.innerHTML = geogibra_btn;
      const menu_list = sunRef.current.getElementsByClassName("se-menu-list");
      geogibra_btn_ul.addEventListener("click", () => {
        setIsEqEditorOn(true);
        setTimeout(() => {
          var parameters = {
            id: ggbId,
            useBrowserForJS: false,
            app: false,
            appName: "evaluator",
            scaleContainerClass: "h-100",
            fontSize: 32,
            // specify the rounding for a calculation (for state.eval)
            rounding: 6,
            language: "en",
            keyboardType: "normal",
            //or "keyboardType": "normal",
            // useful if you have several editors on a page
            preventFocus: false,
          };
          var applet = new GGBApplet(parameters, true);
          applet.inject(ggbId);
        }, 100);
      });

      menu_list[0].appendChild(geogibra_btn_ul);
    }, 500);
  }, [0]);

  return (
    <div ref={sunRef}>
      <Space direction="vertical">
        <Switch
          checked={hideToolbar}
          onChange={(value) => {
            setHideToolbar(value);
          }}
        /> Enable Rich Text<br></br>
        <SunEditor
          onChange={onChange}
          defaultValue={data}
          getSunEditorInstance={getSunEditorInstance}
          setOptions={{ ...textEditorOptions }}
        />
      </Space>
      <Modal
        open={isEqEditorOn}
        closable={true}
        destroyOnClose={true}
        onCancel={() => {
          setIsEqEditorOn(false);
        }}
        footer={null}
      >
        <div id={"outer_" + ggbId} style={{ width: "fit-content" }}>
          <div style={{ height: "100% !important" }} id={ggbId}></div>
        </div>
        <Button
          className="mt-5"
          type="primary"
          onClick={() => {
            html2canvas(document.getElementById("outer_" + ggbId)!).then(
              (canvas: any) => {
                var myImage = canvas.toDataURL("image/png");
                var img = document.createElement("img");
                img.src = myImage;
                editor.current?.insertHTML(img);
              }
            );
          }}
        >
          Insert Equation
        </Button>
      </Modal>
    </div>
  );
}
