import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  HomeTwoTone,
} from "@ant-design/icons";
import { Avatar, Divider, Menu, Typography } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { DashboardContext } from "src/contexts/dashboard_context";
import "./sidebar.css";
import logo from "src/assets/images/logo/stemclass-logo-blue.png";
import vlogo from "src/assets/images/logo/stemclass-logo-horizontal.png";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ menuItem }: { menuItem: any }) => {
  const navigate = useNavigate();
  const { sidebar, toggleSidebar } = React.useContext(DashboardContext);
  const { Text, Title } = Typography;

  return (
    <Sider trigger={null} collapsible collapsed={false}>
      <div className="logo">
        <div className="inner-logo">
          <img src={vlogo} style={{ width: "100%" }} />
        </div>
        <Divider dashed />
      </div>

      <Menu
        className="menu"
        theme="light"
        mode="inline"
        defaultSelectedKeys={[menuItem[0].key]}
        style={{ border: "none", fontSize: 16, }}
        items={menuItem}
        onClick={({ key }) => {
          navigate(key);
        }}
      />
    </Sider>
  );
};

export default Sidebar;
