import { BsFillPatchQuestionFill } from "react-icons/bs";
import { FaFilePdf, FaFilePowerpoint } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import ContentStandards from "./content_standards";
import { AiFillExperiment } from "react-icons/ai";
import { GiThink } from "react-icons/gi";

const reset_object = (data: any) => {
  let new_data = {};

  for (const [k, v] of Object.entries(data)) {
    new_data = { ...new_data, k: "" };
  }

  return new_data;
};

const get_content_title = (type: any) => {
  if (type == 7) {
    return {
      title: "Powerpoint",
      icon: <FaFilePowerpoint size={40} />,
      description: "Powerpoint presentation added by you.",
    };
  }
  if (type == 5)
    return {
      title: "Video",
      icon: <IoLogoYoutube size={40} />,
      description: "A youtube video with custom modifications",
    };
  if (type == 6)
    return {
      title: "PDF",
      icon: <FaFilePdf size={40} />,
      description: "PDF File uploaded by you.",
    };
  if (type == 4)
    return {
      title: "Questions",
      icon: <BsFillPatchQuestionFill size={40} />,
      description: "Questions added by you.",
    };
  if (type == ContentStandards.HANDS_ON) {
    return {
      title: "Hands-On Activity",
      icon: <AiFillExperiment size={40} />,
      description: "Hand-On activity added by you.",
    };
  }
  if (type == ContentStandards.CHALLENGE) {
    return {
      title: "Challenge Activity",
      icon: <GiThink size={40} />,
      description: "Challenge activity added by you.",
    };
  }
  if (type == 0) {
    return {
      title: "Chapter",
    };
  }
  if (type == 1) {
    return {
      title: "Topic",
    };
  }
  if (type == 2) {
    return {
      title: "Learning Outcome",
    };
  }
  if (type == 3) {
    return {
      title: "Learning Objective",
    };
  }
};

export { reset_object, get_content_title };
