import { DownloadOutlined, PlusOutlined, SaveFilled, CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import {
  FcAddImage,
  FcCollaboration,
  FcEngineering,
  FcMakeDecision,
  FcOnlineSupport,
  FcQuestions,
} from "react-icons/fc";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  List,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  Spin,
  Table
} from "antd";
import { Module } from "module";
import React, { useEffect, useRef } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import ContentStandards from "src/utils/content_standards";
import TeacherServices from "src/services/teacher_services";
import useState from "react-usestateref";
import PreviewModal from "src/components/common/content_viewer/preview_modal";
import { setPriority } from "os";
import { YouTubePlayer } from "react-youtube";
import CurriculumCourseTreeSelect from "src/components/common/curriculum_course_select/curriculum_course_select";
import ReviewCard from  "./reviewstudent";
import QuizReview, { QuizReviewData } from "src/components/common/content_creation/quizz/quizreview";
import HandsOnReview, {HandsOnReviewData}  from "src/components/common/content_creation/handson/handsonreview";
import ChallengeReview, {ChallengeReviewData}  from "src/components/common/content_creation/challenge/challengereview";

const { Text, Title } = Typography;
declare global {
  var videoElement: YouTubePlayer;
  // var videoRef: any;
}

 const initRecord  = {
    // "key": 35,
    "key": -999 ,
    // "classDivision": "STEAMCUBEClass 1, A",
    "classDivision": "",
    // "classDivkey": 7,
    "classDivkey": "" ,
    // "studentName": "Steamcourse nan Student5",
    "studentName": "",
    // "completedLearningSession": true,
    "completedLearningSession": "",
    // "answersUpdated": true,
    "answersUpdated": "",
    // "reviewCompleted": false,
    "reviewCompleted": "",
    // "isResultDownloadable": false,
    "isResultDownloadable": "",
    // "isReviewable": true
    "isReviewable": "",
    "quizreviewRequired": "",
    "handsonreviewRequired": "",
    "challengereviewRequired":"",
 }

export default function Classes() {
  const [previewModalOpen, SetPreviewModalOpen] = useState(false);
  const [syllabusDropdowns, setSyllabusDropDowns, syllabusDropdownsRef] =
    useState<any>({
      standard: [],
      subject: [],
    });

  const [dropdownSelectData, setDropdownSelectData] = useState<any>({
    syllabus: "CBSE",
  });

  const [slides, setSlides] = useState<any>([{}, {}]);
  const [handsonSteps, setHandsOnSteps] = useState([
    "Objective",
    "Materials Required.",
    "Instructions",
  ]);

  const [divisions, setDivisios] = useState<any>([]);
  const [lessonCompletionStatus, setLessonCompletionStatus] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isReviewCardVisible, setIsReviewCardVisible] = useState(false);
  const [isReviewCardLoading, setIsReviewCardLoading] = useState(false);
  const [isTutoringLoading, setIsTutoringLoading] = useState(false);
  const [teachingsessionid, setTeachingSessionid] = useState<string>('');
  const [classDivisions, setClassDivisions] = useState<string[]>([]);
  const [selectedClassid, setSelectedClass] = useState<number>(-1);
  const [selectedClassDivision, setSelectedClassDivision] = useState<string>("");
  const [, setSelectedClassDivisionRef] = useState<React.SetStateAction<string | undefined> | null>(null);
  const [isClassDivisionModalOpen, setIsClassDivisionModalOpen] = useState(false);
  const [reviewdata, setReviewData] = useState([]);
  const [quizData, setReviewQuizData] = useState([]);
  const [handsOnData, setReviewHandsOnData] = useState([]);
  const [challengeData, setReviewChallengeData] = useState([]);
  const [reviewcontentid, setReviewContentId] = useState<number>(-1);
  const [teacherreviewsessionid, setTeachingReviewSessionid] = useState<string>('');
  const selectedClassDivisionRef = useRef<string | undefined>(undefined);

  const [isQuizReviewModalVisible, setQuizReviewModalVisible] = useState(false);
  const [isHandsOnReviewModalVisible, setHandsOnReviewModalVisible] = useState(false);
  const [isChallengeReviewModalVisible, setChallengeReviewModalVisible] = useState(false);
  const [record, setRecord] = useState(initRecord);
  const [isReviewModalVisible, setReviewModalVisible] = useState(false);

  const [reviewResultscsvData, setreviewResultsCsvData] = useState<any[]>([]);
  const [isreviewResultsCsvModalVisible, setIsreviewResultsCsvModalVisible] = useState(false);

  const [isQuizReviewed, setIsQuizReviewed] = useState(false);
  const [isHandsOnReviewed, setisHandsOnReviewed] = useState(false);
  const [isChallengeReviewed, setIsChallengeReviewed] = useState(false);

  selectedClassDivisionRef.current = selectedClassDivision;
  // const reviewcolumns = [
  //   { title: 'Class-Division', dataIndex: 'classDivision', key: 'classDivision' },
  //   { title: 'Student Name', dataIndex: 'studentName', key: 'studentName' },
  //   { title: 'Completed Learning Session?', dataIndex: 'completedLearningSession', key: 'completedLearningSession', render: (text: any) => text ? 'Yes' : 'No' },
  //   { title: 'Answers Updated?', dataIndex: 'answersUpdated', key: 'answersUpdated', render: (text: any) => text ? 'Yes' : 'No' },
  //   { title: 'Review completed?', dataIndex: 'reviewCompleted', key: 'reviewCompleted', render: (text: any) => text ? 'Yes' : 'No' },
  //   { title: 'Start Review', dataIndex: 'startReview', key: 'startReview' },
  //   { title: 'Download review results', dataIndex: 'downloadResults', key: 'downloadResults' },
  // ];

  const reviewcolumns = [
    { title: 'Class-Division', dataIndex: 'classDivision', key: 'classDivision' },
    { title: 'Student Name', dataIndex: 'studentName', key: 'studentName' },
    { title: 'Completed Learning Session?', dataIndex: 'completedLearningSession', key: 'completedLearningSession', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Quiz Answers Updated?', dataIndex: 'quizanswersUpdated', key: 'quizanswersUpdated', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Quiz Review completed?', dataIndex: 'quizreviewCompleted', key: 'quizreviewCompleted', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Handson Answers Updated?', dataIndex: 'handsonanswersUpdated', key: 'quizanswersUpdated', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Handson Review completed?', dataIndex: 'handsonreviewCompleted', key: 'quizreviewCompleted', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Challenge Answers Updated?', dataIndex: 'challengeanswersUpdated', key: 'quizanswersUpdated', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Challenge Review completed?', dataIndex: 'challengereviewCompleted', key: 'quizreviewCompleted', render: (text: any)  => (
      <div style={{ backgroundColor: text? 'green' : 'red', color: 'white', padding: '2px 5px', borderRadius: '3px', display: 'inline-block', whiteSpace: 'nowrap' }}>
        {text? 'Yes' : 'No'}
      </div>
    ), },
    { title: 'Start Review', dataIndex: 'startReview', key: 'startReview' },
    { title: 'View review results', dataIndex: 'viewResults', key: 'viewResults' },
    { title: 'Quiz review required', dataIndex: 'quizreviewRequired', key: 'quizreviewRequired', isVisible: false },
    { title: 'Handson review required', dataIndex: 'handsonreviewRequired', key: 'handsonreviewRequired', isVisible: false },
    { title: 'Challenge review required', dataIndex: 'challengereviewRequired', key: 'challengereviewRequired', isVisible: false },
  ];


  // const reviewdata = [
  //   { key: 1, classDivision: 'Class 10, Division A', studentName: 'John Doe', completedLearningSession: true, answersUpdated: false, reviewCompleted: false },
  //   // More data objects...
  // ];

  const reviewresultsviewcolumns = [
    {
      title: "Question ID",
      dataIndex: "question_id",
      key: "question_id",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Max Marks",
      dataIndex: "maxMarks",
      key: "maxMarks",
    },
    {
      title: "Answers",
      dataIndex: "answers",
      key: "answers",
    },
    {
      title: "Review Marks",
      dataIndex: "reviewMarks",
      key: "reviewMarks",
    },
    // {
    //   title: "Content ID",
    //   dataIndex: "contentid_id",
    //   key: "contentid_id",
    // },
    {
      title: "Review Comments",
      dataIndex: "reviewComments",
      key: "reviewComments",
    },
  ];

  // useEffect(() => {
  //   // Get the standards from the database and set them as the state of the component.
  //   getSubjects();
  // }, []);

  const getSubjects = () => {
    console.log("v3: course_content_creation_main_model get subjects: ", dropdownSelectData.syllabus)
    CommunicationService.post(APIStandards.PUBLIC.GET_SUBJECTS, {
      syllabus: dropdownSelectData.syllabus,
    }).then((response: any) => {
      setSyllabusDropDowns({ ...syllabusDropdowns, subject: response.data.subjectlist });
    });
  };

  const getDropdownList = (selectedStandard: any) => {
    CommunicationService.post(APIStandards.PUBLIC.GET_DROPDOWN_LIST, {
      ...dropdownSelectData,
      standard: selectedStandard,
    }).then((response: any) => {
      const tSyllabusDropDowns: any = {};
      response.data.dropdownlist.forEach((data: any) => {
        tSyllabusDropDowns[data] = [];
      });
      setSyllabusDropDowns({ ...syllabusDropdowns, ...tSyllabusDropDowns });
      getDropdownData("standard", selectedStandard, 1);
    });
  };

  const getDivisions = (selectedStandard: any) => {
    CommunicationService.post(APIStandards.TEACHER.GET_ASSIGNED_DIVISIONS, {
      ...dropdownSelectData,
      standard: selectedStandard,
    }).then((response: any) => {
      setDivisios(response.data.divisionlist);
    });
  };

  const getDropdownData = (selectedDropDown: any, event: any, idx: any) => {
    let k: any = { ...dropdownSelectData };
    k[selectedDropDown] = event;
    if (k["division"]) {
      setLessonCompletionStatus(false);
      delete k["division"];
    }
    setDropdownSelectData({ ...k });
    let selectedData: any = {};
    let m = [{}, {}];
    Object.keys(k).forEach((key: string, keyIdx) => {
      if (
        key == "syllabus" ||
        key == "subject" ||
        key == "standard" ||
        keyIdx > idx + 1
      ) {
        //do nothing
      } else {
        selectedData[key] = k[key];
      }
    });

    if (idx > 3 && idx >= Object.keys(syllabusDropdowns).length - 1) {
      CommunicationService.post(APIStandards.PUBLIC.GET_SECONDARY_LIST, {
        ...k,
        selected_data: selectedData,
      })
        .then((response: any) => {
          let slide_data = m;
          slide_data[1] = {
            title: "Learning Objectives",
            data: response.data[Object.keys(response.data)[0]],
          };
          setSlides([...slide_data]);
        })
        .finally(() => {
          CommunicationService.post(APIStandards.PUBLIC.GET_TERITIARY_LIST, {
            ...k,
            selected_data: selectedData,
          })
            .then((response: any) => {
              let slide_data = m;
              slide_data[0] = {
                title: "Learning Outcomes",
                data: response.data[Object.keys(response.data)[0]],
              };
              setSlides([...slide_data]);
            })
            .finally(() => {
              let drodownDataCopy = k;
              if (Object.keys(drodownDataCopy).includes("division"))
                delete drodownDataCopy["division"];
              CommunicationService.post(
                APIStandards.TEACHER.GET_LESSON_PREVIEW,
                {
                  ...drodownDataCopy,
                }
              ).then((response: any) => {
                let slide_data = m;
                let new_lessons: any = [];

                response.data.lessons.forEach((item: any) => {
                  item.contents.forEach((content: any) => {
                    new_lessons.push({ ...item, content });
                  });
                });

                setSlides([...slide_data, ...new_lessons, { finished: true }]);
              });
            });
        });
    } else {
      console.log("v3: classes get_dropdown_values:1 ")
      console.log("v3: classes get_dropdown_values:2 ")
      console.log("v3: classes get_dropdown_values:3 ")
      CommunicationService.post(APIStandards.PUBLIC.GET_DROPDOWN_VALUES, {
        ...k,
        selected_data: selectedData,
        target: [Object.keys(syllabusDropdownsRef.current)[idx + 1]],
      }).then((response: any) => {
        let m = { ...syllabusDropdownsRef.current };
        m[Object.keys(syllabusDropdownsRef.current)[idx + 1]] =
          response.data[Object.keys(syllabusDropdownsRef.current)[idx + 1]];
        setSyllabusDropDowns({ ...m });
      });
    }
  };

  const getStandards = async (event: any) => {
    const classes = await TeacherServices.getStandards();
    console.log("v3: classes getstandards: ", classes)
    setSyllabusDropDowns((sylabusData: any) => ({
      ...sylabusData,
      standard: classes,
    }));
  };

  const check_lesson_completion_status = async (division: any) => {
    setLessonCompletionStatus(false);
    CommunicationService.post(
      APIStandards.TEACHER.GET_LESSON_COMPLETION_STATUS,
      { ...dropdownSelectData, division }
    ).then((data: any) => {
      setLessonCompletionStatus(data.data["completion_status"]);
      localStorage.setItem(
        "teaching_lesson_completion_status",
        data.data["completion_status"]
      );
    });
  };
  // globalThis.videoRef= useRef<HTMLVideoElement>(null);

  useEffect(() => {
    console.log("v3: videoElement during pause: ", globalThis.videoElement);
    if (globalThis.videoElement) {
      // get current time
      const elapsed_seconds = globalThis.videoElement.target.getCurrentTime();
      globalThis.videoElement.target.pauseVideo();
      
    }
  }, [isPaused, globalThis.videoElement]);

  const togglePause = () => {
    // console.log("videoRef during pause: ", globalThis.videoRef);
    setIsPaused(!isPaused);
    // if (globalThis.videoRef.current) {
    //   globalThis.videoRef.current.pause();
    // }
  };

  const handleClassDivisionSelect = (value: string) => {
    setSelectedClassDivision(value);
    setIsClassDivisionModalOpen(false);
    setTeachingSessionid("");
    localStorage.setItem("teacherteachingsessionid", "");
  
    // Call the onSelectHandler function with the selected class division
    onSelectHandler(selectedClassDivisionRef.current || "", value, "fromclassdiv");
  };

  const onSelectHandler = async(value: string, classdiv: string, source: string) => {
    console.log("v3: classes onSelectHandler value: ", value)
    console.log("v3: classes onSelectHandler classdiv: ", classdiv)
    console.log("v3: classes onSelectHandler source: ", source)
    if (!source || source !== "fromclassdiv"){
      setIsTutoringLoading(true);
      console.log("v3: classes onSelectHandler value: 2")
      localStorage.setItem("selectedtopic", value);
      console.log("v3: classes onSelectHandler getting class divisions: ", value)
      let m = [{}, {}];
      try {
        const classDivisionsResponse = await CommunicationService.post(
          APIStandards.TEACHER.GET_ASSIGNED_DIVISIONS,
          { selectedtopic: value }
        );
        setClassDivisions(classDivisionsResponse.data.divisionlist);
        setIsClassDivisionModalOpen(true);
      } catch (error) {
        console.error("Error fetching class divisions:", error);
      }
     
      CommunicationService.post(APIStandards.PUBLIC.GET_TOPIC_DETAILS, {
        selectedtopic: value,
      }).then((response: any) => {
        // setEnableAddContent(true);
        // setLearningObjectives(response.data.learningobjectives);
        // setLearningOutcomes(response.data.learningoutcomes)
        localStorage.setItem("tabs_data", JSON.stringify(response.data.tabsdata));
        localStorage.setItem("topiclob_data", JSON.stringify(response.data.topiclob));
        localStorage.setItem("selectedclass", JSON.stringify(response.data.selectedclass));
        setSelectedClass(response.data.selectedclass.class_id)
        let slide_data = m;
        slide_data[0] = {
          title: "Learning Outcomes",
          data: response.data.learningoutcomes,
        };
        slide_data[1] = {
          title: "Learning Objectives",
          data: response.data.learningobjectives,
        };
        setSlides([...slide_data]);
        localStorage.setItem("slidedata", slide_data.toString());
        // setEnableAddContent(true);
      })
      .finally(() => {
        CommunicationService.post(APIStandards.TEACHER.GET_LESSON_PREVIEW, {
          selectedtopic: value,
        }).then((response: any) => {
          let slide_data = m;
          let new_lessons: any = [];

          response.data.lessons.forEach((item: any) => {
            item.contents.forEach((content: any) => {
              new_lessons.push({ ...item, content });
            });
          });

          setSlides([...slide_data, ...new_lessons, { finished: true }]);
        });
      });
      setIsTutoringLoading(false);
    }
    else{
      console.log("v3: classes onSelectHandler 3 value: ", value)
      console.log("v3: classes onSelectHandler 3 classdiv: ", classdiv)
      console.log("v3: classes onSelectHandler 3 source: ", source)
    }
  }

  const handleStartReview = (record: any) => {
    setReviewModalVisible(true);
    setRecord(record)
    // handleStartQuizReview(record)
  } 

  const handleStartQuizReview = () => {
    console.log('Starting review for:', record);
    setIsReviewCardLoading(false);
    setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
    CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
      academicyear: "2024-2025",
      pedagogy: "CBSE",
      semester: "India School 1 Year Semester",
      selectedtopic: localStorage.getItem("selectedtopic"),
      selecteddivision: selectedClassDivision,
      mode: "start",
      classdivkey: record.classDivkey,
      teacherreviewsessionid: teacherreviewsessionid,
    }).then((result: any) => {
      console.log("v3: classes modal: ",result);
      setTeachingReviewSessionid(result.data.teacherreviewsessionid);
      localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
    }).catch((err:any)=>{
      setTeachingReviewSessionid("");
      console.error(err)}
    );
    CommunicationService.post(APIStandards.TEACHER.GET_LESSON_PREVIEW, {
      selectedtopic: localStorage.getItem("selectedtopic"),
      selectedstudent: record.key,
      uploadcontenttype:"quiz"
    })
    .then((response: any) => {
      console.log("v3: review card data: ", response.data);
      setReviewQuizData(response.data.lessons[0].contents[0].content);
      setReviewContentId(response.data.lessons[0].contents[0]._id);
    setQuizReviewModalVisible(true);
    })
    .catch ((error: any) => {
      console.error("Error fetching review records: ", error);
  });
  };

const handleStartHandsOnReview = () => {
  console.log('Starting review for:', record);
  setIsReviewCardLoading(false);
  setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
  CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
    academicyear: "2024-2025",
    pedagogy: "CBSE",
    semester: "India School 1 Year Semester",
    selectedtopic: localStorage.getItem("selectedtopic"),
    selecteddivision: selectedClassDivision,
    mode: "start",
    classdivkey: record.classDivkey,
    teacherreviewsessionid: teacherreviewsessionid,
  }).then((result: any) => {
    console.log("v3: classes modal: ",result);
    setTeachingReviewSessionid(result.data.teacherreviewsessionid);
    localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
  }).catch((err:any)=>{
    setTeachingReviewSessionid("");
    console.error(err)}
  );
  CommunicationService.post(APIStandards.TEACHER.GET_LESSON_PREVIEW, {
    selectedtopic: localStorage.getItem("selectedtopic"),
    selectedstudent: record.key,
    uploadcontenttype:"handson"
  })
  .then((response: any) => {
    console.log("v3: review card data: ", response.data);
    // setReviewQuizData(response.data.lessons[0].contents[0].content);
    // setReviewContentId(response.data.lessons[0].contents[0]._id);
    setReviewHandsOnData(response.data.lessons[0].contents[0]);
    setReviewContentId(response.data.lessons[0].contents[0]._id);
    setHandsOnReviewModalVisible(true);
  })
  .catch ((error: any) => {
    console.error("Error fetching review records: ", error);
});
}


const handleStartChallengeReview = () => {
  console.log('Starting review for:', record);
  setIsReviewCardLoading(false);
  setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
  CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
    academicyear: "2024-2025",
    pedagogy: "CBSE",
    semester: "Steamcube School 1 Year Semester",
    selectedtopic: localStorage.getItem("selectedtopic"),
    selecteddivision: selectedClassDivision,
    mode: "start",
    classdivkey: record.classDivkey,
    teacherreviewsessionid: teacherreviewsessionid,
  }).then((result: any) => {
    console.log("v3: classes modal: ",result);
    setTeachingReviewSessionid(result.data.teacherreviewsessionid);
    localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
  }).catch((err:any)=>{
    setTeachingReviewSessionid("");
    console.error(err)}
  );
  CommunicationService.post(APIStandards.TEACHER.GET_LESSON_PREVIEW, {
    selectedtopic: localStorage.getItem("selectedtopic"),
    selectedstudent: record.key,
    uploadcontenttype:"challenge"
  })
  .then((response: any) => {
    console.log("v3: review card data: ", response.data);
    // setReviewQuizData(response.data.lessons[0].contents[0].content);
    // setReviewContentId(response.data.lessons[0].contents[0]._id);
    setReviewChallengeData(response.data.lessons[0].contents[0]);
    setReviewContentId(response.data.lessons[0].contents[0]._id);
    setChallengeReviewModalVisible(true);
  })
  .catch ((error: any) => {
    console.error("Error fetching review records: ", error);
});
}

const handleQuizReviewSave = (quizReviewData: QuizReviewData) =>{
  console.log("v3: saving review data: ", quizReviewData);
  if (!teacherreviewsessionid || teacherreviewsessionid === '') {
    setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
  }
  CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
    academicyear: "2024-2025",
    pedagogy: "CBSE",
    semester: "Steamcube School 1 Year Semester",
    selectedtopic: localStorage.getItem("selectedtopic"),
    selecteddivision: selectedClassDivision,
    mode: "end",
    teacherreviewsessionid: teacherreviewsessionid,
    // this is where review comments and marks get saved 
    reviewresults: quizReviewData,
    reviewcontentid: reviewcontentid
  }).then((result: any) => {
    console.log("v3: classes modal: ",result);
    setTeachingReviewSessionid(result.data.teacherreviewsessionid);
    localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
    message.success("Your review results have been saved. Thanks!");
    setQuizReviewModalVisible(false);
    setIsQuizReviewed(true);
    handleShowReviewCard();
  }).catch((err:any)=>{
    setTeachingReviewSessionid("");
    console.error(err)}
  );
}
const handleHandsOnReviewSave = (handsOnReviewData: HandsOnReviewData) =>{
  let quizHandsOnSendData = handsOnReviewData;
  quizHandsOnSendData[0].fileContent = ""
  // quizHandsOnSendData[0].fileContent: string = 
  console.log("v3: saving review data: ", handsOnReviewData);
  if (!teacherreviewsessionid || teacherreviewsessionid === '') {
    setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
  }
  CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
    academicyear: "2024-2025",
    pedagogy: "CBSE",
    semester: "Steamcube School 1 Year Semester",
    selectedtopic: localStorage.getItem("selectedtopic"),
    selecteddivision: selectedClassDivision,
    mode: "end",
    teacherreviewsessionid: teacherreviewsessionid,
    // this is where review comments and marks get saved 
    reviewresults: quizHandsOnSendData,
    reviewcontentid: reviewcontentid
  }).then((result: any) => {
    console.log("v3: classes modal: ",result);
    setTeachingReviewSessionid(result.data.teacherreviewsessionid);
    localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
    message.success("Your review results have been saved. Thanks!");
    setHandsOnReviewModalVisible(false);
    setisHandsOnReviewed(true);
    handleShowReviewCard();
  }).catch((err:any)=>{
    setTeachingReviewSessionid("");
    console.error(err)}
  );
}
const handleChallengeReviewSave = (handsOnReviewData: HandsOnReviewData) =>{
  let quizHandsOnSendData = handsOnReviewData;
  quizHandsOnSendData[0].fileContent = ""
  // quizHandsOnSendData[0].fileContent: string = 
  console.log("v3: saving review data: ", handsOnReviewData);
  if (!teacherreviewsessionid || teacherreviewsessionid === '') {
    setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
  }
  CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
    academicyear: "2024-2025",
    pedagogy: "CBSE",
    semester: "Steamcube School 1 Year Semester",
    selectedtopic: localStorage.getItem("selectedtopic"),
    selecteddivision: selectedClassDivision,
    mode: "end",
    teacherreviewsessionid: teacherreviewsessionid,
    // this is where review comments and marks get saved 
    reviewresults: quizHandsOnSendData,
    reviewcontentid: reviewcontentid
  }).then((result: any) => {
    console.log("v3: classes modal: ",result);
    setTeachingReviewSessionid(result.data.teacherreviewsessionid);
    localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
    message.success("Your review results have been saved. Thanks!");
    setChallengeReviewModalVisible(false);
    setIsChallengeReviewed(true);
    handleShowReviewCard();
  }).catch((err:any)=>{
    setTeachingReviewSessionid("");
    console.error(err)}
  );
}
//   const handleDownloadResult = (record: any) => {
//     console.log('v3: Starting download for:', record);
//     CommunicationService.post(APIStandards.TEACHER.GET_REVIEW_RESULTS, {
//       selectedtopic: localStorage.getItem("selectedtopic"),
//       selectedstudent: record.key
//     })
//     .then((response: any) => {
//       console.log("v3: download results data: ", response);
//       const contentDisposition = "attachment; filename=review_results.csv";
//       const header = response.headers;
//       header['Content-Disposition'] = contentDisposition;
//       const url = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", "review_results.csv");
//       document.body.appendChild(link);
//       link.click();
//       if (link.parentNode) {
//         link.parentNode.removeChild(link);
//       }
//     })
//     .catch ((error: any) => {
//       console.error("Error fetching review results: ", error);
//       message.error("Failed to generate and download the result file.");
//   });
// };

const handleDownloadResult = async (record: any) => {
  console.log("v3: Starting download for:", record);
  CommunicationService
   .post(APIStandards.TEACHER.GET_REVIEW_RESULTS, {
      selectedtopic: localStorage.getItem("selectedtopic"),
      selectedstudent: record.key,
    })
   .then((response: any) => {
      const csvData = response.data;
      setreviewResultsCsvData(csvData);
      setIsreviewResultsCsvModalVisible(true);
    })
   .catch((error: any) => {
      console.error("Error fetching review results: ", error);
    });
};

const handlereviewResultsCsvModalOk = () => {
  setIsreviewResultsCsvModalVisible(false);
};

const handlereviewResultsCsvModalCancel = () => {
  setIsreviewResultsCsvModalVisible(false);
};


  const handleShowReviewCard = () => {
      setIsReviewCardLoading(true);
      setTeachingReviewSessionid('');
      localStorage.setItem("teacherreviewsessionid", '');
      CommunicationService.post(
        APIStandards.TEACHER.GET_REVIEW_STUDENTS,
        { 
          academicyear: "2024-2025",
          pedagogy: "CBSE",
          semester: "India School 1 Year Semester",
          selectedtopic: localStorage.getItem("selectedtopic"),
          selecteddivision: selectedClassDivision,
          selectedclass: selectedClassid,
        }
      )
      .then((response: any) => {
      console.log("v3: review card data: ", response.data);
      setReviewData(response.data.teacherreviewdata);
      setIsReviewCardVisible(true);
      })
      .catch ((error: any) => {
      console.error("Error fetching review details: ", error);
    });
    setIsReviewCardLoading(false);
  };

  const handleReviewSave = (quizReviewData: QuizReviewData) =>{
    console.log("v3: saving review data: ", quizReviewData);
    if (!teacherreviewsessionid || teacherreviewsessionid === '') {
      setTeachingReviewSessionid(localStorage.getItem("teacherreviewsessionid") || "");
    }
    CommunicationService.post(APIStandards.TEACHER.LOG_REVIEWING, {
      academicyear: "2024-2025",
      pedagogy: "CBSE",
      semester: "India School 1 Year Semester",
      selectedtopic: localStorage.getItem("selectedtopic"),
      selecteddivision: selectedClassDivision,
      mode: "end",
      teacherreviewsessionid: teacherreviewsessionid,
      reviewresults: quizReviewData,
      reviewcontentid: reviewcontentid
    }).then((result: any) => {
      console.log("v3: classes modal: ",result);
      setTeachingReviewSessionid(result.data.teacherreviewsessionid);
      localStorage.setItem("teacherreviewsessionid", result.data.teacherreviewsessionid);
      message.success("Your review results have been saved. Thanks!");
      setReviewModalVisible(false);
      handleShowReviewCard();
    }).catch((err:any)=>{
      setTeachingReviewSessionid("");
      console.error(err)}
    );
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            Let's teach some lessons today! <br />
          </Typography.Title>
          <Typography.Text style= {{fontSize:16}}>
          Explore the menu below to discover and choose the learning topic you'd like to start with. 
          Simply click on each section (<CaretRightOutlined /> and <CaretDownOutlined />) to reveal more options until you find your specific topic of interest.
          </Typography.Text>
        </Col>
      </Row>
      {/* <Row className="mt-3">
        <Col span={24}>
          <Space direction="horizontal">
            <Form layout="vertical">
              <Form.Item label={"Subject"}>
                <Select
                  className="custom-select"
                  placeholder="Subject"
                  onSelect={async (event: any) => {
                    setDropdownSelectData({
                      ...dropdownSelectData,
                      subject: event,
                    });
                    await getStandards(event);
                  }}
                >
                  {syllabusDropdowns.subject.map((item: any) => {
                    return <Select.Option key={item}>{item}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
            <Form layout="vertical">
              <Form.Item label={"Standard"}>
                <Select
                  className="custom-select"
                  placeholder="Standard"
                  onSelect={async (event) => {
                    await getDropdownList(event);
                    await getDivisions(event);
                  }}
                >
                  {syllabusDropdowns.standard
                    .sort((a: any, b: any) => {
                      return parseInt(a, 10) - parseInt(b, 10);
                    })
                    .map((item: any) => {
                      return <Select.Option key={item}>{item}</Select.Option>;
                    })}
                </Select>
              </Form.Item>
            </Form>
            {Object.keys(syllabusDropdowns).map((dropdown: any, idx: any) => {
              if (dropdown == "standard" || dropdown == "subject") {
                return <></>;
              }
              return (
                <div>
                  <Form layout="vertical">
                    <Form.Item label={dropdown}>
                      <Select
                        className="custom-select"
                        placeholder={`Select ${dropdown}`}
                        onSelect={async (event) => {
                          console.log("reseting slides");
                          setSlides([{}, {}]);
                          await getDropdownData(dropdown, event, idx);
                        }}
                      >
                        {syllabusDropdowns[dropdown].map((item: any) => {
                          return (
                            <Select.Option key={item}>{item}</Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
              );
            })}

            <Form layout="vertical">
              <Form.Item label={"Division"}>
                <Select
                  className="custom-select"
                  placeholder="Select Division"
                  value={dropdownSelectData["division"] || null}
                  onSelect={async (event: any) => {
                    setDropdownSelectData({
                      ...dropdownSelectData,
                      division: event,
                    });
                    check_lesson_completion_status(event);
                  }}
                >
                  {divisions.map((item: any) => {
                    return <Select.Option key={item}>{item}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row> */}
      <Row>
        <Col span={17}>
          <CurriculumCourseTreeSelect onSelect={onSelectHandler} dataType={'curriculum'}>
            {isTutoringLoading && (
              <Spin size="small" style={{ position: 'absolute', top: '50%', left: '50%', marginLeft: -12, marginTop: -12 }} />
              )}
          </CurriculumCourseTreeSelect>
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col span={8}>
          <Button
            type="primary"
            disabled={
              slides.length <= 3 ||
              // !dropdownSelectData.subject ||
              // !dropdownSelectData.standard ||
              // Object.keys(syllabusDropdowns).some(
              //   (dropdown) => !dropdownSelectData[dropdown]
              // ) ||
              !slides[2]?.content ||
              // !Object.keys(dropdoswnSelectData).includes("division")
              !selectedClassDivision
            }
            size="large"
            block
            onClick={() => {
              console.log("v3: before sessionid: ", teachingsessionid)
              console.log("v3: storage sessionid: ", localStorage.getItem("teacherteachingsessionid"))
              setTeachingSessionid(localStorage.getItem("teacherteachingsessionid") || "");
              console.log("v3: after sessionid: ", teachingsessionid)
              CommunicationService.post(APIStandards.TEACHER.LOG_TEACHING, {
                academicyear: "2024-2025",
                pedagogy: "CBSE",
                semester: "India School 1 Year Semester",
                selectedtopic: localStorage.getItem("selectedtopic"),
                selecteddivision: selectedClassDivision,
                mode: "start",
                teacherteachingsessionid: teachingsessionid,
              }).then((result: any) => {
                console.log("v3: classes modal: ",result);
                setTeachingSessionid(result.data.teacherteachingsessionid);
                localStorage.setItem("teacherteachingsessionid", result.data.teacherteachingsessionid);
              }).catch((err:any)=>{
                setTeachingSessionid("");
                console.error(err)}
              );
              SetPreviewModalOpen(true);
            }}
          >
            Click here to start Tutoring
          </Button>
        </Col>
        <Divider type="vertical" style={{ height: "40px", borderWidth: 3, borderColor: 'darkgrey' }}></Divider>
        <Col span={8}>
          <Button
            type="primary"
            disabled={
              !selectedClassDivision
            }
            size="large"
            block
            onClick={handleShowReviewCard}
          >
            Click here to start Reviewing
          </Button>
        </Col>
      </Row>
      {lessonCompletionStatus && (
        <Alert
          className="mt-3"
          message="Teaching completed"
          description="You have already completed teaching this lesson. You may revisit this lesson, but you maynot mark any progress on this."
          type="success"
          showIcon
        />
      )}
      {Object.keys(syllabusDropdowns).every(
        (dropdown) => dropdownSelectData[dropdown]
      ) &&
        slides.length <= 3 && (
          <Alert
            className="mt-4"
            message="No content has been added!"
            description="You have not added any content yet for this topic. Please add contents to start teaching this topic."
            type="warning"
            showIcon
          />
        )}
      <Divider></Divider>
      <div className="reviewApp">
        {isReviewCardLoading && (
          <Spin size="large" spinning={isReviewCardLoading} tip="Loading...">
            <div style={{ marginTop: 16 }} />
          </Spin>
        )}
        {isReviewCardVisible && (
          <ReviewCard
            isVisible={isReviewCardVisible}
            columns={reviewcolumns}
            data={reviewdata}
            onStartReview={handleStartReview}
            onDownloadResult={handleDownloadResult}
          />
        )}
      </div>
      
      <Modal
        title="Reveiw Results Data"
        open={isreviewResultsCsvModalVisible}
        // onOk={handlereviewResultsCsvModalOk}
        onCancel={handlereviewResultsCsvModalCancel}
        width={"95%"}
        // okButtonProps={{disabled: true}}
        footer={null}
      >
        <Table dataSource={reviewResultscsvData} columns={reviewresultsviewcolumns} />
      </Modal>

      <Modal
        open={previewModalOpen}
        width={"95%"}
        style={{ height: "95vh" }}
        forceRender={true}
        onCancel={() => {
          //setTimeout(() => {
          //   const k = {
          //     standard: [],
          //     subject: [],
          //   };

          //   setSlides([{}, {}]);
          //   setDropdownSelectData({
          //     syllabus: "CBSE",
          //   });
          //   setSyllabusDropDowns((data: any) => ({ ...k }));
          //   getSubjects();
          // }, 500);

          SetPreviewModalOpen(false);
          togglePause();
        }}
        footer={null}
        destroyOnClose={true}
      >
        {previewModalOpen && (<PreviewModal
          style={{ height: "90vh" }}
          lessons={slides}
          selectData={dropdownSelectData}
          selecteddivision={selectedClassDivision}
          selectedclass={selectedClassid}
        ></PreviewModal>
        )}
      </Modal>

      <Modal
        open={isClassDivisionModalOpen}
        title="Select Class Division"
        destroyOnClose={true}
        onCancel={() => setIsClassDivisionModalOpen(false)}
        footer={null}
      >
        <Select
          className="custom-select"
          placeholder="Select Class Division"
          defaultValue=""
          onSelect={handleClassDivisionSelect}
        >
          {classDivisions.map((item: any) => {
            return <Select.Option key={item}>{item}</Select.Option>;
          })}
        </Select>
      </Modal>

      {/* <Modal
        open={isReviewModalVisible}
        title="Review the Student's Quiz answers."
        destroyOnClose={true}
        onCancel={() => {setReviewModalVisible(false); setTeachingReviewSessionid("");}}
        footer={null}
      >
        {isReviewModalVisible && (<QuizReview onSave={handleReviewSave} data={quizData} />)}
      </Modal> */}


      <Modal
        width={"60vh"}
        open={isReviewModalVisible}
        closable={true}
        // title="Click to start reviewing!"
        title={<Title level={4} style={{textAlign:"center"}}>Click to start reviewing!</Title>}
        destroyOnClose={true}
        onCancel={() => { setReviewModalVisible(false); setTeachingReviewSessionid(""); }}
        footer={null}
      >

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            height: "30vh",
            // margin:"100px"
          }}
        >
          <Row style={{ margin: 10 }}>
            <Col span={24}>
              <Button
                type="primary"
              // onClick={() => onStartReview(record)}
              onClick={() => handleStartQuizReview()}
              disabled={!record.quizreviewRequired || isQuizReviewed}
              >
                Start Quiz Review
              </Button>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col span={24}>
              <Button
                type="primary"
                onClick={() => handleStartHandsOnReview()}
              disabled={!record.handsonreviewRequired|| isHandsOnReviewed}
              >
                Start Hands-On Review
              </Button>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col span={24}>
              <Button
                type="primary"
              onClick={() => handleStartChallengeReview()}
              disabled={!record.challengereviewRequired || isChallengeReviewed}
              >
                Start Challenge Review
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        width={"800px"}
        open={isQuizReviewModalVisible}
        // title="Review the Student's Quiz answers."
        title="Review the Learner's Questions answers."
        destroyOnClose={true}
        onCancel={() => {setQuizReviewModalVisible(false); setTeachingReviewSessionid("");}}
        footer={null}
      >

        {isQuizReviewModalVisible && (<QuizReview onSave={handleQuizReviewSave} data={quizData} />)}
        </Modal>

      <Modal
        width={"800px"}
        // height: "30vh",
        open={isHandsOnReviewModalVisible}
        // title="Review the Student's Quiz answers."
        title="Review the Learner's Hands-on Activity."
        destroyOnClose={true}
        onCancel={() => {setHandsOnReviewModalVisible(false); setTeachingReviewSessionid("");}}
        footer={null}
      >
        {isHandsOnReviewModalVisible && (<HandsOnReview onSave={handleHandsOnReviewSave} data={handsOnData} />)}
        </Modal>
      <Modal
        width={"800px"}
        // height: "30vh",
        open={isChallengeReviewModalVisible}
        // title="Review the Student's Quiz answers."
        title="Review the Learner's Challenge Activity"
        destroyOnClose={true}
        onCancel={() => {setChallengeReviewModalVisible(false); setTeachingReviewSessionid("");}}
        footer={null}
      >
        {isChallengeReviewModalVisible && (<ChallengeReview onSave={handleChallengeReviewSave} data={challengeData} />)}
        </Modal>
     </div> 
  );
}
