/* A React component that is used to create and manage questions and answers. */
import { PlusOutlined, SaveFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Radio,
  Row,
  Space,
  Switch,
  Typography,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../text_editor/text_editor";

type QuizData = {
  question_id: number;
  question: string;
  type: number;
  answers: string[];
  selectedAnswer: number;
  maxMarks: number;
}

export default function Quizz({ onSave, data }: any) {
  const [quizData, setQuizData] = useState<QuizData[]>([
    // {
    //   question: "",
    //   type: 0,
    //   answers: [""],
    // },
  ]);

  useEffect(() => {
    // Set form according to the existing data
    if (data) {
      setQuizData([...data]);
    } else {
      setQuizData([
        {
          question_id: 1,
          question: "",
          type: 0,
          answers: [""],
          selectedAnswer: -1,
          maxMarks: 0,
        },
      ]);
    }
  }, [data]);

  const addNewQuestion = () => {
    setQuizData((prevQuizData) => [
      ...prevQuizData,
      {
        question_id: prevQuizData.length + 1,
        question: "",
        type: 0,
        answers: [""],
        selectedAnswer: -1,
        maxMarks: 0,
      },
    ]);
  };

  const addNewOption = (idx: number) => {
    let tQuizData = quizData;
    tQuizData[idx].answers.push("");
    setQuizData([...tQuizData]);
    console.log({ quizData });
  };

  const setSelectedAnswer = (qstnIdx: number, answerIdx: number) => {
    let tQuizData = quizData;
    tQuizData[qstnIdx].selectedAnswer = answerIdx;
    setQuizData([...tQuizData]);
  };

  const changeQuestionType = (qstnIdx: number, type: any) => {
    console.log({ type });
    let tQuizData = quizData;
    if (type) tQuizData[qstnIdx].type = 1;
    else tQuizData[qstnIdx].type = 0;
    setQuizData([...tQuizData]);
  };

  const setQuestionData = (idx: number, question: any) => {
    let tQuizData = quizData;
    tQuizData[idx].question = question;
    setQuizData([...tQuizData]);
  };

  const setOptionData = (
    questionIdx: number,
    optionIdx: number,
    answer: any
  ) => {
    let tQuizData = quizData;
    tQuizData[questionIdx].answers[optionIdx] = answer;
    setQuizData([...tQuizData]);
  };

  const setMaxMarks = (qstnIdx: number, marks: any) => {
    let tQuizData = quizData;
    tQuizData[qstnIdx].maxMarks = marks;
    setQuizData([...tQuizData]);
  };

  return (
    <div>
      <Row>
        <Col>
          <Typography.Title level={5}>Question and Answers</Typography.Title>
          <Typography.Text disabled>
            Create and manage question and answers here!
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider></Divider>
        </Col>
      </Row>
      <>
        {quizData.map((question: any, idx: any) => {
          return (
            <Row>
              <Col span={24}>
                <Typography.Text>Question {idx + 1}</Typography.Text>
                <TextEditor
                  onChange={(val: any) => {
                    setQuestionData(idx, val);
                  }}
                  data={question.question}
                ></TextEditor>
              </Col>
              <Col span={24} className="mt-2">
                <Space>
                  <Typography.Text>Question Type:</Typography.Text>
                  <Switch
                    checkedChildren="Multichoice"
                    unCheckedChildren="Descriptive"
                    defaultChecked={question.type}
                    onChange={(val) => {
                      changeQuestionType(idx, val);
                    }}
                  />
                </Space>
                <Space>
                  <Typography.Text>Max Marks:</Typography.Text>
                  <InputNumber
                    min={1}
                    max={100}
                    value={question.maxMarks}
                    onChange={(val) => {
                      setMaxMarks(idx, val);
                    }}
                  />
                </Space>
                {question.type == 1 && (
                  <Radio.Group
                    onChange={(value) => {
                      setSelectedAnswer(idx, value.target.value);
                    }}
                    value={question.selectedAnswer}
                  >
                    <Space className="mt-3">
                      {question.answers.map((answer: any, answerIdx: any) => {
                        return (
                          <Space direction="vertical">
                            <Radio value={answerIdx}>
                              Option {answerIdx + 1}
                            </Radio>
                            <TextEditor
                              onChange={(val: string) => {
                                setOptionData(idx, answerIdx, val);
                              }}
                              data={answer}
                            />
                          </Space>
                        );
                      })}

                      <div style={{ maxWidth: "20%" }}>
                        <Button
                          type="dashed"
                          icon={<PlusOutlined />}
                          danger
                          size="large"
                          style={{ height: "100%" }}
                          onClick={() => {
                            addNewOption(idx);
                          }}
                        >
                          New Option
                        </Button>
                      </div>
                    </Space>
                  </Radio.Group>
                )}
              </Col>
              <Divider></Divider>
            </Row>
          );
        })}
      </>
      <Row>
        <Col span={24} className="text-center mt-3">
          <Button
            onClick={addNewQuestion}
            type="dashed"
            icon={<PlusOutlined />}
          >
            {" "}
            New Question
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Space
            align="end"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                onSave(quizData);
              }}
              icon={<SaveFilled />}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}
