import React, { useState, useEffect } from 'react';
import { Avatar, Col, Divider, Drawer, List, Row, Modal } from 'antd';
import { Input, Checkbox, Button, Form, Typography, message } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";

import logo from "src/assets/images/logo/stemclass-logo-blue.png";
import CommunicationService from "src/services/communication_service";
import EditProfile from "src/views/guest/edit_profile/edit_profile";
import APIStandards from "src/utils/API_standards";
import "./profile_page.css"



const { Title, Text } = Typography;



interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);


interface ProfilePageProps {
  viewProfileOpenFlag: boolean;
  sendViewProfileOpenFlag: Function;
}


const ProfilePage = ({ viewProfileOpenFlag, sendViewProfileOpenFlag }: ProfilePageProps) => {
  const [openProfilePage, setOpenProfilePage] = useState(viewProfileOpenFlag);


  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);

  // userObj with the same keys as in ProfileView's user_json
  var userObj = {
    username: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    status: "",
    email_address: "",
    profile_picture_base64: "",
    image_format_type: ""
  }

  const [userObjState, setUserObjState] = useState(userObj);

  useEffect(() => {
    if (viewProfileOpenFlag) {
      getUserDetailsForProfile();
    }
    setOpenProfilePage(viewProfileOpenFlag);
  }, [viewProfileOpenFlag]);



  const getUserDetailsForProfile = () => {


    const userDetailsObj = JSON.parse(
      localStorage.getItem("userDetails") || "{}"
    );

    CommunicationService.post(APIStandards.GUEST.PROFILE_VIEW, { "username": userDetailsObj['userdetails']["username"] })
      .then((res: any) => {
        setUserObjState(res.data)
        console.log(res.data)
      })
      .catch((err: any) => {
        console.log(err)
      });

  }

  // close drawer
  const onViewProfileClose = () => {
    sendViewProfileOpenFlag(false);

  };

  // show modal open
  const showEditProfileModal = () => {
    setIsEditProfileModalOpen(true)
  }

  // close EditProfile Modal
  const handleEditProfileCancel = (formOpenFlag: boolean) => {
    setIsEditProfileModalOpen(formOpenFlag);
    getUserDetailsForProfile();
  };


  return (
    <>
      <Drawer width={640} placement="right" closable={true} onClose={onViewProfileClose} open={openProfilePage}>
        <Row className="drawer-row">
          <Col span={12}>
            <p className="site-description-item-profile-p" style={{ marginBottom: 12, textDecoration: "underline" }}>
              User Profile
            </p>
          </Col>
          <Col span={12}>
            <p className="site-description-item-profile-p" style={{ marginBottom: 12, textDecoration: "underline" }}>
              <a onClick={showEditProfileModal}> Edit Profile </a>
            </p>
          </Col>
        </Row>
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Col span={12} >
            {/* hardcoded */}
            {/* <Avatar size={128} shape={"square"} icon={<UserOutlined />} /> */}

            {
              // if the profile picture is available 
              userObjState.profile_picture_base64.length != 0
              &&
              (
                <Avatar size={128} shape={"square"} style={{ marginBottom: 10 }} src={`data:image/${userObjState.image_format_type};base64,${userObjState.profile_picture_base64}`} />
              )
            }

            {
              // if the profile picture is not avaiable 
              userObjState.profile_picture_base64.length == 0
              &&
              (
                <Avatar size={128} shape='square' icon={<UserOutlined />} />
              )
            }
            {/* <Avatar size={128} shape={"square"} style={{ marginBottom: 10 }} src= { 'data:image/jpeg;base64,'+ userObjState.profile_picture_base64}/> */}

          </Col>
        </Row>

        <p className="site-description-item-profile-p" >Personal</p>
        <Row className="drawer-row">
          <Col span={12}>
            <DescriptionItem title="First Name" content={userObjState.first_name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Username" content={userObjState.username} />
          </Col>
        </Row>
        <Row className="drawer-row">
          <Col span={12}>
            <DescriptionItem title="Middle Name" content={userObjState.middle_name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Gender" content={userObjState.gender} />
          </Col>
        </Row>
        <Row className="drawer-row">
          <Col span={12}>
            <DescriptionItem title="Last Name" content={userObjState.last_name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Status" content={userObjState.status} />
          </Col>
        </Row>
        <Divider />
        <p className="site-description-item-profile-p">Contacts</p>
        <Row className="drawer-row">
          <Col span={12}>
            <DescriptionItem title="Email Id" content={userObjState.email_address} />
          </Col>
          <Col span={12}>
            {/* <DescriptionItem title="Phone Number" content="+XX XXX XXXX XXXX" /> */}
          </Col>
        </Row>
      </Drawer>

      <EditProfile formOpenFlag={isEditProfileModalOpen} sendFormOpenFlag={handleEditProfileCancel} />


    </>
  );
};

export default ProfilePage;