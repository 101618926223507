import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import AzureVideoPlayer from "./azure_video_player";

export default function YoutubeViewer({ content, syllabus }: any) {
  const contentData = JSON.parse(content);
  console.log("contentData.url: ",contentData.url)
  const _onReady = (event: YouTubePlayer) => {
    globalThis.videoElement = event;
  };

  return (
    <div style={{ height: "100%" }}>
      {syllabus === 'CBSE' ? (
        // CBSE Syllabus - YouTube Player
        <>
          {Object.keys(contentData).length > 0 && (
            <YouTube
              style={{ height: "100%" }}
              videoId={contentData.url}
              onReady={_onReady}
              opts={{
                height: "100%",
                width: "100%",
                playerVars: {
                  autoplay: 0,
                  controls: 1,
                  showinfo: 0,
                  rel: 0,
                  start: contentData.trimRange.start,
                  end: contentData.trimRange.end,
                  modestbranding: 0,
                  iv_load_policy: 3,
                },
              }}
            ></YouTube>
          )}
        </>
      ) : (
        // Non-CBSE Syllabus - Azure Video Player
        <AzureVideoPlayer videoId={contentData["url"]}/>
      )}
    </div>
  );
}