import {
  BookFilled,
  HomeTwoTone,
  UploadOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import Layout, { Content } from "antd/es/layout/layout";
import React from "react";
import { HeaderC, Sidebar } from "src/components";
import Drawer_ from "src/components/common/layout/drawer/drawer";
import "./school_master.css";
import { GiTeacher } from "react-icons/gi";
import { SiGoogleclassroom } from "react-icons/si";
import { MdSpaceDashboard } from "react-icons/md";
import { Outlet } from "react-router-dom";

const SchoolMaster: React.FC = () => {
  const menu: any = [
    {
      key: "/u/school/home",
      icon: <MdSpaceDashboard />,
      label: "Home",
    },
    {
      key: "/u/school/teachers",
      icon: <GiTeacher />,
      label: "Teachers",
    },
    {
      key: "/u/school/subjects",
      icon: <BookFilled />,
      label: "Subjects",
    },
    {
      key: "/u/school/classes",
      icon: <SiGoogleclassroom />,
      label: "Class Teacher",
    },
  ];
  return (
    <Layout>
      <div className="main-sidebar">
        <Sidebar menuItem={menu}></Sidebar>
      </div>
      <Layout className="site-layout">
        <HeaderC></HeaderC>
        <Content
          style={{
            margin: "0px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Drawer_ menuItem={menu}></Drawer_>
    </Layout>
  );
};

export default SchoolMaster;
