import React from 'react';
import { LoginForm } from 'src/components/index';
import { Layout } from 'antd';
import logo from 'src/assets/images/logo/stemclass-logo-blue.png';
import bgimage from 'src/assets/images/logo/steamcube-bg-v0.4.png';
import './login.css';

const { Content } = Layout;

const Login: React.FC = () => {
  return (
    <Layout className="login-container">
      <Content className="login-content">
        <div className="login-form-container">
          <img src={logo} alt="Logo" className="loginlogo" />
          <LoginForm />
        </div>
      </Content>
    </Layout>
  );
};

export default Login;