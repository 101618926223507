import { useState, useRef } from "react";
import { TextField, Button, Box, IconButton, CircularProgress, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import APIStandards from "src/utils/API_standards";
import CommunicationService from "src/services/communication_service";
import { sanitize } from "dompurify"

const AIChatBot = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<{ text: string; sender: string }[]>([]);
  const [textFieldDisabled, setTextFieldDisabled] = useState(false);
  const [aichatloading, setaichatLoading] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleSendMessage = async () => {
    // Set loading status to true
    setaichatLoading(true);
    // Set loading status in the TextField
    setMessage('Loading...');

    // Set the TextField to disabled to prevent multiple submissions
    setTextFieldDisabled(true);
    setMessages([...messages, { text: message, sender: 'user' }]);

    // Send the message to the API
    try {
      let chatresponseData='';
      const response = await CommunicationService.post(APIStandards.PUBLIC.AI_CHAT_BOT, {
        message: message,
      }).then((response: any) => {
        chatresponseData = response.data.response.content;
      });

      // Reset the loading status and show the TextField
      setMessage('');
      setaichatLoading(false);
      setTextFieldDisabled(false);

      // Update the messages state with the response
      setMessages([...messages, { text: message, sender: 'user' }, { text: chatresponseData, sender: 'assistant' }]);
      textFieldRef.current?.focus();
    } catch (error) {
      console.error(error);
      // Handle error scenario, e.g., show an error message
      setMessage('Error sending message. Please try again.');
      setaichatLoading(false);
      setTextFieldDisabled(false);
    }
    setMessage('');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FFFBEF',
        padding: 2,
        borderRadius: 2,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        height: '100%', // Set the height of the container to 100%
        display: 'flex', // Use flexbox to layout the components
        flexDirection: 'column', // Stack the components vertically
      }}
    >
      <Box sx={{ flexGrow: 1, overflowY: 'auto', }}>
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
              marginBottom: 2,
              borderRadius: 2
            }}
          >
            <Box
              sx={{
                backgroundColor: message.sender === 'user' ? '#e3faed' : '#e4f2f9',
                padding: 1,
                borderRadius: 2,
                maxWidth: '80%',
              }}
            >
              <Typography
                sx={{
                  backgroundColor: message.sender === 'user'? '#e3faed' : '#e4f2f9',
                  padding: 1,
                  borderRadius: 2,
                  maxWidth: '80%',
                }}
                component="div"
                dangerouslySetInnerHTML={{ __html: sanitize(message.text) }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          inputRef={textFieldRef}
          autoFocus={true}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.key === "enter") {
              handleSendMessage();
            }
          }}
          placeholder="Type a message..."
          disabled={textFieldDisabled}
          sx={{
            flex: 1,
            marginRight: 2,
            borderRadius: 16, // Add a rounded edge with a radius of 16px
            '& .MuiInputBase-input': {
              borderRadius: 16, // Also apply the rounded edge to the input field
            },
          }}
        />
        {aichatloading ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton onClick={handleSendMessage} sx={{ color: '#4B144B' }}>
            <SendIcon htmlColor="#4B144B" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default AIChatBot;