import { PlusCircleFilled } from "@ant-design/icons";
import { CChart } from "@coreui/react-chartjs";
import {
  Row,
  Col,
  Button,
  Modal,
  Timeline,
  Card,
  Divider,
  Result,
  Space,
  Alert,
  Badge,
  Tooltip,
  Popover,
} from "antd";
import React, { Children, useEffect, useRef, useState } from "react";
import { FcLock } from "react-icons/fc";
import ContentCreationMainModal from "src/components/teacher/content_creation/content_creation_main_model";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import ContentStandards from "src/utils/content_standards";
import { useNavigate } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import CurriculamAggregatedReport from "src/components/teacher/curriculum_aggregated_report/curriculam_aggregated_report";
import CourseContentCreationMainModal from "src/components/teacher/content_creation/course_content_creation_main_modal";
import CurriculumAggregateStudentReport from "src/components/teacher/curriculum_aggregate_student_report/curriculum_aggregate_student_report";
import PowerBIReport from 'src/components/common/powerbi/reports/powerbireport';
import axios from 'axios';
type ButtonType = "first" | "second" | "third" | "fourth" | "fifth" | null;

export default function PrincipalHome() {
  const deployment_type = process.env.REACT_APP_deployment_type || "dev";
  const navigate = useNavigate();

  const [contentCreationModal, setContentCreationModal] = useState(false);
  const [courseContentCreationModal, setCourseContentCreationModal] =
    useState(false);
  const [dayVsCountGraphdata, setDayVsCountGraphdata] = useState<any>({});
  const [timeLineData, setTimeLineData] = useState<any>([]);
  const [additionalModalVisible, setAdditionalModalVisible] = useState(false);
  const [contentDistributionData, setContentDistributionData] = useState<any>(
    {}
  );
  const [mainModalVisible, setMainModalVisible] = useState(false);
  const [selectedButton, setSelectedButton] = useState<ButtonType | null>(null);
  const [reportConfig, setReportConfig] = useState({
    reportId: '',
    embedUrl: '',
    accessToken: ''
  });
  const [powerbiloading, setLoading] = useState(true);

  useEffect(() => {
    // Call the function to fetch report details
    fetchReportConfig();
  }, []);

  const fetchReportConfig = async () => {
    try {
      let reportName = deployment_type.toString() === 'prod' ? "Principal report (view only)" : "Principal report-dev2-DB";
      const response = await axios.get('https://steamcubepowerbiembed.azurewebsites.net/api/httppowerbiembed',{
        params: {
          code: 'rb-5uyB5zQDSzGZkGDzI7jQxIUe0PNUwDIPVS3G0aHBwAzFuhglfkg==',
          workspaceId: '6d4ca98c-b755-4326-8543-0a77cf9bd330',
          // reportName: "steamcube_developers_teacher's_dashboard - Copy"
          reportName: reportName
        }
      });
      console.log("fetchReportConfig -> Azure Function app complete")

      // Assuming the returned JSON structure includes `reportId`, `embedUrl`, `accessToken`
      const data = response.data;
      // const modified_embedUrl = `${data.embedUrl}&filter=steamcube vw_teacher_school_credentials/Teacher Name eq 'Steamcourse Teacher 1'`;
      const modified_embedUrl = `${data.embedUrl}&filter=steamcube_x0020_vw_student_password_change/Email_x0020_Address in ('nambirajesh25@gmail.com','hod.science@iswkoman.com','hod.mathematics@iswkoman.com','anujose224@gmail.com','sameeraf@iswkoman.com','coordinator.vinitha@iswkoman.com','coordinator.jyoti@iswkoman.com','samreen.syed@iswkoman.com','nikkhath.thouseef@gmail.com','minih@iswkoman.com','kumari.poonam@iswki.org','arunima.nair@iswkoman.com','avpsecondary.science@iswkoman.com','joji@iswkoman.com','rajendran.pavithra@iswki.org')`;
      setReportConfig({
        reportId: data.reportId,
        embedUrl: modified_embedUrl,
        accessToken: data.embedToken
      });

      console.log("fetchReportConfig -> setconfig complete");
      console.log("fetchReportConfig -> setconfig", data.embedUrl);
      console.log("fetchReportConfig -> setconfig", modified_embedUrl);
      console.log("fetchReportConfig -> setconfig", data.reportId);
      console.log("fetchReportConfig -> setconfig", data.embedToken);

      setLoading(false); // Set loading to false as fetch is complete
    } catch (error) {
      console.error("Error fetching Power BI config", error);
      setLoading(false);
    }
  };
  if (powerbiloading) {
    console.log("fetchReportConfig -> powerbiloading is true")
    return <div>Loading report...</div>;
  }
  return (
    <div>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Card size="small" title="Principal Daily Dashboard Report">
            <PowerBIReport
              reportId={reportConfig.reportId}
              embedUrl={reportConfig.embedUrl}
              accessToken={reportConfig.accessToken}
              width="100%" // You can adjust this as needed
              height="850px" // You can adjust this as needed
            />
          </Card>
        </Col>
      </Row>
     </div>
  );
}
