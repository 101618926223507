import { SaveFilled } from "@ant-design/icons";
import { Row, Col, Divider, Typography, Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import TextEditor from "../text_editor/text_editor";

/**
 * A custom activity that allows the user to create a custom activity.
 * @param {string} activityName - The name of the activity.
 * @param {string} activityDescription - The description of the activity.
 * @param {string[]} steps - The steps of the activity.
 * @param {string} existingData - The existing data of the activity.
 * @returns None
 */
export default function CustomActivity({
  activityName,
  activityDescription,
  steps,
  existingData,
  onSave,
}: any) {
  const [activityData, setActivityData] = useState<any>([...existingData]);

  return (
    <div>
      <Row>
        <Col span={24}>
          <Title level={4}>Create {activityName}</Title>
        </Col>
        <Col span={24}>
          <Typography.Text disabled>{activityDescription}</Typography.Text>
        </Col>
      </Row>
      <Divider></Divider>
      {steps.map((step: string, idx: number) => {
        return (
          <Row key={"row" + idx}>
            <Col span={24}>
              <Title level={5}>
                {idx + 1}. {step}
              </Title>
            </Col>
            <TextEditor
              key={idx}
              onChange={(data: any) => {
                let tActivity = activityData;
                tActivity[idx] = data;
                setActivityData([...tActivity]);
              }}
              data={existingData[idx]}
            ></TextEditor>
            <Divider></Divider>
          </Row>
        );
      })}
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            onClick={() => {
              console.log({ activityData });
              onSave(activityData);
            }}
            icon={<SaveFilled />}
            className="float-right"
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}
