import { Input, Checkbox, Button, Form, Typography, message, Row, Col } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import logo from "src/assets/images/logo/stemclass-logo-blue.png";
import { useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import { reset_object } from "src/utils/helpers";
import "./change_password.css";

const { Title, Text } = Typography;

const ChangePassword: React.FC = () => {

  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    });
  const [form] = Form.useForm();

  const changePassword = () => {
    // if no localstorage is set, in other words, not logged  in, redirect to login page
    (localStorage.length === 0) && (window.location.href = "/login");

    const token = localStorage.getItem("token");
    const userDetailsString =localStorage.getItem("userDetails");
    
    const userDetailsObj = JSON.parse(
      localStorage.getItem("userDetails") || "{}"
    );

    if (passwordInfo.newPassword !== passwordInfo.confirmPassword) {
          message.error('New password and confirm password do not match');
        }
    else if(passwordInfo.currentPassword == passwordInfo.newPassword) {
          message.error('New password should not be the same as current one!')
        }
    else{
    CommunicationService.post(APIStandards.GUEST.CHANGE_USER_PASSWORD, { 
      "username":userDetailsObj['userdetails']["username"],
      "currentpassword": passwordInfo.currentPassword,
      "newpassword": passwordInfo.newPassword
      })
      .then(() => {
        message.success(
          "You can now use the new password to login to your account"
        );
       
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/"; 
      },3000);
      
      })
      .catch((err: any) => {
      //  TO display: 'Enter the current password correctly!'
        message.error(err.response.data.error);
      })
    .catch((err: any) => {
      message.error("Invalid username or password, please try again!");
    });
  }
  };

  return (
    
      <div className="container">
      <div className="login-panel" style={{ width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <img src={logo} style={{ height: "110px" }} />
          {/* <Title style={{ color: "#072eef" }}>StemClass</Title> */}
        </div>
        
      <div>
      <Row>
      <Col>

      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={4}>Please create a new password to continue!</Title>
        <Text style={{ fontSize: 16 }} disabled>
          Enter your Current and New password <br /> to update your password.
        </Text>
      </div>
         <Form
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={() => {}}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
        
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[{ message: "Please enter your current password!" }]}
          >
            <Input.Password
              placeholder="Current Password"
              size="large"
              prefix={<KeyOutlined />}
              onChange={(e) => {
                setPasswordInfo({ ...passwordInfo, currentPassword: e.target.value });
              }}
            />
          </Form.Item>


          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
                { required: true, message: 'Please enter your new password' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || value.length > 6 ) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Password should contain at least 6 characters');
                  },
                }),
            ]}
          >
            <Input.Password
              placeholder="New Password"
              size="large"
              prefix={<KeyOutlined />}
              onChange={(e) => {
                setPasswordInfo({ ...passwordInfo, newPassword: e.target.value });
              }}
            />
          </Form.Item>
      
           <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              dependencies={['newPassword']}
              rules={[
                { required: true, message: 'Please confirm your new password' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value ) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
            ]}
            >
             <Input.Password
              placeholder="Confirm New Password"
              size="large"
              prefix={<KeyOutlined />}
              onChange={(e) => {
                setPasswordInfo({ ...passwordInfo, confirmPassword: e.target.value });
              }}
            />
      </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              icon={<LoginOutlined />}
              onClick={changePassword}
            >
              Change Password
            </Button>
          </Form.Item> 
        </Form> 
            </Col>
          </Row>
        </div>
      </div>
      </div>
      
  );
};

export default ChangePassword;
