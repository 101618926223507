import { Switch, Button, Card, Col, Row, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react"; // Fix the import of useState
import TextEditor from "../content_creation/text_editor/text_editor";
import "./viewer_styles.css";
import { SaveFilled, QuestionCircleTwoTone } from "@ant-design/icons";
import communication_service from "src/services/communication_service";
import API_standards from "src/utils/API_standards";
import content_standards from "src/utils/content_standards";
import user_types from "src/utils/user_types";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { CiSquareQuestion } from "react-icons/ci";

export default function QuizViewer(content: any) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails") || "{}").type
  );
  const [quizContent, setQuizContent] = useState(
    JSON.parse(content["content"]["content"])
  );
  console.log("v3: quiz viewer content: ", content);
  const [selectedOptions, setSelectedOptions] = useState<any>({});

  useEffect(() => {
    let selectedOptionsObject: any = {};
    for (let i = 0; i < quizContent.length; i++) {
      const { question_id, selectedAnswer } = quizContent[i];
      selectedOptionsObject[i] = selectedAnswer;
      };
    setSelectedOptions(selectedOptionsObject);
    console.log("v3: quiz viewer: selectedOptions: ", selectedOptions);
  }, [quizContent]);

  const handleCardClick = (question_idx: number, answer_idx: any) => {
    const newContent = [...quizContent];
    console.log("v3: quiz viewer: newContent before: ", newContent);
    console.log("v3: quiz viewer: question_idx: ", question_idx);
    console.log("v3: quiz viewer: answer_idx: ", answer_idx);
    newContent[question_idx].selectedAnswer = answer_idx;
    console.log("v3: quiz viewer: newContent after: ", newContent);
    setQuizContent(newContent);
    console.log("v3: quiz viewer: selectedOptions before: ", selectedOptions);
    setSelectedOptions((prevState: any) => ({
      ...prevState,
      [question_idx]: answer_idx,
    }));
    console.log("v3: quiz viewer: selectedOptions after: ", selectedOptions);
  };

  const handleTextChange = (question_idx: number, text: string) => {
    const newContent = [...quizContent];
    newContent[question_idx].answers[0] = text;
    setQuizContent(newContent);
    // setSelectedOptions((prevState: any) => ({
    //   ...prevState,
    //   [question_idx]: text,
    // }));
  };

  const handleExamSubmitClick = () => {
    console.log("v3: submit answer content: ", JSON.parse(content["content"]["content"]))
    console.log("v3: submit answer content: ", content["content"]["_id"])
    communication_service
      .post(API_standards.STUDENT.ADD_CONTENT, {
        content_type: content_standards.QUIZ,
        reference_id: content["content"]["_id"],
        selectedtopic: content["content"]["lesson_id"],
        content: JSON.stringify(quizContent),
      })
      .then((res: any) => {
        message.success("Your answers have been successfully added!");
      })
      .catch((err: any) => {
        message.error("Failed to save your answers, please try again later!");
      });
  };

  return (
    <div>
      <Row>
        {JSON.parse(content["content"]["content"]).map(
          (question: any, idx: number) => (
            <Col span={24} className="mt-2" key={idx}>
              <Card title={`Question ${idx + 1}`}>
                <Space direction="vertical">
                  <Space direction="horizontal">
                  <CiSquareQuestion size={30} color="DodgerBlue" />
                    <div
                      dangerouslySetInnerHTML={{ __html: question.question.replace(
                        'font-size:15px;',
                        'font-size:18px;'
                      ) }}
                    />
                  </Space>
                  {question.type === 1 && (
                    <Space direction="vertical">
                      <Typography.Text disabled>
                        Please select an option from below.
                      </Typography.Text>
                      <Space direction="horizontal">
                        <MdOutlineQuestionAnswer size={30} color="DodgerBlue" />
                        {question.answers.map(
                          (answer: any, ans_idx: number) => (
                            <Space
                              direction="vertical"
                              size={"large"}
                              key={ans_idx}
                            >
                              <Card
                                className={`options-card ${
                                  selectedOptions[idx] === ans_idx
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() => handleCardClick(idx, ans_idx)}
                              >
                                <div
                                  dangerouslySetInnerHTML={{ __html: answer }}
                                />
                              </Card>
                            </Space>
                          )
                        )}
                      </Space>
                    </Space>
                  )}
                  {question.type === 0 && (<Space direction="horizontal">
                                          <MdOutlineQuestionAnswer size={30} color="DodgerBlue" />
                                          <TextEditor
                        onChange={(info: any) => {
                          handleTextChange(idx, info);
                        }}
                        data={question.answers[0]}
                                              />
                                      </Space>)}
                </Space>
              </Card>
            </Col>
          )
        )}
      </Row>
      <Row className="mt-3">
        <Col span={24} className="text-center">
          <Button
            type="primary"
            icon={<SaveFilled />}
            size="large"
            onClick={handleExamSubmitClick}
            disabled={currentUser !== user_types.Student} //disable button if not student
          >
            Submit Answers!
          </Button>
        </Col>
      </Row>
    </div>
  );
}
