import { App, Button, Form, Input, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  ArrowRightOutlined,
  MailOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import { validate_email } from "src/utils/validations";
import MessageStandards from "src/utils/message_standards";
import { reset_object } from "src/utils/helpers";

const { Title, Text } = Typography;

const SchoolRegistrationForm: React.FC = () => {
  const { message, modal, notification } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const [schoolRegistrationForm, setSchoolRegistrationForm] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });

  const [schoolRegistrationValidation, setSchoolRegistrationValidation] =
    useState({
      name: "",
      email: "",
      address: "",
    });

  const registerSchool = () => {
    if (validateForm()) {
      setIsLoading(true);

      //trim the values

      CommunicationService.post(APIStandards.GUEST.SCHOOL_ACCOUNT_CREATION, {
        name: schoolRegistrationForm.name.trim(),
        email: schoolRegistrationForm.email.trim(),
        address: schoolRegistrationForm.address.trim(),
      })
        .then((data: any) => {
          const reset_data: any = reset_object(schoolRegistrationForm);
          setSchoolRegistrationForm(reset_data);
          message.success(MessageStandards.SCHOOL_REGISTRATION_SUCCESS, 6);
        })

        .catch((err: any) => {
          if (err.response) message.error(err.response.data.detail);
          else
            message.error(
              "Oops! Something unexpected happend, please try again."
            );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
    }
  };

  const validateForm = () => {
    let isValid = true;
    let validation_object = {
      name: "",
      email: "",
      address: "",
    };

    // name validation
    if (schoolRegistrationForm.name.length < 6) {
      validation_object = {
        ...validation_object,
        name: "Name should contain more than 6 characters.",
      };
    }

    // email validation
    if (!validate_email(schoolRegistrationForm.email)) {
      validation_object = {
        ...validation_object,
        email: "Please enter a valid email",
      };
    }

    //phone validation TODO: Improve
    // if (schoolRegistrationForm.phone.toString().length !== 10) {
    //   validation_object = {
    //     ...validation_object,
    //     phone: "Please enter a valid phone number",
    //   };
    // }

    // address validaiton TODO: Improve
    if (schoolRegistrationForm.address.toString().length < 10) {
      validation_object = {
        ...validation_object,
        address: "Please enter a valid address",
      };
    }

    for (const [k, v] of Object.entries(validation_object)) {
      if (v) isValid = false;
    }

    setSchoolRegistrationValidation({
      ...schoolRegistrationValidation,
      ...validation_object,
    });

    return isValid;
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={3}>Hi, Welcome!</Title>
        <Text disabled>Please enter the following details to get started.</Text>
      </div>
      <div>
        <Form
          layout="vertical"
          name="school_registration"
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <Form.Item
            label="School Name"
            required={true}
            validateStatus={
              schoolRegistrationValidation.name ? "error" : "success"
            }
            help={schoolRegistrationValidation.name}
          >
            <Input
              prefix={<IdcardOutlined />}
              placeholder="Please enter school name"
              value={schoolRegistrationForm.name}
              onChange={(ele: any) => {
                setSchoolRegistrationForm({
                  ...schoolRegistrationForm,
                  name: ele.target.value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Email Id"
            required
            validateStatus={
              schoolRegistrationValidation.email ? "error" : "success"
            }
            help={schoolRegistrationValidation.email}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Please enter email id"
              value={schoolRegistrationForm.email}
              onChange={(ele: any) => {
                setSchoolRegistrationForm({
                  ...schoolRegistrationForm,
                  email: ele.target.value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="School Address"
            required
            validateStatus={
              schoolRegistrationValidation.address ? "error" : "success"
            }
            help={schoolRegistrationValidation.address}
          >
            <TextArea
              rows={6}
              value={schoolRegistrationForm.address}
              placeholder="Please enter school address"
              onChange={(ele: any) => {
                setSchoolRegistrationForm({
                  ...schoolRegistrationForm,
                  address: ele.target.value,
                });
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              size="large"
              icon={<ArrowRightOutlined />}
              type="primary"
              block
              onClick={registerSchool}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SchoolRegistrationForm;
