export default {
  CHAPTER: 0,
  TOPIC: 1,
  LEARNING_OUTCOME: 2,
  LEARNING_OBJECTIVE: 3,
  QUIZ: 4,
  VIDEO: 5,
  PDF: 6,
  PPT: 7,
  STANDARD: 8,
  SUBJECT: 9,
  STRAND: 10,
  HANDS_ON: 11,
  COMPETENCY: 12,
  CHALLENGE: 13,
};
