import { Input, Checkbox, Button, Form, Typography, message } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined } from "@ant-design/icons";
import { useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import { reset_object } from "src/utils/helpers";

const { Title, Text } = Typography;

const LoginForm: React.FC = () => {
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [form] = Form.useForm();

  const login = () => {
    CommunicationService.post(APIStandards.GUEST.LOGIN, {
      username: loginInfo.email,
      password: loginInfo.password,
    })
      .then((data: any) => {
        let token = data.data.token;
        let refresh = data.data.refresh;
        localStorage.setItem("token", token);
        localStorage.setItem("refreshtoken", refresh);
        form.resetFields();
        window.location.href = "/";
      })
      .catch((err: any) => {
        message.error("Invalid username or password, please try again!");
      });
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={2}>Sign In</Title>
        <Text style={{ fontSize: 16 }} disabled>
          Please enter your username and password <br /> to sign in.
        </Text>
      </div>
      <div>
        <Form
          name="basic"
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={() => {}}
          onFinishFailed={() => {}}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[{ message: "Please enter your email!" }]}
          >
            <Input
              placeholder="Email"
              prefix={<UserOutlined />}
              size="large"
              onChange={(e) => {
                setLoginInfo({ ...loginInfo, email: e.target.value });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ message: "Please enter your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              size="large"
              prefix={<KeyOutlined />}
              onChange={(e) => {
                setLoginInfo({ ...loginInfo, password: e.target.value });
              }}
            />
          </Form.Item>

          {/* <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              icon={<LoginOutlined />}
              onClick={login}
            >
              Login
            </Button>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <a href="/forgot_password">Forgot Password?</a>
          </Form.Item> 

        </Form>
      </div>
    </>
  );
};

export default LoginForm;
