import { MoreOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Popover, Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import { IoMdMore } from "react-icons/io";
import { AddTeacherModal } from "src/components";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";

export default function Teachers() {
  const [modal, openModal] = useState(false);
  const [teacherDetails, setTeacherDetails] = useState([]);

  useEffect(() => {
    CommunicationService.post(APIStandards.SCHOOL.GET_TEACHER_LIST, {}).then(
      (data: any) => {
        setTeacherDetails(data.data);
      }
    );
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "user_details",
      key: "user_details",
      render: (item: any) => {
        if (item.length > 0) return item[0]["name"];
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "user_details",
      key: "user_details",
      render: (item: any) => {
        if (item.length > 0) {
          return "Active";
        } else {
          return "Yet to Join";
        }
      },
    },
  ];
  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Button
        type="text"
        icon={<AiOutlineUserAdd />}
        onClick={() => {
          openModal(true);
        }}
      >
        &nbsp; Add Teacher
      </Button>
      <Button disabled type="text" icon={<BiImport />}>
        &nbsp; Import Teachers
      </Button>
    </div>
  );

  return (
    <Card
      title="Teachers"
      bordered={false}
      style={{ width: "100%" }}
      extra={
        <Popover placement="bottom" content={content} title="Manage Teachers">
          <Button type="primary" icon={<MoreOutlined />}>
            {" "}
            More
          </Button>
        </Popover>
      }
    >
      <Table dataSource={teacherDetails} columns={columns} />

      <Modal
        open={modal}
        footer={null}
        title="Invite Teacher(s)"
        onCancel={() => {
          openModal(false);
        }}
      >
        <AddTeacherModal></AddTeacherModal>
      </Modal>
    </Card>
  );
}
