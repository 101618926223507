import React, { useEffect, useState } from 'react';
import "./videoplayer.css";

export default function AzureVideoPlayer({videoId} : any)  {
  console.log("contentDatavideo: ", typeof(videoId))
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
      async function fetchVideoUrl() {
        const containerName = 'steamcubeclass-courses-videos';
        const blobName = videoId;
        const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rltf&se=2024-12-31T15:16:51Z&st=2024-04-30T07:16:51Z&spr=https&sig=6uJJG%2B5Rp4Ke2qJ9acQrJnjVFxwjYy9at%2FiI499DkOE%3D';
  
        // const url = `https://steamcubecourses.blob.core.windows.net/${containerName}/${blobName}?${sasToken}`;
        const url = `https://steamcubeazblobcdn1.azureedge.net/${containerName}/${blobName}?${sasToken}`;
        setVideoUrl(url);
      }
  
      fetchVideoUrl();
    }, []);

    const handleContextMenu = (event: any) => {
      event.preventDefault();
    }
  
    return (
      <><div style={{ width: "100%", height: "98%" }}>
        {videoUrl && (
          <video ref={globalThis.videoRef} controls autoPlay controlsList="nodownload" onContextMenu={handleContextMenu}>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div></>
    );
  };


