import { Button, Col, Form, message, Row, Select } from "antd";
import Typography from "antd/es/typography";
import React, { useEffect, useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
const { Option } = Select;

export default function AssignSubject({ onSave }: any) {
  const [teachers, setSteachers] = useState([]);
  const [mappingForm, setMappingForm] = useState({
    standard: "",
    division: "",
    subject: "",
    teacherId: "",
  });
  const getTeacherList = () => {
    CommunicationService.post(APIStandards.SCHOOL.GET_TEACHER_LIST, {})
      .then((data: any) => {
        setSteachers(data.data);
      })
      .catch((ex: any) => {
        message.error("Failed to load teachers list, Please try again!");
      });
  };

  useEffect(() => {
    getTeacherList();
  }, []);

  const classes = [
    "Pre-KG",
    "LKG",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const grades = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
  ];

  const subjects = [
    "English",
    "Maths",
    "Physics",
    "Chemistry",
    "Biology",
    "Geography",
    "History",
    "Economics",
    "Politics",
    "Hindi",
    "Malayalam",
    "EVS",
  ];

  return (
    <div>
      <Row>
        <Typography.Title level={5}>
          Please select a Class, Subject and Teacher to Assign
        </Typography.Title>
        <Col span={24}>
          <Form>
            <Form.Item name="Standard" label="Standard">
              <Select
                onSelect={(item: any) => {
                  setMappingForm({ ...mappingForm, standard: item });
                }}
              >
                {classes.map((item: string) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name="Division" label="Division">
              <Select
                onSelect={(item: any) => {
                  setMappingForm({ ...mappingForm, division: item });
                }}
              >
                {grades.map((item: string) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name="Subject" label="Subject">
              <Select
                onSelect={(item: any) => {
                  setMappingForm({ ...mappingForm, subject: item });
                }}
              >
                {subjects.map((item: string) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name="teacher" label="Teacher">
              <Select
                onChange={(teacher_id) => {
                  setMappingForm({ ...mappingForm, teacherId: teacher_id });
                }}
              >
                {teachers.map((item: any) => {
                  return (
                    <Option value={item._id}>
                      {item?.user_details[0]?.name} {"<" + item?.email + ">"}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            onClick={() => {
              onSave(mappingForm);
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}
