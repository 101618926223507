import { DeleteFilled, EyeFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  Tooltip,
} from "antd";
import { FaFilePdf, FaFilePowerpoint, FaYoutube } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import VideoCreation from "src/components/common/content_creation/video/video_creation/video_creation";
import FileUpload from "src/components/common/content_creation/file_upload/file_upload";
import ContentManagementServices from "src/services/content_management_services";
import { get_content_title } from "src/utils/helpers";
import ContentStandards from "src/utils/content_standards";
import YoutubeViewer from "src/components/common/content_viewer/youtube_viewer";
import GoogleDocsViewer from "src/components/common/content_viewer/google_docs_viewer";
import APIStandards from "src/utils/API_standards";
import CustomActivity from "src/components/common/content_creation/custom_activity/custom_activity";
import Quizz from "src/components/common/content_creation/quizz/quizz";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { AiFillExperiment } from "react-icons/ai";
import { GiThink } from "react-icons/gi";
import { Title } from "chart.js";
import AzureVideoPlayer from "src/components/common/content_viewer/azure_video_player";
import YouTube, { YouTubePlayer } from "react-youtube";
import { IconButton, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Chat, Close  } from '@mui/icons-material';
import { Modal as MUIModal } from "@mui/material"
import AIChatBot from "src/components/common/aichatbot/ai_chat_bot";

let videoElement: YouTubePlayer = null;


export default function ContentAdditionModel() {
  const [selectedOption, setSelectedOption] = useState(0);
  const [addedContents, setAddedContents] = useState<any>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewData, setPreviewData] = useState<any>("");
  const [competencies, setCompetencies] = useState<any>([]);
  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [quizData, setQuizData] = useState<any>([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [quizId, setQuizId] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [visible, setVisible] = useState(false);
  const [handsonSteps, setHandsOnSteps] = useState([
    "Objective",
    "Materials Required.",
    "Instructions",
    "Max marks",
  ]);
  const [handsOnExistingData, setHandsOnExistingData] = useState<any>([]);
  const [existingHandsOnId, setExistingHandsOnId] = useState("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const [challengeSteps, setChallengeSteps] = useState([
    "Challenge",
    "Max marks",
  ]);
  const [challengeExistingData, setChallengeExistingData] = useState<any>([]);
  const [existingChallengeId, setExistingChallengeId] = useState("");
  const [AIChatopen, setAIChatOpen] = useState(false);
  

  useEffect(() => {
    getTabs();
  }, []);

  useEffect(() => {
    getTabs(false);
  }, [addedContents, activeTab]);

  useEffect(() => {
    if (videoElement) {
      // get current time
      const elapsed_seconds = videoElement.target.getCurrentTime();
        videoElement.target.pauseVideo();
      
    }
  }, [isPaused, videoElement]);

 



  const getContentList = () => {
    setLoading(true);
    setQuizData([]);
    setQuizId("");
    ContentManagementServices.listContent()
      .then((data: any) => {
        var tData: any = [];
        data.data.learningobjectives.forEach((item: any) => {
          if (
            item.content_type == ContentStandards.PDF ||
            item.content_type == ContentStandards.VIDEO ||
            item.content_type == ContentStandards.PPT ||
            item.content_type == ContentStandards.QUIZ ||
            item.content_type == ContentStandards.HANDS_ON ||
            item.content_type == ContentStandards.CHALLENGE
          )
            tData.push(item);
          if (item.content_type == ContentStandards.QUIZ) {
            setQuizData(JSON.parse(item.content));
            setQuizId(item._id);
          }
        });
        setAddedContents([...tData]);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error("v3: getContentList: ", err)
        message.error("Failed to list contents, Please try again!");
      });
  };

  const deleteContent = async (contentId: any) => {
    ContentManagementServices.deleteContent(contentId)
      .then((data: any) => {
        message.success("Content deleted successfully!");
        getContentList();
      })
      .catch((err: any) => {
        message.error("Failed to delete content, Please try again!");
      });
  };

  const _onReady = (event: YouTubePlayer) => {
    videoElement = event;
  };
  
  const _azurePlayer = ({cotainername, videoId}: any) => {
    // const containerName = 'steamcubeclass-courses-videos';
    const containerName = cotainername;
    const blobName = videoId;
    const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rltf&se=2024-12-31T15:16:51Z&st=2024-04-30T07:16:51Z&spr=https&sig=6uJJG%2B5Rp4Ke2qJ9acQrJnjVFxwjYy9at%2FiI499DkOE%3D';

    // const url = `https://steamcubecourses.blob.core.windows.net/${containerName}/${blobName}?${sasToken}`;
    const url = `https://steamcubeazblobcdn1.azureedge.net/${containerName}/${blobName}?${sasToken}`;
    console.log("v3: azureplayer url: ", encodeURIComponent(url))
  
  
    if (containerName.endsWith('videos')){
      return url;
    }
    else{
      return encodeURIComponent(url);
    }
  };

  const previewFile = (content: any) => {
    console.log("content: ", content)
    if (content.content_type == ContentStandards.HANDS_ON) {
      setHandsOnExistingData(JSON.parse(content.content));
      setExistingHandsOnId(content._id);
      setSelectedOption(ContentStandards.HANDS_ON);
    } else if (content.content_type == ContentStandards.QUIZ) {
      setQuizData(JSON.parse(content.content));
      setQuizId(content._id);
      setSelectedOption(4);
    } else if (content.content_type == ContentStandards.CHALLENGE) {
      setChallengeExistingData(JSON.parse(content.content));
      setExistingChallengeId(content._id);
      setSelectedOption(ContentStandards.CHALLENGE);
    } else {
      setPreviewOpen(true);
      setPreviewData(content);
      
    } 
  };


  const togglePause = () => {
    setIsPaused(!isPaused);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleCancel = () => {
    // Your logic when Cancel button is clicked or modal is closed
    // const vidRef = useRef(null);
    setPreviewOpen(false)
    // setVisible(false);
    togglePause();
  };

  const getTabs = (refreshContent: Boolean = true) => {
    const tabsData = JSON.parse(localStorage.getItem("tabs_data") || "[]");

    let tTabs: any = [];
    let selectedLessons = JSON.parse(
      localStorage.getItem("teacher_selected_lessons") || "{}"
    );
    tabsData.forEach((tab: any) => {
      console.log("v3: each tab: ", tab.tabkey)
      if (tab.lobid === selectedLessons.learningObjective.learningobjectiveid) {
        console.log("v3: found tab from BE: ")
        tTabs.push({
          key: tab.tabkey,
          // label: tab,
          label: (
            <Tooltip title={`${tab.tabvalue}`}>
              <span>{tab.tabvalue}</span>
            </Tooltip>
          ),
          children: (
            <ContentAdditionComponent
              tabName={tab.tabvalue}
              addedContent={addedContents}
              loading={loading}
            />
          ),
        });
    }
    });

    setTabs(tTabs);
    if (refreshContent) {
      setTimeout(() => {
        //setActiveTab(tabsData[0]);
        let selectedLessons = JSON.parse(
          localStorage.getItem("teacher_selected_lessons") || "{}"
        );
        

        selectedLessons["selectedtopic"] = localStorage.getItem("selectedtopic")
        selectedLessons["tab"] = tabsData[0].tabkey;
        selectedLessons["parenttype"] = "topiclobcontenttype";
        localStorage.setItem(
          "teacher_selected_lessons",
          JSON.stringify(selectedLessons)
        );
        getContentList();
      }, 200);
    }
  };

  const addQuiz = (data: any) => {
    ContentManagementServices.addContent(
      ContentStandards.QUIZ,
      "Questions",
      JSON.stringify(data),
      quizId ? quizId : false
    ).then((response: any) => {
      message.success("Questions added successfully!");
    });
  };
  const saveHandsOn = (data: any) => {
    console.log("v3: content addition save Hands-on called.");
    ContentManagementServices.addContent(
      ContentStandards.HANDS_ON,
      "Hands On Activity",
      JSON.stringify(data),
      existingHandsOnId ? existingHandsOnId : false
    ).then((response: any) => {
      message.success("Hands-On activity added successfully!");
    });
  };

  const saveChallenge = (data: any) => {
    console.log("v3: content addition save Challenge called.");
    ContentManagementServices.addContent(
      ContentStandards.CHALLENGE,
      "Challenge Activity",
      JSON.stringify(data),
      existingChallengeId ? existingChallengeId : false
    ).then((response: any) => {
      message.success("Challenge activity added successfully!");
    });
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
    let selectedLessons = JSON.parse(
      localStorage.getItem("teacher_selected_lessons") || "{}"
    );

    selectedLessons["tab"] = key;
    localStorage.setItem(
      "teacher_selected_lessons",
      JSON.stringify(selectedLessons)
    );

    getContentList();
  };

  const ContentAdditionComponent = ({
    tabName,
    addedContent,
    loading,
  }: any) => {
    return (
      <div>
        <div>
          <Card
            title={`Prepare your content for ${tabName} using the tools below!`}
            style={{ width: "100%", height: "70vh" }}
          >
          <Row>
  <Col span={24}>
  <Typography.Title disabled level={5}>
                  Choose any of the below option to add your content
                </Typography.Title>

                <Space>
      <Card size="small" title="Explanation" style={{ background: 'greenyellow' }}>
        <Space>
          <Button
            size="large"
            type="primary"
            color="red"
            icon={<FaYoutube size={16} />}
            onClick={() => {
              setSelectedOption(1);
            }}
          >
            &nbsp; Video
          </Button>
          <Button
            type="primary"
            size="large"
            icon={<FaFilePowerpoint size={16} />}
            onClick={() => {
              setSelectedOption(2);
            }}
          >
            &nbsp; Presentation
          </Button>
          <Button
            size="large"
            type="primary"
            icon={<FaFilePdf size={16} />}
            onClick={() => {
              setSelectedOption(3);
            }}
          >
            &nbsp; PDF File
          </Button>
        </Space>
      </Card>

      <Divider type="vertical" />

      <Card size="small" title="Assessment" style={{ background: 'yellow' }}>
        <Space>
          <Button
            size="large"
            type="primary"
            icon={<BsFillPatchQuestionFill size={16} />}
            onClick={() => {
              setSelectedOption(4);
            }}
          >
            &nbsp; Questions
          </Button>
          </Space>
          </Card>
          <Card size="small" title="Real-Life" style={{ background: 'orange', marginLeft: "22px" }}>
            <Space>
          <Button
            size="large"
            type="primary"
            icon={<AiFillExperiment size={16} />}
            onClick={() => {
              setSelectedOption(ContentStandards.HANDS_ON);
            }}
          >
            &nbsp; Hands On
          </Button>
          <Button
            size="large"
            type="primary"
            icon={<GiThink size={16} />}
            onClick={() => {
              setSelectedOption(ContentStandards.CHALLENGE);
            }}
          >
            &nbsp; Challenge
          </Button>
        </Space>
      </Card>
      <Card size="small" title="AI Chat" style={{ background: 'yellowgreen' }}>
        <Space>
          {/* Add Chat Button here */}
          <IconButton aria-label="chat" sx={{ color: 'blue' }} onClick={handleAIChatOpen}
          >
            <Chat />
          </IconButton>
        </Space>
      </Card>
    </Space>
  </Col>
</Row>
            <Row style={{ height: "200px", overflowX: "scroll"}}>
              <Col span={24}>
                <Row>
                  <Typography.Title level={5}>
                    Contents for {tabName}
                  </Typography.Title>
                </Row>
                <Row>
                  <Col span={24}>
                    {!loading && (
                      <List
                        itemLayout="horizontal"
                        dataSource={addedContent}
                        renderItem={(item: any) =>
                          (item.content_type == ContentStandards.PDF ||
                            item.content_type == ContentStandards.PPT ||
                            item.content_type == ContentStandards.VIDEO ||
                            item.content_type == ContentStandards.QUIZ ||
                            item.content_type == ContentStandards.HANDS_ON ||
                            item.content_type == ContentStandards.CHALLENGE) && (
                              <List.Item
                              
                              actions={[
                                <Popconfirm
                                  placement="topLeft"
                                  title={"Delete Content?"}
                                  description={
                                    "Are you sure that you want to delete this content?"
                                  }
                                  onConfirm={() => {
                                    deleteContent(item._id);
                                  }}
                                  okText="Delete"
                                  cancelText="No"
                                >
                                  <Button
                                    type="text"
                                    
                                    icon={<DeleteFilled />}
                                    danger
                                  ></Button>
                                </Popconfirm>,
                                <Button
                                  type="link"
                                  icon={<EyeFilled />}
                                  style={{marginRight: "370px"}}
                                  onClick={() => {
                                    previewFile(item);
                                  }}
                                >
                                  Preview
                                </Button>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={get_content_title(item.content_type)?.icon}
                                title={get_content_title(item.content_type)?.title}
                                description={get_content_title(item.content_type)?.description}
                              />
                            </List.Item>
                          )
                        }
                      />
                    )}
                    {loading && <Spin></Spin>}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  };

let selectedLessons = JSON.parse(
  localStorage.getItem("teacher_selected_lessons") || "{}"
);

const handleAIChatOpen = () => {
  setAIChatOpen(true);
};

const handleAIChatClose = () => {
  setAIChatOpen(false);
};

  return (
    <>
      <Card>
        <h1
          style={{
            fontSize: 15,

            color: "rgb(0, 102, 178)",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            Content is prepared for Learners to practice Competencies.{" "}
          </span>
          <br />
          <span style={{ color: "grey" }}>
            If there are multiple competencies to be practiced, then the learner
            has to go from LOW to MEDIUM to HIGH Competencies.
          </span>
        </h1>
      </Card>
      <Tabs items={tabs} onChange={onTabChange} />
      <Modal
        open={selectedOption ? true : false}
        width={"95%"}
        closable={true}
        onCancel={() => {
          setSelectedOption(0);
          getContentList();
        }}
        footer={null}
      >
        <div style={{ minHeight: "70vh" }}>
          {selectedOption == 1 && <VideoCreation></VideoCreation>}
          {selectedOption == 2 && <FileUpload fileType="pptx"></FileUpload>}
          {selectedOption == 3 && <FileUpload fileType="pdf"></FileUpload>}
          {selectedOption == 4 && (
            <Quizz onSave={addQuiz} data={quizData}></Quizz>
          )}
          {selectedOption == ContentStandards.HANDS_ON && (
            <CustomActivity
              activityName="Hand On Activity"
              activityDescription="Create a hands-on activity"
              existingData={handsOnExistingData}
              steps={handsonSteps}
              onSave={saveHandsOn}
            />
          )}
          {selectedOption == ContentStandards.CHALLENGE && (
            <CustomActivity
              activityName="Challenge Activity"
              activityDescription="Create a challenge activity"
              existingData={challengeExistingData}
              steps={challengeSteps}
              onSave={saveChallenge}
            />
          )}
        </div>
      </Modal>
      <Modal
        open={previewOpen}
        // open={_onReady}
        closable={true}
        width={"95%"}
        destroyOnClose={true}
        forceRender={true}
        // afterClose={togglePause}
        onCancel={handleCancel}
        // onCancel={() => {
        //   setPreviewOpen(false);
        // }}
        
        footer={null}
      >
        
        <div style={{ height: "70vh" }}>
          {previewData.content_type === ContentStandards.VIDEO && (
             <div style={{ height: "100%" }}>
             {selectedLessons["syllabus"] === 'CBSE' ? (
               // CBSE Syllabus - YouTube Player
               <>
                 {Object.keys(JSON.parse(previewData.content)).length > 0 && (
                   <YouTube
                     style={{ height: "100%" }}
                     videoId={JSON.parse(previewData.content).url}
                     onReady={_onReady}
                     opts={{
                       height: "100%",
                       width: "100%",
                       playerVars: {
                         autoplay: 0,
                         controls: 1,
                         showinfo: 0,
                         rel: 0,
                         start: JSON.parse(previewData.content).trimRange.start,
                         end: JSON.parse(previewData.content).trimRange.end,
                         modestbranding: 0,
                         iv_load_policy: 3,
                       },
                     }}
                   ></YouTube>
                 )}
               </>
             ) : (
               // Non-CBSE Syllabus - Azure Video Player
               <><div>
        {(
          <video ref={videoRef} controls autoPlay width="100%" height="50%" controlsList="nodownload">
            <source src={_azurePlayer({cotainername:'steamcubeclass-courses-videos',
              videoId: JSON.parse(previewData.content).url})} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div><script>
          document.querySelector('video').setAttribute('onContextMenu', "return false;")
        </script></>
              //  <AzureVideoPlayer videoId={previewData.content["url"]}/>
             )}
           </div>
            // <YoutubeViewer content={previewData.content} syllabus={selectedLessons["syllabus"]}></YoutubeViewer>
            
          )}
          {(previewData.content_type === ContentStandards.PPT ||
            previewData.content_type == ContentStandards.PDF) && (
            <GoogleDocsViewer
              // src={`${APIStandards.SPACES_END_POINT_URL}${
              //   JSON.parse(previewData.content).filename
              // }`}
              src={_azurePlayer({cotainername: 'steamcube-content-uploads',
              videoId : JSON.parse(previewData.content).filename})}
            ></GoogleDocsViewer>
          )}
        </div>
        <style>
          {`
      .ant-modal-close-x {
        color: black;
        font-weight: bold;
        font-size: 26px;
        background-color: white;
        cursor: pointer;
        
      }

      .ant-modal-close-x:hover {
        background-color: grey;
      }
    `}
        </style>
      </Modal>

      {/* <MUIModal
        open={AIChatopen}
        onClose={handleAIChatClose}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
        style={{
          background: 'white',
          position: 'absolute',
          right: 16,
          top: '10%',
          left: '70%',
          // transform: 'translateY(-50%)',
          width: '25vw',
          height: '75vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: 16, // Add rounded corners
          overflow: 'hidden', // Add overflow hidden to prevent content from overflowing
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1 }}>
          <IconButton onClick={handleAIChatClose}>
            <Close />
          </IconButton>
        </Box>
        <AIChatBot />
      </MUIModal> */}
      <Dialog
        open={AIChatopen}
        onClose={handleAIChatClose}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            right: 0,
            top: '25%',
            bottom: 0,
            width: '25vw',
            maxHeight: '75vh',
            overflowY: 'auto',
            display: 'flex',
            // flexDirection: 'column-reverse',
            // justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Teaching Assistant AI ChatBot
          <IconButton onClick={handleAIChatClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <AIChatBot />
        </DialogContent>
      </Dialog>
      
    </>
  );
}
