import React from 'react';
import { Card as AntdCard, Table, Button, Anchor } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

interface ReviewCardProps {
  isVisible: boolean;
  columns: any[];
  data: any[];
  onStartReview: (record: any) => void;
  onDownloadResult: (record: any) => void;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ isVisible, columns, data, onStartReview, onDownloadResult }) => {
  return (
    <AntdCard title="Student Learning Sessions" bordered={false} style={{ marginTop: 16 }} hidden={!isVisible}>
      <Table
        columns={columns.map((column) => {
          if (column.dataIndex === 'startReview') {
            return {
              ...column,
              render: (text: any, record: any) => (
                <Button 
                  type="primary"
                  onClick={() => onStartReview(record)}
                  disabled={!record.quizisReviewable && !record.handsonisReviewable && !record.challengeisReviewable}
                >
                  Start Review
                </Button>
              ),
            };
          }
          if (column.dataIndex === 'viewResults') {
            return {
              ...column,
              render: (text: any, record: any) => (
                <Button 
                  type="primary"
                  onClick={() => onDownloadResult(record)}
                  disabled={!record.quizisResultDownloadable && !record.handsonisResultDownloadable && !record.challengeisResultDownloadable}
                  icon={(!record.quizisResultDownloadable && !record.handsonisResultDownloadable && !record.challengeisResultDownloadable) ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                >
                  View results
                </Button>
              ),
            };
          }
          if (column.dataIndex === 'quizreviewRequired' || column.dataIndex === 'handsonreviewRequired' || column.dataIndex === 'challengereviewRequired') {
            return {
            };
          }
          return column;
        })}
        dataSource={data}
        pagination={false}
        rowKey="key"
      />
    </AntdCard>
  );
};

export default ReviewCard;