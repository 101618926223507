import { PlusCircleFilled } from "@ant-design/icons";
import { CChart } from "@coreui/react-chartjs";
import {
  Row,
  Col,
  Button,
  Modal,
  Timeline,
  Card,
  Divider,
  Result,
  Space,
  Alert,
  Badge,
  Tooltip,
  Popover,
} from "antd";
import React, { Children, useEffect, useRef, useState } from "react";
import { FcLock } from "react-icons/fc";
import ContentCreationMainModal from "src/components/teacher/content_creation/content_creation_main_model";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import ContentStandards from "src/utils/content_standards";
import { useNavigate } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import CurriculamAggregatedReport from "src/components/teacher/curriculum_aggregated_report/curriculam_aggregated_report";
import CourseContentCreationMainModal from "src/components/teacher/content_creation/course_content_creation_main_modal";
import CurriculumAggregateStudentReport from "src/components/teacher/curriculum_aggregate_student_report/curriculum_aggregate_student_report";
// import PowerBIReport from 'src/components/common/powerbi/reports/powerbireport';
import PowerBIReportWrapper from "src/components/common/powerbi/reports/powerbireportwrapper";
import axios from 'axios';
type ButtonType = "first" | "second" | "third" | "fourth" | "fifth" | null;

export default function TeacherHome() {
  const navigate = useNavigate();
  console.log("v3: env vars: ",process.env);
  const deployment_type = process.env.REACT_APP_deployment_type || "dev";

  const [contentCreationModal, setContentCreationModal] = useState(false);
  const [courseContentCreationModal, setCourseContentCreationModal] =
    useState(false);
  const [dayVsCountGraphdata, setDayVsCountGraphdata] = useState<any>({});
  const [timeLineData, setTimeLineData] = useState<any>([]);
  const [additionalModalVisible, setAdditionalModalVisible] = useState(false);
  const [contentDistributionData, setContentDistributionData] = useState<any>(
    {}
  );
  const [mainModalVisible, setMainModalVisible] = useState(false);
  const [selectedButton, setSelectedButton] = useState<ButtonType | null>(null);
  const [reportConfig, setReportConfig] = useState({
    reportId: '',
    embedUrl: '',
    accessToken: ''
  });
  const [powerbiloading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>({});

  useEffect(() => {
    const userJson = localStorage.getItem("userDetails");
    console.log("v3: Fetch report config user details: ", userJson);
    if (userJson) {
      const userDetails = JSON.parse(userJson);
      console.log("v3: Parsed user details: ", userDetails);
      setUserDetails(userDetails);
      fetchReportConfig(userDetails);
    }
  }, []);

  const fetchReportConfig = async (userDetails: any) => {
    try {
      let reportName = deployment_type.toString() === 'prod' ? "steamcube_developers_teacher's_dashboard - Copy" : "steamcube_developers_teacher's_dashboard -dev2-DB";
      console.log("v3: Embed repport User Details: ",userDetails);
      const response = await axios.get('https://steamcubepowerbiembed.azurewebsites.net/api/httppowerbiembed',{
        params: {
          code: 'rb-5uyB5zQDSzGZkGDzI7jQxIUe0PNUwDIPVS3G0aHBwAzFuhglfkg==',
          workspaceId: '6d4ca98c-b755-4326-8543-0a77cf9bd330',
          reportName: reportName
        }
      });
      console.log("fetchReportConfig -> Azure Function app complete")

      // Assuming the returned JSON structure includes `reportId`, `embedUrl`, `accessToken`
      const data = response.data;
      const modified_embedUrl = `${data.embedUrl}&filter=steamcube_x0020_vw_teacher_school_credentials/Teacher_x0020_email eq '${userDetails.userdetails.username}' and steamcube_x0020_vw_teaching_sessions/username eq '${userDetails.userdetails.username}'`;
      // const modified_embedUrl = `${data.embedUrl}&filter=steamcube_x0020_vw_teacher_school_credentials/Teacher_x0020_email eq 'steamcourse.teacher1@steamcube.in'`;
      // steamcube vw_teaching_sessions/username

      // steamcube vw_teacher_school_credentials/Teacher Name
      // steamcube vw_teacher_school_credentials/Teacher email
      setReportConfig({
        reportId: data.reportId,
        embedUrl: modified_embedUrl,
        accessToken: data.embedToken
      });

      // console.log("fetchReportConfig -> setconfig complete");
      // console.log("fetchReportConfig -> setconfig", data.embedUrl);
      // console.log("fetchReportConfig -> setconfig", modified_embedUrl);
      // console.log("fetchReportConfig -> setconfig", data.reportId);
      // console.log("fetchReportConfig -> setconfig", data.embedToken);

      setLoading(false); // Set loading to false as fetch is complete
    } catch (error) {
      console.error("Error fetching Power BI config", error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   CommunicationService.post(APIStandards.TEACHER.GET_DASHBOARD_DATA, {}).then(
  //     (data: any) => {
  //       generateContentCountVsDaysData(data.data);
  //       generateTimeLineData(data.data);
  //       generateContentDistributionData(data.data);
  //     }
  //   );
  // }, []);

  const openMainModal = () => {
    setMainModalVisible(true);
  };

  const handleFirstButtonClick = () => {
    setSelectedButton("first");
    setAdditionalModalVisible(false);
    // Call the function here (e.g., navigate("/u/teacher/lesson-plan"))
    // ...
  };

  const openContentAddition = () => {
    setAdditionalModalVisible(true);
  };

  const openAdditionalModal = () => {
    setContentCreationModal(true); // Open Content Creation Modal
  };
  const generateContentCountVsDaysData = (data: any) => {
    let day_vs_count: any = {};
    data.forEach((data: any) => {
      const d = new Date(data["created_datetime"]);
      if (!day_vs_count[d.toLocaleDateString()]) {
        day_vs_count[d.toLocaleDateString()] = 1;
      } else {
        day_vs_count[d.toLocaleDateString()] += 1;
      }
    });

    let graph_data = {
      labels: [...Object.keys(day_vs_count)],
      datasets: [
        {
          label: "Content Added",
          backgroundColor: "rgba(0, 204, 102, 1)",
          borderColor: "rgba(0, 204, 102, 1)",
          pointBackgroundColor: "rgba(0, 204, 102, 1)",
          pointBorderColor: "rgba(0, 204, 102, 1)",
          data: [...Object.values(day_vs_count)],
        },
      ],
    };
    setDayVsCountGraphdata(graph_data);
    console.log({ graph_data });
  };

  const generateTimeLineData = (data: any) => {
    let t_timeline_data: any = [];
    data.forEach((data: any) => {
      const d = new Date(data["created_datetime"]);
      const activity = `Added ${Object.keys(ContentStandards)
        .filter(
          (key: string) =>
            ContentStandards[key as keyof typeof ContentStandards] ==
            data.content_type
        )[0]
        .toLowerCase()
        .replace("_", " ")} for ${data.lesson_details[0].subject}`;
      t_timeline_data.push({
        label: `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`,
        children: activity,
      });
    });
    setTimeLineData(t_timeline_data);
  };

  const generateContentDistributionData = (data: any) => {
    let content_distribution_data: any = {};
    data.forEach((data: any) => {
      const type = Object.keys(ContentStandards)
        .filter(
          (key: string) =>
            ContentStandards[key as keyof typeof ContentStandards] ==
            data.content_type
        )[0]
        .toLowerCase()
        .replace("_", " ");

      if (!content_distribution_data[type]) {
        content_distribution_data[type] = 1;
      } else {
        content_distribution_data[type] += 1;
      }
    });
    setContentDistributionData(content_distribution_data);
  };
  if (powerbiloading) {
    console.log("fetchReportConfig -> powerbiloading is true")
    return <div>Loading report...</div>;
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          {/* <Alert
            message="Complete the Year plan."
            description={`Dear ${
              JSON.parse(localStorage.getItem("userDetails") || "{}")
                .user_details.name
            }, as we prioritize our education system's effectiveness, completing the year plan becomes crucial in nurturing and enriching our students' learning experiences. Let's work together to ensure every aspect is thoughtfully planned and executed for their growth and development.`}
            type="info"
            showIcon
            closable
            style={{ marginBottom: "5px" }}
          /> */}

          <Space className="float-right mt-3">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={openContentAddition}
            >
              Want to create some content?
            </Button>
            <Modal
              open={contentCreationModal}
              onCancel={() => setContentCreationModal(false)}
              width="80%"
              footer={null}
              destroyOnClose={true}
            >
              <ContentCreationMainModal />
            </Modal>
            <Modal
              open={additionalModalVisible}
              onCancel={() => setAdditionalModalVisible(false)}
              footer={null}
              destroyOnClose={true}
              width="40%"
            >
              <div><p></p></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* First button to open existing modal */}
                <Popover
                  content={
                    <p>
                      Prepare content for teaching the chapter here.
                      <span style={{ fontWeight: "bold" }}>(Academics)</span>
                    </p>
                  }
                  placement="right"
                >
                  <Button
                    type="primary"
                    icon={<PlusCircleFilled />}
                    onClick={() => setContentCreationModal(true)}
                    style={{ marginBottom: "10px", width: "90%" }}
                  >
                    Curriculum
                  </Button>
                </Popover>

                {/* Other buttons */}
                <Popover
                  content={
                    <p>
                      Prepare content for teaching the course here.
                      <span style={{ fontWeight: "bold" }}>
                        (Non-Academics)
                      </span>
                    </p>
                  }
                  placement="right"
                >
                  <Button
                    style={{ marginBottom: "10px", width: "90%" }}
                    type="primary"
                    icon={<PlusCircleFilled />}
                    onClick={() => setCourseContentCreationModal(true)}
                  >
                    Courses
                  </Button>
                </Popover>
                <Button
                  style={{ marginBottom: "10px", width: "90%" }}
                  type="primary"
                  icon={<PlusCircleFilled />}
                  disabled={true}
                >
                  Projects
                </Button>
                <Button
                  style={{ marginBottom: "10px", width: "90%" }}
                  type="primary"
                  icon={<PlusCircleFilled />}
                  disabled={true}
                >
                  Internship
                </Button>
                <Button
                  style={{ marginBottom: "10px", width: "90%" }}
                  type="primary"
                  icon={<PlusCircleFilled />}
                  disabled={true}
                >
                  Products
                </Button>
              </div>
            </Modal>

            {/* Wrapping the Alert and Button in a div */}
            <div>
              <Button
                type="dashed"
                onClick={() => {
                  navigate("/u/teacher/lesson-plan");
                }}
              >
                Manage your year plan
              </Button>

 
            </div>
          </Space>
        </Col>
      </Row>
      <Divider></Divider>

      {/* <CChart type="line" data={dayVsCountGraphdata} /> */}
      {/* <iframe
        title="Teacher's_Dashboard"
        width="1140"
        height="541.25"
        src="https://app.powerbi.com/reportEmbed?reportId=f5f9a94b-b99d-43fb-b358-35d8c8f14d0e&autoAuth=true&ctid=e1ff8195-7df6-4ccd-960a-de8e099a8fbb"
      ></iframe> */}

      {/* <iframe
        title="Teacher's Dashboard (renewed)"
        width="1140"
        height="541.25"
        src="https://app.powerbi.com/reportEmbed?reportId=c58760cd-1a07-494c-9dce-18030bd4db20&autoAuth=true&ctid=e1ff8195-7df6-4ccd-960a-de8e099a8fbb"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
      <Divider></Divider> */}
      {/* Added PowerBI report embed */}
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Card size="small" title="Teacher Daily Dashboard Report">
            <PowerBIReportWrapper
              reportId={reportConfig.reportId}
              embedUrl={reportConfig.embedUrl}
              accessToken={reportConfig.accessToken}
              // width="100%" // You can adjust this as needed
              // height="850px" // You can adjust this as needed
            />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[24, 16]}>
        <Col span={16}>
          <Card size="small" title="Content Creation vs Day (Last 30 Days)">
            <CChart type="line" data={dayVsCountGraphdata} />
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small" title="Total Content Distribution">
            <CChart
              type="polarArea"
              data={{
                labels: [...Object.keys(contentDistributionData)],
                datasets: [
                  {
                    data: [
                      ...Object.values(contentDistributionData).map(
                        (data: any, index: number) => {
                          return parseInt(data.toString());
                        }
                      ),
                    ],
                    backgroundColor: [
                      "#FF6384",
                      "#4BC0C0",
                      "#FFCE56",
                      "#E7E9ED",
                      "#36A2EB",
                      "#eb8d36",
                      "#213fd9",
                      "#a1186c",
                      "#bf1733",
                    ],
                  },
                ],
              }}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card size="small" title="Subject vs Student Learning">
          <CurriculumAggregateStudentReport/>
            {/* <Result
              icon={<FcLock scale={8} style={{ fontSize: "130px" }} />}
              title="No data available yet!"
              subTitle="No learning information from students to display!"
            ></Result> *-/}
          </Card>
        </Col>
        <Col span={8}>
          <Card size="small" title="Your Activity Timeline (top 10 events)">
           
            <Timeline mode={"left"}>
              {timeLineData
                .slice(Math.max(timeLineData.length - 10, 0))
                .reverse()
                .map((data: any, index: number) => {
                  return (
                    <Timeline.Item label={data.label}>
                      {data.children}
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col span={24}>
          <Card title={"Curriculum Month wise Report"}>
            <CurriculamAggregatedReport />
          </Card>
        </Col>
      </Row> */}
      <Modal
        open={contentCreationModal}
        closable
        onCancel={() => {
          setContentCreationModal(false);
        }}
        width="80%"
        footer={null}
        destroyOnClose={true}
      >
        <ContentCreationMainModal></ContentCreationMainModal>
      </Modal>
      <Modal
        open={courseContentCreationModal}
        closable
        onCancel={() => {
          setCourseContentCreationModal(false);
        }}
        width="80%"
        footer={null}
        destroyOnClose={true}
      >
        <CourseContentCreationMainModal></CourseContentCreationMainModal>
      </Modal>
    </div>
  );
}
