import React, { useState, useEffect } from 'react';
import { Avatar, Col, Divider, Drawer, List, Row, Modal } from 'antd';
import { Input, Checkbox, Button, Form, Typography, message, Radio, RadioChangeEvent } from "antd";
import { UserOutlined, KeyOutlined, LoginOutlined, CloseSquareOutlined } from "@ant-design/icons";

import logo from "src/assets/images/logo/stemclass-logo-blue.png";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";

import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import type { UploadFile, UploadProps } from 'antd';


const { Title, Text } = Typography;


var userObj = {
    username: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    status: "",
    email_address: ""
}

interface EditProfile {
    formOpenFlag: boolean;
    sendFormOpenFlag: Function;
}


const EditProfile = ({ formOpenFlag, sendFormOpenFlag }: EditProfile) => {
    const [profileInfo, setProfileInfo] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        profileImageContent: "",
    });

    const [profileImageName, setProfileImageName] = useState("");
    const [invalidImage, setInvalidImage] = useState(false);

    const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(formOpenFlag);

    const [genderValue, setGenderValue] = useState('');
    const genderOptions = ['Male', 'Female', 'Others'];


    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);

    const submitEditProfileForm = () => {
        if (invalidImage) {
            message.error("Please upload a valid image file.");
        }
        else {
            (localStorage.length === 0) && (window.location.href = "/login");

            const token = localStorage.getItem("token");
            const userDetailsString = localStorage.getItem("userDetails");

            const userDetailsObj = JSON.parse(
                localStorage.getItem("userDetails") || "{}"
            );

            CommunicationService.post(APIStandards.GUEST.EDIT_USER_PROFILE, {
                "username": userDetailsObj['userdetails']["username"],
                "first_name": profileInfo.firstName,
                "middle_name": profileInfo.middleName,
                "last_name": profileInfo.lastName,
                "gender": profileInfo.gender,
                "profile_image": profileInfo.profileImageContent,
                "profile_image_name": profileImageName
            })
                .then(() => {
                    message.success(
                        "Succefully Updated!"
                    );
                    setTimeout(() => {
                        handleEditProfileCancel();
                    }, 500);
                })
                .catch((err: any) => {
                    //  TO display: 'Enter the current password correctly!'
                    message.error(err.response.data.error);
                })
                .catch((err: any) => {
                    message.error("Invalid submission, please try again!");
                });
        }
    }
    const getBase64 = (file: any): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setInvalidImage(true)
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
            setInvalidImage(true)
        }
        if (isJpgOrPng && isLt2M) {
            setInvalidImage(false)
        }
        if (file.name) {
            setProfileImageName(file.name);
        }
        const reader = new FileReader();
        reader.onload = (evt: any) => {
            setProfileInfo({ ...profileInfo, profileImageContent: evt.target.result })
            // setProfileInfo({ ...profileInfo, profileImage: reader.readAsDataURL(file) })
        }
        // read image as base64 encoded string
        reader.readAsDataURL(file);

        // return isJpgOrPng && isLt2M;
        // to turn off the upload error with action attribute and file's status props 
        return false
    };

    const handleCancelImg = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload </div>
        </button>
    );



    useEffect(() => {
        setIsEditProfileModalOpen(isEditProfileModalOpen);

    }, [isEditProfileModalOpen]);


    const handleEditProfileCancel = () => {
        setIsEditProfileModalOpen(false);
        sendFormOpenFlag(false)
    };

    const onGenderValueChange = ({ target: { value } }: RadioChangeEvent) => {
        setGenderValue(value);
        setProfileInfo({ ...profileInfo, gender: value[0] });
    };

    return (
        <>
            <Modal
                // title="Update your Profile:"
                open={formOpenFlag}
                // okText="Yes"
                // cancelText="No"
                // onOk={handleOk}
                onCancel={handleEditProfileCancel}
                width={800}
                style={{ top: 10 }}
                footer={null}>

                <div className="container">
                    <div className="login-panel" style={{ width: "100%" }}>
                        <div>
                            <Row>
                                <Col>

                                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                        <Title level={4}>Update your profile info!</Title>
                                    </div>

                                    <Form
                                        name="basic"
                                        wrapperCol={{ span: 24 }}
                                        initialValues={{ remember: true }}
                                        onFinish={() => { }}
                                        onFinishFailed={() => { }}
                                        autoComplete="off"
                                        layout="vertical"

                                    >
                                        <Text style={{ fontSize: 16 }} >
                                            A picture of yourself [png,jpeg]
                                        </Text>
                                        <Form.Item>
                                            <Upload
                                                accept=".png,.jpg,.jpeg"
                                                action=""
                                                beforeUpload={beforeUpload}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                            >
                                                {fileList.length >= 1 ? null : uploadButton}
                                            </Upload>
                                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelImg}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>

                                        <Form.Item
                                            label="First Name"
                                            name="firstName"
                                            rules={[{ message: "Please enter your first name!" }]}
                                        >
                                            <Input
                                                placeholder="Michael"
                                                size="large"
                                                //   prefix={<KeyOutlined />}
                                                onChange={(e) => {
                                                    setProfileInfo({ ...profileInfo, firstName: e.target.value });
                                                }}
                                            />
                                        </Form.Item>


                                        <Form.Item
                                            label="Middle Name"
                                            name="middleName"
                                            rules={[{ message: "Please enter your middle name!" }]}
                                        >
                                            <Input
                                                placeholder="Joseph"
                                                size="large"
                                                //   prefix={<KeyOutlined />}
                                                onChange={(e) => {
                                                    setProfileInfo({ ...profileInfo, middleName: e.target.value });
                                                }}
                                            />
                                        </Form.Item>


                                        <Form.Item
                                            label="Last Name"
                                            name="lastName"
                                            rules={[{ message: "Please enter your last name!" }]}
                                        >
                                            <Input
                                                placeholder="Jackson"
                                                size="large"
                                                //   prefix={<KeyOutlined />}
                                                onChange={(e) => {
                                                    setProfileInfo({ ...profileInfo, lastName: e.target.value });
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            <Radio.Group options={genderOptions} onChange={onGenderValueChange} value={genderValue} />
                                        </Form.Item>


                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                block
                                                size="large"
                                                icon={<LoginOutlined />}
                                                onClick={submitEditProfileForm}
                                            >
                                                Update
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
};

export default EditProfile;