import APIStandards from "src/utils/API_standards";
import ContentStandards from "src/utils/content_standards";
import CommunicationService from "./communication_service";

class ContentManagementServices {
  static async addContent(
    contentType: number,
    contentTitle: String,
    contentData: any,
    contentId: any = false
  ) {
    const selectedLessons = JSON.parse(
      localStorage.getItem("teacher_selected_lessons") || "{}"
    );

    const contentInfo = {
      content_data: contentData,
      content_type: contentType,
      content_title: contentTitle,
      content_id: contentId,
    };

    return CommunicationService.post(APIStandards.TEACHER.CREATE_CONTENT, {
      lessons: selectedLessons,
      content: contentInfo,
    });
  }

  static async listContent() {
    const selectedLessons = JSON.parse(
      localStorage.getItem("teacher_selected_lessons") || "{}"
    );

    console.log("v3: list content 1")
    return CommunicationService.post(APIStandards.TEACHER.LIST_CONTENTS, {
      lessons: selectedLessons,
    });
    // return CommunicationService.post(APIStandards.TEACHER.LIST_CONTENTS, {
    //     parentid : 1,
    //     parenttype : "lob"
    // });
  }

  static async listLearningOutcomes() {
    const selectedLessons = JSON.parse(
      localStorage.getItem("teacher_selected_lessons") || "{}"
    );

    const referanceStandardId = "";
    const standard = selectedLessons.standard;
    const subject = selectedLessons.subject;
    const strand = selectedLessons.strand;
    const referanceChapterId = "";
    const chapterNumber = selectedLessons.chapter[0].chapter_no;
    const chapterName = selectedLessons.chapter[0].chapter_name;

    const lessonArray = [
      [standard, standard, ContentStandards.STANDARD],
      [subject, subject, ContentStandards.SUBJECT],
      [strand, strand, ContentStandards.STRAND],
      [chapterNumber, chapterName, ContentStandards.CHAPTER],
    ];

    return CommunicationService.post(APIStandards.TEACHER.LIST_CONTENTS, {
      lessons: lessonArray,
    });
    console.log("v3: list content 2")
  //   return CommunicationService.post(APIStandards.TEACHER.LIST_CONTENTS, {
  //     parentid : 1,
  //     parenttype : "lob"
  // });
  }

  static async deleteContent(contentId: any) {
    return CommunicationService.post(APIStandards.TEACHER.DELETE_CONTENT, {
      content_id: contentId,
    });
  }
}

export default ContentManagementServices;
