import { Tabs, TabsProps } from "antd";
import React, { useState } from "react";
import TrimVideo from "../trim_video/trim_video";
import VideoChooser from "../video_chooser/video_chooser";
import "./video_creation.css";

export default function VideoCreation({
  standard,
  subject_details,
  chapter_details,
  topic_details,
  learning_objective_details,
}: any) {
  const [activeKey, setActiveKey] = useState("1");
  const [selectedVideo, setSelectedVideo] = useState({});
  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const onVideoSelect = (item: any) => {
    setActiveKey("2");
    setSelectedVideo({ ...item });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Search for a Video`,
      children: <VideoChooser onVideoSelect={onVideoSelect}></VideoChooser>,
    },
    {
      key: "2",
      label: `Choose Video`,
      children: <TrimVideo selectedVideo={selectedVideo}></TrimVideo>,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      activeKey={activeKey}
      items={items}
      onChange={onChange}
    />
  );
}
