import React, { useState, useContext } from "react";

export const DashboardContext = React.createContext({} as any);

export default function DashboardProvider({ children }: any) {
  const [sidebar, setSidebar] = React.useState(false);

  function toggleSidebar() {
    setSidebar(!sidebar);
  }

  return (
    <DashboardContext.Provider value={{ toggleSidebar, sidebar, setSidebar }}>
      {children}
    </DashboardContext.Provider>
  );
}
