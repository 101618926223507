import { MenuOutlined, UserOutlined, QuestionCircleOutlined, LogoutOutlined, ProfileTwoTone, MailTwoTone } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { DashboardContext } from "src/contexts/dashboard_context";
import ProfilePage from "src/views/guest/profile_page/profile_page"
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import "./header.css";
import { Button, Popover, Space, Typography, message, Modal, Divider, Tooltip, Popconfirm } from "antd";
import { Avatar, Col, Drawer, List, Row, Menu } from "antd";
import { useCookies } from "react-cookie";
import { FaSchool } from "react-icons/fa";
import type { PopconfirmProps } from 'antd';
const greetingTime = require("greeting-time");

export default function HeaderC() {
  // const { sidebar, toggleSidebar } = React.useContext(DashboardContext);
  const { Text } = Typography;
  const [userDetails, setUserDetails] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpMenuItem, setHelpMenuItem] = useState("");

  // variable to set the state for Profile View drawer
  const [viewProfileOpenFlag, setviewProfileOpenFlag] = useState(false);
  const [profileMenuSchoolContent, setProfileMenuSchoolContent] = useState<any>();
  const [profileMenuRoleContent, setProfileMenuRoleContent] = useState<any>();

// userObj with the same keys as in ProfileView's user_json
var userObj = {
  username: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  gender: "",
  status: "",
  email_address: "",
  profile_picture_base64: "",
  image_format_type: ""
}
  const [userObjState, setUserObjState] = useState(userObj);
  useEffect(() => {
    const userDetails: any = localStorage.getItem("userDetails");
    setUserDetails(JSON.parse(userDetails));
      getUserDetailsForProfile();
      const schoolchangeconfirm: PopconfirmProps['onConfirm'] = (schoolid) => {
        onChangeSchool(schoolid);
      };
      const rolechangeconfirm: PopconfirmProps['onConfirm'] = (roleid) => {
        onChangeRole(roleid);
      };
      setProfileMenuSchoolContent(Object.keys(userDetailObj.userdetails.schooldetail).map((key) => (
        <Popconfirm
          title="Switch school"
          description="Do you want to switch to this school?"
          onConfirm={(e) => schoolchangeconfirm(userDetailObj.userdetails.schooldetail[key].schoolidkey)}
          okText="Yes"
          cancelText="No"
          >
          <Menu.Item
            key={userDetailObj.userdetails.schooldetail[key].schoolidkey}
            icon={<FaSchool />}
            disabled={userDetailObj.userdetails.schooldetail[key].schoolname === localStorage.getItem("currentSchoolname") ? true : false}
            >
            {userDetailObj.userdetails.schooldetail[key].schoolname}
          </Menu.Item>
        </Popconfirm>
      )));
      setProfileMenuRoleContent(Object.keys(userDetailObj.userdetails.userdetail).map((key) => (
        <Popconfirm
          title="Switch Role"
          description="Do you want to switch to this role?"
          onConfirm={(e) => rolechangeconfirm(userDetailObj.userdetails.userdetail[key].rolenames.roleid)}
          okText="Yes"
          cancelText="No"
          >
          <Menu.Item
            key={userDetailObj.userdetails.userdetail[key].rolenames.roleid}
            icon={<FaSchool />}
            disabled={userDetailObj.userdetails.userdetail[key].rolenames.rolename === localStorage.getItem("currentRolename") ? true : false}
            >
            {userDetailObj.userdetails.userdetail[key].rolenames.rolename}
          </Menu.Item>
        </Popconfirm>
      )));
  }, []);


  const getUserDetailsForProfile = () => {


    const userDetailsObj = JSON.parse(
      localStorage.getItem("userDetails") || "{}"
    );

    CommunicationService.post(APIStandards.GUEST.PROFILE_VIEW, { "username": userDetailsObj['userdetails']["username"] })
      .then((res: any) => {
        setUserObjState(res.data)
        console.log(res.data)
      })
      .catch((err: any) => {
        console.log(err)
      });

  }

  const logout = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    localStorage.clear()
    window.location.href = "/login";
  };

// show logout confirmation pop up
  const showLogOutModal = () => {
    setIsModalOpen(true);
  };

// yes to to logout
  const handleLogOutOk = () => {
    setIsModalOpen(false);
    logout();
  };

// no to logout
  const handleOutCancel = () => {
    setIsModalOpen(false);
  };

 // open Drawer
  const showViewProfileDrawer = () => {
    setviewProfileOpenFlag(true);
  };

  // close Drawer
  const onViewProfileClose = (isOpen:boolean) => {
    setviewProfileOpenFlag(isOpen);
    getUserDetailsForProfile();
  };

  const helpMenu = (
    <Menu onClick={(e) => setHelpMenuItem(e.key)} style={{ width: 200 }}>
      <Menu.Item key="email">
        <Tooltip title="Click here to send email to Support">
          <a href="mailto:support@steamcube.in?subject=Support%20Request&body=Hello%20Support%2C%0A%0AI%20need%20help%20with%20..." target="_blank" rel="noopener noreferrer">Email</a>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="youtube">
        <Tooltip title="Click here to visit our Tutorials at Youtube">
          <a href="https://www.youtube.com/watch?v=DPc6VcohzA4&list=PLPfJ3uQEZtEw0LQypfLrKdGNgG4fAvIVQ" target="_blank" rel="noopener noreferrer">Foundational videos</a>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const schoolchangeconfirm: PopconfirmProps['onConfirm'] = (schoolid) => {
    console.log("v3: header school change confirm: ",schoolid);
    onChangeSchool(schoolid);
  };

  const rolechangeconfirm: PopconfirmProps['onConfirm'] = (roleid) => {
    console.log("v3: header role change confirm: ",roleid);
    onChangeRole(roleid);
  };

  const onChangeSchool = (schoolid: any)=>{
    Object.keys(userDetailObj.userdetails.schooldetail).map((key) => {
      
      if (userDetailObj.userdetails.schooldetail[key].schoolidkey === schoolid){
        CommunicationService.post(APIStandards.PUBLIC.SET_DEFAULT_SCHOOL, {
          currentschoolid: schoolid,
          currentschoolname: userDetailObj.userdetails.schooldetail[key].schoolname
        })
         .then((res: any) => {
            localStorage.setItem("currentSchoolid", schoolid);
            localStorage.setItem("currentSchoolname", userDetailObj.userdetails.schooldetail[key].schoolname);
            message.success("School changed to " + userDetailObj.userdetails.schooldetail[key].schoolname + ". Please reload this page to see the effects.");
            // Recalculate the profileMenuContent
            setProfileMenuSchoolContent(
              Object.keys(userDetailObj.userdetails.schooldetail).map((key) => (
                <Popconfirm
                  key={userDetailObj.userdetails.schooldetail[key].schoolidkey}
                  title="Switch school"
                  description="Do you want to switch to this school?"
                  onConfirm={(e) => schoolchangeconfirm(userDetailObj.userdetails.schooldetail[key].schoolidkey)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Menu.Item
                    key={userDetailObj.userdetails.schooldetail[key].schoolidkey}
                    icon={<FaSchool />}
                    disabled={userDetailObj.userdetails.schooldetail[key].schoolname === localStorage.getItem("currentSchoolname")? true : false}
                  >
                    {userDetailObj.userdetails.schooldetail[key].schoolname}
                  </Menu.Item>
                </Popconfirm>
              ))
            );
          })
         .catch((err: any) => {
            message.error("Error in changing school");
          });
      }
    });
  };

  const onChangeRole = (roleid: any)=>{
    Object.keys(userDetailObj.userdetails.userdetail).map((key) => {
      
      if (userDetailObj.userdetails.userdetail[key].rolenames.roleid === roleid){
        CommunicationService.post(APIStandards.PUBLIC.SET_DEFAULT_ROLE, {
          currentroleid: roleid,
          currentrolename: userDetailObj.userdetails.userdetail[key].rolenames.rolename
        })
         .then((res: any) => {
            localStorage.setItem("currentRoleid", roleid);
            localStorage.setItem("currentRolename", userDetailObj.userdetails.userdetail[key].rolenames.rolename);
            message.success("Role changed to " + userDetailObj.userdetails.userdetail[key].rolenames.rolename + ". Please reload this page to see the effects.");
            // Recalculate the profileMenuContent
            setProfileMenuRoleContent(Object.keys(userDetailObj.userdetails.userdetail).map((key) => (
              <Popconfirm
                title="Switch Role"
                description="Do you want to switch to this role?"
                onConfirm={(e) => rolechangeconfirm(userDetailObj.userdetails.userdetail[key].rolenames.roleid)}
                okText="Yes"
                cancelText="No"
                >
                <Menu.Item
                  key={userDetailObj.userdetails.userdetail[key].rolenames.roleid}
                  icon={<FaSchool />}
                  disabled={userDetailObj.userdetails.userdetail[key].rolenames.rolename === localStorage.getItem("currentRolename") ? true : false}
                  >
                  {userDetailObj.userdetails.userdetail[key].rolenames.rolename}
                </Menu.Item>
              </Popconfirm>
            )));
            // window.location.reload();
          })
         .catch((err: any) => {
            message.error("Error in changing role");
          });
      }
    });
  };

  const userDetailObj = JSON.parse(localStorage.getItem('userDetails') || '');

  const profileMenu = (
    <Menu selectable={false}>
      <Menu.Item key="1" onClick={showViewProfileDrawer} icon={<ProfileTwoTone />}>
        View Profile
      </Menu.Item>
      <Menu.Divider />
      <Typography.Text>Schools</Typography.Text>
        {profileMenuSchoolContent}
      <Menu.Divider />
      <Typography.Text>Roles</Typography.Text>
        {profileMenuRoleContent}
      <Menu.Divider />
      <Menu.Item key="2" onClick={showLogOutModal} icon={<LogoutOutlined style={{color: "#1677FF"}}/>}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={4}>
          {greetingTime(new Date())},{" "}
          {userObjState.first_name + " " + userObjState.last_name}.
        </Typography.Title>
        {/* <Space>
          <Button
            className="menu-icon"
            type="text"
            icon={<MenuOutlined />}
            onClick={toggleSidebar}
          ></Button>
        </Space> */}
      </div>
      <div className="header-items" style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <div className="header-item help-section">
          <Popover content={helpMenu} title="Help" trigger="click" placement="bottomLeft">
            <Tooltip title="Help">
              <Button className="help-icon" type="text" icon={<QuestionCircleOutlined />} />
            </Tooltip>
          </Popover>
        </div>
        <div className="header-item profile-section">
          <Text style={{ marginRight: "10px" }}>
            {userDetails?.user_details?.name}
          </Text>
          <Tooltip placement="leftBottom" title={userObjState.username} color="#666666">
          <Popover
            content={
              // <ul style={{ textAlign: "center", listStyle: 'none' }}>
              //   <li key={1}><a onClick={showViewProfileDrawer}>View Profile</a></li>
              //   <Divider />
              //   <li key={2}><a onClick={showLogOutModal}>Logout</a></li>
              // </ul>
              profileMenu
            }
            // title="Settings"
            trigger="click"
            placement="bottom"
          >
            {/* <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" /> */}
            { 
            // if the profile picture is available 
            userObjState.profile_picture_base64.length != 0 
              &&
              (
            <Avatar size={40} shape={"circle"}  src= { `data:image/${userObjState.image_format_type};base64,${userObjState.profile_picture_base64}`}/>
            )
             }

            { 
            // if the profile picture is not avaiable 
            userObjState.profile_picture_base64.length == 0 
              &&
              (
              <Avatar size={40} icon={<UserOutlined />} />
            )
             }
          </Popover>
          </Tooltip>

          <Modal title="Are you sure you want to Logout?"
            open={isModalOpen}
            okText="Yes"
            cancelText="No"
            onOk={handleLogOutOk}
            onCancel={handleOutCancel}>
          </Modal>
        </div>
      </div>

      <ProfilePage sendViewProfileOpenFlag={onViewProfileClose} viewProfileOpenFlag={viewProfileOpenFlag}/>
    </Header>
  );
}
