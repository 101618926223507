import { Button, Divider, Input, Space, message, Steps } from 'antd';
import React, { useState } from 'react';

type QuizData = {
    question_id: number;
    question: string;
    type: number;
    answers: string;
    selectedAnswer: number;
    maxMarks: number;
    contentid_id:number;
  };

export type QuizReviewData = {
    question_id: number;
    question: string;
    answers: string;
    maxMarks: number;
    reviewComments: string;
    reviewMarks: number;
    contentid_id:number;
}[];


export default function QuizReview({ onSave, data }: any) {
    console.log("v3: quizreviewdata data: ", data);
  const [quizReviewData, setQuizReviewData] = useState<QuizReviewData>(
    data ? JSON.parse(data).map((quizDataItem: QuizData) => ({
        question_id:quizDataItem.question_id,
        question: quizDataItem.question,
        answers: quizDataItem.type===1 ? quizDataItem.answers[quizDataItem.selectedAnswer] : quizDataItem.answers[0],
        maxMarks:quizDataItem.maxMarks,
        contentid_id: quizDataItem.contentid_id,
        reviewComments: undefined,
        reviewMarks: undefined,
    })) : [{
        question_id: 0,
        question: undefined,
        answers: undefined,
        maxMarks: undefined,
        contentid_id: undefined,
        reviewComments: undefined,
        reviewMarks: undefined,
    }],
  );
  
  const [index, setIndex] = useState(0);

  const next = () => {
    setIndex(index + 1);
  };

  const prev = () => {
    setIndex(index - 1);
  };

  const onChange = (value: number) => {
    console.log('onChange:', value);
    setIndex(value);
  };
  
  const items = quizReviewData.map((item) => ({ key: quizReviewData.indexOf(item), title: "" }));

  console.log("v3: quixreview quizdata: ", quizReviewData);

  const saveQuizReview = () => {
    // Call your API here with quizReviewData
    const hasEmptyFields = quizReviewData.some((reviewData) => {
        return reviewData.reviewComments === undefined || reviewData.reviewComments.trim() === '' || reviewData.reviewMarks === undefined;
      });
    
    const hasWrongValue = quizReviewData.some((reviewData) => {
        const inputValue = reviewData.reviewMarks;
        console.log("v3: quiz review mark type: ", typeof(inputValue))
        if (inputValue < 0 || inputValue > reviewData.maxMarks) {
            return true;
        }
        return false;
      });
    
      if (hasEmptyFields) {
        alert('Please fill in all the review comments and marks before saving.');
        return;
      }
      if (hasWrongValue) {
        alert('Review Marks should be between 0 and max marks.');
        return;
      }
    onSave(quizReviewData);
  };

  return (
    <div>
        <Steps type="default" size='small' onChange={onChange} current={index} items={items} />
      {
        <div key={index}>
          <h3 style={{fontWeight:"bold"}}>Question: </h3><div dangerouslySetInnerHTML={{ __html:quizReviewData[index].question }} />
          <h3 style={{fontWeight:"bold"}}>Answer: </h3><div dangerouslySetInnerHTML={{ __html:quizReviewData[index].answers }} />
          <h3 style={{fontWeight:"bold"}}>Max marks: </h3><div>{quizReviewData[index].maxMarks }</div>
          <Space direction="vertical" style={{ width: '100%' }}>
            <h2 style={{fontWeight:"bold"}}>Enter Review comments:</h2>
            <Input.TextArea
              value={quizReviewData[index].reviewComments}
              onChange={(e) => setQuizReviewData(
                quizReviewData.map((item, i) => (i === index ? { ...item, reviewComments: e.target.value } : item)),
              )}
              placeholder="Review Comments"
              required={true}
              title="Please enter review comments"
            />
            <h2 style={{fontWeight:"bold"}}>Enter Review marks:</h2>
            <Input
              value={quizReviewData[index].reviewMarks}
              onChange={(e) => setQuizReviewData(
                quizReviewData.map((item, i) => (i === index ? { ...item, reviewMarks: parseFloat(e.target.value) } : item)),
              )}
              type="number"
              min={0}
              max={quizReviewData[index].maxMarks}
              placeholder="Review Marks"
              required={true}
              title="Please enter review marks"
            />
          </Space>
        </div>
      // ))
      
      }
      <Button type="primary" disabled={index === quizReviewData.length - 1 ? false : true} onClick={saveQuizReview} style={{ marginTop: '16px',float:'right' }}>
        Save
      </Button>
      <div style={{ marginTop: 24 }}>
        {index < quizReviewData.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {index === quizReviewData.length - 1 && (
          <Button  type="primary" onClick={() => message.success('You are finished with the review! Click on Save.')}>
            Done
          </Button>
        )}
        {index > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
}