import { message } from "antd";
import APIStandards from "src/utils/API_standards";
import axios from "axios";
import { url } from "inspector";

function getBaseUrl() {
  // some logic to determine base URL here
  return Promise.resolve(
    'https://jsonplaceholder.typicode.com/',
  );
}
class CommunicationService {
  axiosInstance: any;
  
  constructor() {
    let jwtToken = null;
    if (localStorage.getItem("token")) {
      jwtToken = localStorage.getItem("token");
    }
    this.axiosInstance = axios.create({
      baseURL: APIStandards.AKS_RWRITE_URL,
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "application/json",
      },
    });
    this.axiosInstance.interceptors.request.use(function(config: any) {
      console.log("v3: Intercepting axios request: ", config)
        if (config.url.startsWith("rread")){
          config.baseURL = APIStandards.AKS_RREAD_URL
          // console.log("login_form post inside rread: ", this.axiosInstance.baseURL)
        }
        else if (config.url.startsWith("rwrite")) {
          config.baseURL = APIStandards.AKS_RWRITE_URL
        }
        else if (config.url.startsWith("fread")) {
          config.baseURL = APIStandards.AKS_FREAD_URL
        }
        else if (config.url.startsWith("fwrite")) {
          config.baseURL = APIStandards.AKS_FWRITE_URL
        }
        return config;
      },
      (      error: any) => Promise.reject(error),
    );
  }

  async get(url: string, params: any, show_alert = true) {
    // if (url.startsWith("rread")){
    //   this.axiosInstance.defaults.baseURL = APIStandards.AKS_RREAD_URL
    // }
    // else if ((url.startsWith("rwrite"))) {
    //   this.axiosInstance.baseURL = APIStandards.AKS_RWRITE_URL
    // }
    // else if ((url.startsWith("fread"))) {
    //   this.axiosInstance.baseURL = APIStandards.AKS_FREAD_URL
    // }
    // else if ((url.startsWith("fwrite"))) {
    //   this.axiosInstance.baseURL = APIStandards.AKS_FWRITE_URL
    // }
    
    // Adding extra parameters for select school and role
    params.selectedroleid = localStorage.getItem("currentRoleid");
    params.selectedschoolid = localStorage.getItem("currentSchoolid");
    url = url + "?";
    Object.keys(params).forEach((key: string) => {
      url = url + key + "=" + params[key] + "&";
    });
    let result = await this.axiosInstance.get(url);
    // if (result.status === 401){
    //   let refreshtoken = localStorage.getItem( 'refreshtoken' );
    //   this.post(APIStandards.GUEST.REFRESH, {
    //     refresh: refreshtoken
    //   })
    //     .then((data: any) => {
    //       let token = data.data.token;
    //       let refresh = data.data.refresh;
    //       localStorage.setItem("token", token);
    //       localStorage.setItem("refreshtoken", refresh);
    //       this.axiosInstance.headers = {
    //         Authorization: "Bearer " + token,
    //         "Content-Type": "application/json",
    //       }
    //       result = this.axiosInstance.get(url);
    //     })
    //     .catch((err: any) => {
    //       message.error("Some problem happened while getting the new access token. Please close the browser and login again.");
    //     });
    // }
    return result;
  }

  post(url: string, params: any, show_alert = true) {
    console.log("v3: login_form post: ", url)
    // if (url.startsWith("rread")){
    //   console.log("login_form post inside rread: ", url)
    //   this.axiosInstance.baseURL = APIStandards.AKS_RREAD_URL
    //   console.log("login_form post inside rread: ", this.axiosInstance.baseURL)
    // }
    // else if ((url.startsWith("rwrite"))) {
    //   this.axiosInstance.baseURL = APIStandards.AKS_RWRITE_URL
    // }
    // else if ((url.startsWith("fread"))) {
    //   this.axiosInstance.baseURL = APIStandards.AKS_FREAD_URL
    // }
    // else if ((url.startsWith("fwrite"))) {
    //   this.axiosInstance.baseURL = APIStandards.AKS_FWRITE_URL
    // }
    
    // Adding extra parameters for select school and role
    params.selectedroleid = localStorage.getItem("currentRoleid");
    params.selectedschoolid = localStorage.getItem("currentSchoolid");
    let result = this.axiosInstance.post(url, params);
    // if (result.status === 401){
    //   let refreshtoken = localStorage.getItem( 'refreshtoken' );
    //   this.post(APIStandards.GUEST.REFRESH, {
    //     refresh: refreshtoken
    //   })
    //     .then((data: any) => {
    //       let token = data.data.token;
    //       let refresh = data.data.refresh;
    //       localStorage.setItem("token", token);
    //       localStorage.setItem("refreshtoken", refresh);
    //       this.axiosInstance.headers = {
    //         Authorization: "Bearer " + token,
    //         "Content-Type": "application/json",
    //       }
    //       result = this.axiosInstance.post(url, params);
    //     })
    //     .catch((err: any) => {
    //       message.error("Some problem happened while getting the new access token. Please close the browser and login again.");
    //     });
    // }
    return result;
  }
}

export default new CommunicationService();
