import { SaveFilled } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { get_content_title } from "src/utils/helpers";

export default function LessonCreation({ lessonType, onSave }: any) {
  const [lessonData, setLessonData] = useState({
    lessonNumber: "",
    lessonName: "",
  });
  return (
    <>
      <Row>
        <Col>
          <Typography.Title level={5}>
            Create new {get_content_title(lessonType)?.title}
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form>
            <Form.Item
              label={get_content_title(lessonType)?.title + " number"}
              help="Please enter chapter and topic number in number format without any prefix, eg: 01,02, 04 and please enter Learning Objective/Learning Outcome code as complete code, eg: S06.01.LS.01.1"
            >
              <Input
                placeholder={
                  "Please enter  " +
                  get_content_title(lessonType)?.title +
                  " number"
                }
                onChange={(event: any) => {
                  setLessonData({
                    ...lessonData,
                    lessonNumber: event.target.value,
                  });
                }}
              ></Input>
            </Form.Item>
            <Form.Item label={get_content_title(lessonType)?.title + " name"}>
              <Input
                placeholder={
                  "Please enter  " +
                  get_content_title(lessonType)?.title +
                  " name"
                }
                onChange={(event: any) => {
                  setLessonData({
                    ...lessonData,
                    lessonName: event.target.value,
                  });
                }}
              ></Input>
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                onClick={() => {
                  onSave(lessonData);
                }}
                icon={<SaveFilled />}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
