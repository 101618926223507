import {
  HomeTwoTone,
  UploadOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { GrCertificate } from "react-icons/gr";
import Layout, { Content } from "antd/es/layout/layout";
import React from "react";
import { HeaderC, Sidebar } from "src/components";
import Drawer_ from "src/components/common/layout/drawer/drawer";
import { GiOfficeChair, GiRobotAntennas, GiTeacher } from "react-icons/gi";
import { SiGoogleclassroom } from "react-icons/si";
import { MdSpaceDashboard } from "react-icons/md";
import { Outlet } from "react-router-dom";
import { FaCertificate } from "react-icons/fa";
import { AiFillExperiment } from "react-icons/ai";

const StudentMaster: React.FC = () => {
  const menu: any = [
    {
      key: "/u/student/home",
      icon: <MdSpaceDashboard />,
      label: "Home",
    },
    {
      key: "/u/student/subjects",
      icon: <SiGoogleclassroom />,
      label: "Curriculum",
    },
    {
      key: "/u/student/courses",
      icon: <FaCertificate />,
      label: "Courses",
    },
    {
      key: "/u/student/projects",
      icon: <AiFillExperiment />,
      label: "Projects",
    },
    {
      key: "/u/student/intership",
      icon: <GiOfficeChair />,
      label: "Internship",
    },
    {
      key: "/u/student/products",
      icon: <GiRobotAntennas />,
      label: "Products",
    },
  ];
  return (
    <Layout>
      <div className="main-sidebar">
        <Sidebar menuItem={menu}></Sidebar>
      </div>
      <Layout className="site-layout">
        <HeaderC></HeaderC>
        <Content
          style={{
            margin: "0px 16px",
            padding: 24,
            overflow: "scroll",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Drawer_ menuItem={menu}></Drawer_>
    </Layout>
  );
};

export default StudentMaster;
