import { EditFilled, PlusOutlined, SaveFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  List,
  message,
  Modal,
  Row,
  // Slider,
  Space,
  Typography,
} from "antd";
import { SliderTooltipProps } from "antd/es/slider";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import ContentManagementServices from "src/services/content_management_services";
import ContentStandards from "src/utils/content_standards";
import { json } from "stream/consumers";
import Quizz from "../../quizz/quizz";
import "./trim_video.css";

export default function TrimVideo(props: any) {
  const [totalDuration, setTotalDuration] = useState(0);
  const [player, setPlayer] = useState<any>(false);
  const [playerTimes, setPlayerTimes] = useState({
    start: 0,
    end: 0,
    currentTime: 0,
  });
  const [onSeeking, setOnSeeking] = useState(false);
  const [seekValue, setSeekValue] = useState();
  const [quizModelOpen, setQuizModelOpen] = useState(false);
  const [quizData, setQuizData] = useState<any>([]);
  const [activeQuizData, setActiveQuizData] = useState<any>([]);

  const [opts, setOpts] = useState({
    height: "390",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      start: playerTimes.start,
      end: playerTimes.end,
      controls: 0,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      enablejsapi: 1,
    },
  });

  const formatSeekerTime: any = (seconds: any) => {
    return new Date(seconds * 1000).toISOString().substring(11, 19);
  };

  const saveVideo = async () => {
    const video_metadata = {
      url: props.selectedVideo.id.videoId,
      trimRange: playerTimes,
      questions: quizData,
    };
    await ContentManagementServices.addContent(
      ContentStandards.VIDEO,
      "",
      JSON.stringify(video_metadata)
    );
    message.success("Video has been added successfully!");
  };

  var k: any;

  function _onReady(event: any) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    setTotalDuration(event.target.getDuration());
    setPlayerTimes({ ...playerTimes, end: event.target.getDuration() });
    setPlayer(event.target);
    setInterval(() => {
      const k = event.target.getCurrentTime();
      if (k) {
        setPlayerTimes({
          ...playerTimes,
          currentTime: k,
        });
      }
    }, 1000);
  }

  function _onPlay(event: any) {
    console.log({ event });
  }

  return (
    <div>
      {!props.selectedVideo && (
        <Typography.Title>Please select a video to continue</Typography.Title>
      )}
      {props.selectedVideo && (
        <>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <YouTube
                    videoId={props.selectedVideo.id.videoId}
                    opts={opts}
                    onReady={_onReady}
                    onPlay={_onPlay}
                  />
                </Col>
                {player && (
                  <>
                    {/* <Col span={24}>
                      <Slider
                        max={totalDuration}
                        className="seek-slider"
                        step={1}
                        value={onSeeking ? seekValue : playerTimes.currentTime}
                        tooltip={{ formatter: formatSeekerTime, open: true }}
                        onAfterChange={(value: any) => {
                          setOnSeeking(false);
                          if (
                            value >= playerTimes.start &&
                            value <= playerTimes.end
                          ) {
                            player.seekTo(value);
                          } else {
                            player.seekTo(playerTimes.start);
                          }
                        }}
                        onChange={(value: any) => {
                          setOnSeeking(true);
                          if (k) {
                            clearInterval(k);
                          }
                          k = setTimeout(() => {
                            if (
                              value >= playerTimes.start &&
                              value <= playerTimes.end
                            ) {
                              setPlayerTimes({
                                ...playerTimes,
                                currentTime: value,
                              });
                              setSeekValue(value);
                              player.seekTo(value);
                            } else {
                              setPlayerTimes({
                                ...playerTimes,
                                currentTime: playerTimes.start,
                              });
                              player.seekTo(playerTimes.start);
                            }
                          }, 100);
                        }}
                      />
                    </Col> */}
                    {/* <Col span={24}>
                      <Slider
                        range
                        className="trim-slider"
                        max={totalDuration}
                        tooltip={{ formatter: formatSeekerTime }}
                        defaultValue={[0, totalDuration]}
                        onChange={(value) => {
                          setTimeout(() => {
                            setPlayerTimes({
                              ...playerTimes,
                              start: value[0],
                              end: value[1],
                              currentTime: value[0],
                            });

                            if (
                              playerTimes.currentTime <= value[0] ||
                              playerTimes.currentTime >= value[1]
                            ) {
                              player.seekTo(value[0]);
                            }
                          }, 50);
                        }}
                      />
                    </Col> */}
                  </>
                )}
              </Row>
            </Col>
            {/* <Col span={12} className="pl-3">
              <List
                header={
                  <Typography.Title level={5}>Added Questions</Typography.Title>
                }
                footer={
                  <div>
                    {" "}
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      block
                      onClick={() => {
                        const existingQuestion = quizData.filter(
                          (item: any) => item[0] == playerTimes.currentTime
                        );
                        setActiveQuizData([...existingQuestion]);
                        setQuizModelOpen(true);
                      }}
                    >
                      New Question
                    </Button>
                  </div>
                }
                bordered
                dataSource={quizData}
                renderItem={(item: any, idx) => (
                  <List.Item>
                    <>
                      <Space>
                        <Typography.Text mark>
                          [{formatSeekerTime(item[0])}]
                        </Typography.Text>{" "}
                        Question {idx + 1}
                      </Space>
                      <Button
                        icon={<EditFilled />}
                        onClick={() => {
                          const existingQuestion = quizData.filter(
                            (questions: any) => questions[0] == item[0]
                          );
                          setActiveQuizData([...existingQuestion[0][1]]);
                          setQuizModelOpen(true);
                        }}
                      >
                        Edit Question
                      </Button>
                    </>
                  </List.Item>
                )}
              />
            </Col> */}
          </Row>
          <Row className="mt-4">
            <Col span={24}>
              <Button
                type="primary"
                className="float-right"
                icon={<SaveFilled />}
                onClick={saveVideo}
              >
                Save Video
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Modal
        footer={[]}
        width="95%"
        closable
        open={quizModelOpen}
        onCancel={() => {
          setQuizModelOpen(false);
        }}
        destroyOnClose={true}
      >
        <Quizz
          data={activeQuizData}
          onSave={(qstns: any) => {
            setQuizData([...quizData, [playerTimes.currentTime, qstns]]);
            setQuizModelOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
