import { Row, Col, message, Card, Space, Divider, Typography } from "antd";
import Search from "antd/es/input/Search";
import React, { useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";

export default function VideoChooser(props: any) {
  const [seachOngoing, setSearchOnGoing] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const searchVideo = (search_term: any) => {
    CommunicationService.post(APIStandards.TEACHER.SEARCH_YOUTUBE, {
      search: search_term,
    })
      .then((data: any) => {
        setSearchResults(data.data);
      })
      .catch((err: any) => {
        message.error("Something went wrong, please try again!");
      })
      .finally(() => {
        setSearchOnGoing(false);
      });
  };

  return (
    <div style={{ width: "100%", minHeight: "70vh" }}>
      <Row>
        <Col span={24}>
          <Search
            placeholder="Search something, eg: atom"
            enterButton="Search"
            size="large"
            loading={seachOngoing}
            onSearch={(value, event) => {
              setSearchOnGoing(true);
              searchVideo(value);
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={5}>
        Please select a video to continue
      </Typography.Title>
      <Row gutter={10}>
        {searchResults.map((item: any) => {
          return (
            <Col span={4}>
              <Card
                bordered={true}
                hoverable
                cover={
                  <img
                    alt="Thumbinal"
                    src={item.snippet.thumbnails.medium.url}
                  />
                }
                onClick={() => {
                  props.onVideoSelect(item);
                }}
              >
                <Typography.Text>{item.snippet.title}</Typography.Text>
                <br />
                <Typography.Text disabled>
                  {item.snippet.description}
                </Typography.Text>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
