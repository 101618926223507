import React, { useEffect, useState } from 'react';
import { TreeSelect, Switch, Space, notification } from 'antd';
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import user_types from "src/utils/user_types";

const treeData1 = [
  {
    value: 'parent 1',
    title: 'parent 1',
    children: [
      {
        value: 'parent 1-0',
        title: 'parent 1-0',
        children: [
          {
            value: 'leaf1',
            title: 'leaf1',
          },
          {
            value: 'leaf2',
            title: 'leaf2',
          },
        ],
      },
      {
        value: 'parent 1-1',
        title: 'parent 1-1',
        children: [
          {
            value: 'leaf3',
            title: <b style={{ color: '#08c' }}>leaf3</b>,
          },
        ],
      },
    ],
  },
];

export default function CurriculumCourseTreeSelect(props: any)  {
  const [value, setValue] = useState<string>();
  const [treeData, setTreeData] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails") || "{}").type
  );
  const [expandAll, setExpandAll] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  console.log("v3: curriculum_course_select dataType: ", props.dataType)

  // Update the TreeSelect when expandAll state changes
  // useEffect(() => {
  //   setTreeData((prevTreeData: any) => {
  //     // Update the existing treeData to set `isLeaf` to `false` when expandAll is true
  //     if (expandAll) {
  //       return prevTreeData.map((data: any) => {
  //         if (data.children) {
  //           data.children = data.children.map((child: any) => {
  //             return { ...child, isLeaf: false };
  //           });
  //         }
  //         return data;
  //       });
  //     }
  //     return prevTreeData;
  //   });
  // }, [expandAll]);
  
  const onChange = (newValue: string) => {
    console.log("v3: TreeSelect onchange called: ");
    setValue(newValue);
  };

  // Set treeDefaultExpandAll based on the expandAll state
  const onExpandChange = (checked: boolean) => {
    setExpandAll(checked);
  };

  const onSelect = (selectedValue: string, node: any) => {
    if (node.children === undefined) {
      console.log("Selected node is a Leaf: ", selectedValue);
      props.onSelect(selectedValue);
    } 
    else {
      openNotification();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser == user_types.Teacher) {
          const response = await CommunicationService.post(APIStandards.PUBLIC.GET_SUBJECTS, { dataType: props.dataType, treeLevel: props.treeLevel });
          console.log("v3: curriculum_course_select teacher treedata: ", treeData1)
          setTreeData(response.data.subjectlist);
        }
        else if (currentUser == user_types.Student) {
          const response = await CommunicationService.post(APIStandards.STUDENT.LIST_SUBJECTS, { dataType: props.dataType });
          console.log("v3: curriculum_course_select student treedata: ", treeData1)
          setTreeData(response.data.subjectlist);
        }
        // setTreeData(treeData1);
      } catch (error) {
        console.error('Error fetching tree data: ', error);
      }
    };

    fetchData();
  }, []);

  // filters node that matche search string
  const filterTreeNode = (filterValue:String,node:any) => {
    const nodeTitle = node.title.toLowerCase();
    const filterValueLower = filterValue.toLowerCase();
    return nodeTitle.includes(filterValueLower);
  };

  const openNotification = () => {
    api.open({
      message: 'Choose correct Node',
      description:
        'You have selected a Parent node! Please select the last/leaf node.',
      duration: 4
    });
  };

  return (
    <div>
    {/* // <Space direction='vertical' style={{ width: "100%" }}>
    //   <Switch
    //       checked={expandAll}
    //       onChange={onExpandChange}
    //     /> Expand All<br></br> */}
    {contextHolder}
      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Please select or search a topic - e.g. 'Electric' or '2.1'"
        allowClear
        treeDefaultExpandAll={expandAll}
        // onChange={onChange}
        treeData={treeData}
        onSelect={onSelect}
        treeExpandAction={"click"}
        filterTreeNode={filterTreeNode}
      />
    {/* // </Space> */}
    </div>
  );
};

