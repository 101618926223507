import React, { useEffect, useState } from "react";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import { useParams } from "react-router-dom";
import ContentStandards from "src/utils/content_standards";
import { Collapse, List, Typography } from "antd";
const { Panel } = Collapse;

export default function Lessons({ match }: any) {
  const [lessons, setLessons] = useState<any>({});
  const { lessonId }: any = useParams();
  const [activeStrand, setActiveStrand] = useState<any>("");

  useEffect(() => {
    get_strands_for_subjects();
  }, []);

  const SideBar: any = (data: any) => {
    return (
      <Collapse
        accordion={true}
        onChange={(key: string | string[]) => {
          get_chapters_for_strand(key);
          setActiveStrand(key);
        }}
        activeKey={activeStrand}
      >
        {lessons[lessonId] &&
          lessons[lessonId].map((strand: any) => {
            return (
              <Panel key={strand._id} header={strand.title}>
                <Collapse
                  onChange={(key: string | string[]) => {
                    get_topics_for_chapter(key);
                    setActiveStrand(key);
                  }}
                >
                  {lessons[strand._id] &&
                    lessons[strand._id].map((chapter: any) => {
                      return (
                        <Panel key={chapter._id} header={chapter.title}>
                          <List
                            bordered
                            dataSource={lessons[chapter._id]}
                            renderItem={(item: any) => (
                              <List.Item>
                                <Typography.Text mark>
                                  {item.number}
                                </Typography.Text>
                                {item.title}
                              </List.Item>
                            )}
                          />
                        </Panel>
                      );
                    })}
                </Collapse>
              </Panel>
            );
          })}
      </Collapse>
    );
  };

  const get_lessons = () => {};

  const get_strands_for_subjects = () => {
    CommunicationService.post(APIStandards.STUDENT.LIST_LESSONS, {
      parent_id: lessonId,
      lesson_type: ContentStandards.STRAND,
    }).then((response: any) => {
      let new_lessons = { ...lessons };
      new_lessons[lessonId] = response.data.lessons;
      setLessons(new_lessons);
    });
  };

  const get_chapters_for_strand = (strand_id: any) => {
    CommunicationService.post(APIStandards.STUDENT.LIST_LESSONS, {
      parent_id: strand_id,
      lesson_type: ContentStandards.CHAPTER,
    }).then((response: any) => {
      let new_lessons = { ...lessons };
      new_lessons[strand_id] = response.data.lessons;
      setLessons(new_lessons);
    });
  };

  const get_topics_for_chapter = (chapter_id: any) => {
    CommunicationService.post(APIStandards.STUDENT.LIST_LESSONS, {
      parent_id: chapter_id,
      lesson_type: ContentStandards.TOPIC,
    }).then((response: any) => {
      let new_lessons = { ...lessons };
      new_lessons[chapter_id] = response.data.lessons;
      setLessons(new_lessons);
    });
  };

  return (
    <div>
      <SideBar></SideBar>
    </div>
  );
}
