import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  List,
  message,
  Modal,
  Row,
  Space,
  Typography,
  Spin,
  Button,
  Table,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import "./course.css";
import CommunicationService from "src/services/communication_service";
import APIStandards from "src/utils/API_standards";
import PreviewModal from "src/components/common/content_viewer/preview_modal";
import CourseSyllabusDropdowns from "src/components/common/syllabus_dropdowns/course_syllabus_dropdowns";
import CurriculumCourseTreeSelect from "src/components/common/curriculum_course_select/curriculum_course_select";

export default function Course() {
  const [cardData, setCardData] = useState<any>({});
  const [topics, setTopics] = useState<any>({});
  const [learningModal, setLearningModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedData, setSelectedData] = useState<any>({});
  const [slideData, setSlides] = useState<any>([{}, {}]);
  const [loading, setLoading] = useState<boolean>(false);
  const [learnsessionid, setLearningSessionid] = useState<string>('');

  const [reviewResultscsvData, setreviewResultsCsvData] = useState<any[]>([]);
  const [isreviewResultsCsvModalVisible, setIsreviewResultsCsvModalVisible] = useState(false);
  const reviewresultsviewcolumns = [
    {
      title: "Type",
      dataIndex: "qtype",
      key: "qtype",
    },
    {
      title: "Question ID",
      dataIndex: "question_id",
      key: "question_id",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Max Marks",
      dataIndex: "maxMarks",
      key: "maxMarks",
    },
    {
      title: "Answers",
      dataIndex: "answers",
      key: "answers",
    },
    {
      title: "Review Marks",
      dataIndex: "reviewMarks",
      key: "reviewMarks",
    },
    // {
    //   title: "Content ID",
    //   dataIndex: "contentid_id",
    //   key: "contentid_id",
    // },
    {
      title: "Review Comments",
      dataIndex: "reviewComments",
      key: "reviewComments",
    },
  ];
 
  const getTopics = (chapter: string) => {
    const selectData: any = {
      ...JSON.parse(localStorage.getItem("selectedDropdowns") || "{}"),
      Chapter: chapter,
    };

    setSelectedData({ ...selectData });

    CommunicationService.post(APIStandards.PUBLIC.GET_DROPDOWN_VALUES, {
      ...selectData,
      selected_data: selectData,
      target: ["Topic / Concept"],
    }).then((response: any) => {
      setTopics(response.data);
      setLearningModal(true);
    });
  };

  useEffect(() => {
    console.log("v3: learnsessionid after setLearningSessionid: ", learnsessionid);
    // perform side effects here after the state update has been completed
  }, [learnsessionid]);

  const getSlidesData = (topic: any) => {
    const selectData: any = { ...selectedData, "Topic / Concept": topic };
    let m = [{}, {}];

    CommunicationService.post(APIStandards.PUBLIC.GET_SECONDARY_LIST, {
      ...selectData,
      selected_data: selectData,
    })
      .then((response: any) => {
        let slide_data = m;
        slide_data[1] = {
          title: "Learning Objectives",
          data: response.data[Object.keys(response.data)[0]],
        };
        setSlides([...slide_data]);
      })
      .finally(() => {
        CommunicationService.post(APIStandards.PUBLIC.GET_TERITIARY_LIST, {
          ...selectData,
          selected_data: selectedData,
        })
          .then((response: any) => {
            let slide_data = m;
            slide_data[0] = {
              title: "Learning Outcomes",
              data: response.data[Object.keys(response.data)[0]],
            };
            setSlides([...slide_data]);
          })
          .finally(() => {
            CommunicationService.post(APIStandards.STUDENT.LIST_LESSONS, {
              ...selectData,
            }).then((response: any) => {
              let slide_data = m;
              let new_lessons: any = [];

              response.data.lessons.forEach((item: any) => {
                item.contents.forEach((content: any) => {
                  new_lessons.push({ ...item, content });
                });
              });

              setSlides([...slide_data, ...new_lessons, { finished: true }]);
            });
          });
      });
  };

  globalThis.videoRef= useRef<HTMLVideoElement>(null);

  const togglePause = () => {
    console.log("videoRef during pause: ", globalThis.videoRef);
    // setIsPaused(!isPaused);
    if (globalThis.videoRef.current) {
      globalThis.videoRef.current.pause();
    }
  };

  const onSelectHandler = (value: string) => {
    setLoading(true);
    console.log("v3: classes onSelectHandler value: ", value)
    localStorage.setItem("selectedtopic", value);
    setSelectedTopic(value);
    setLearningSessionid('');
    localStorage.setItem("studentlearningsessionid", '');
    let m = [{}, {}];
    CommunicationService.post(APIStandards.PUBLIC.GET_TOPIC_DETAILS, {
      selectedtopic: value,
    }).then((response: any) => {
      // setEnableAddContent(true);
      // setLearningObjectives(response.data.learningobjectives);
      // setLearningOutcomes(response.data.learningoutcomes)
      localStorage.setItem("tabs_data", JSON.stringify(response.data.tabsdata));
      localStorage.setItem("topiclob_data", JSON.stringify(response.data.topiclob));
      let slide_data = m;
      slide_data[0] = {
        title: "Learning Outcomes",
        data: response.data.learningoutcomes,
      };
      slide_data[1] = {
        title: "Learning Objectives",
        data: response.data.learningobjectives,
      };
      setSlides([...slide_data]);
      localStorage.setItem("slidedata", slide_data.toString());
      // setEnableAddContent(true);
    })
    .finally(() => {
      CommunicationService.post(APIStandards.TEACHER.GET_LESSON_PREVIEW, {
        selectedtopic: value,
      }).then((response: any) => {
        let slide_data = m;
        let new_lessons: any = [];

        response.data.lessons.forEach((item: any) => {
          item.contents.forEach((content: any) => {
            new_lessons.push({ ...item, content });
          });
        });

        setSlides([...slide_data, ...new_lessons, { finished: true }]); 
      });
    });
    setLoading(false);
  }

const handleDownloadResult = async () => {
  // console.log("v3: Starting download for:", record);
  CommunicationService
   .post(APIStandards.TEACHER.GET_MY_REVIEW_RESULTS, {
      selectedtopic: localStorage.getItem("selectedtopic"),
    })
   .then((response: any) => {
      const csvData = response.data;
      setreviewResultsCsvData(csvData);
      setIsreviewResultsCsvModalVisible(true);
    })
   .catch((error: any) => {
      console.error("Error fetching review results: ", error);
    });
};

const handlereviewResultsCsvModalOk = () => {
  setIsreviewResultsCsvModalVisible(false);
};

const handlereviewResultsCsvModalCancel = () => {
  setIsreviewResultsCsvModalVisible(false);
};

  return (
    <div>
      <Row>
        <Col>
          <Space direction="vertical">
          <Typography.Text style= {{fontSize:16}}>
            Explore the menu below to discover and choose the learning topic you'd like to start with. 
            Simply click on each section (<CaretRightOutlined /> and <CaretDownOutlined />) to reveal more options until you find your specific topic of interest.
            </Typography.Text>
            {/* <CourseSyllabusDropdowns
              onSelect={(data: any) => {
                setCardData(data);
              }}
              numberOfDropDownsToSubtract={2}
            ></CourseSyllabusDropdowns> */}
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <CurriculumCourseTreeSelect onSelect={onSelectHandler} dataType={'STEAM'} />
              <Spin spinning={loading} size={"large"} />
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col span={6}>
          <Button
            type="primary"
            disabled={
              slideData.length <= 3 ||
              // !dropdownSelectData.subject ||
              // !dropdownSelectData.standard ||
              // Object.keys(syllabusDropdowns).some(
              //   (dropdown) => !dropdownSelectData[dropdown]
              // ) ||
              !slideData[2]?.content
              // !Object.keys(dropdownSelectData).includes("division")
            }
            size="large"
            block
            onClick={() => {
              setLearningModal(true);
              let localsessionid = '';
              console.log("v3: before sessionid: ", learnsessionid);
              console.log("v3: storage sessionid: ", localStorage.getItem("studentlearningsessionid"));
              if (localStorage.getItem("studentlearningsessionid")  === null) {
                console.log("v3: learning session id entering null: ");
                setLearningSessionid('');
                localsessionid = '';
                }
              else {
                console.log("v3: learning session id entering else n: ");
                setLearningSessionid(localStorage.getItem("studentlearningsessionid") || "");
                localsessionid = localStorage.getItem("studentlearningsessionid") || "";
              }
              console.log("v3: after sessionid: ", learnsessionid);
              CommunicationService.post(APIStandards.STUDENT.LOG_LEARNING, {
                academicyear: "S2024-S2025",
                pedagogy: "STEM",
                semester: "Steamcube School 1 Year Semester",
                selectedtopic: selectedTopic,
                mode: "start",
                studentlearningsessionid: localsessionid,
              }).then((result: any) => {
                console.log("v3: setlearningmodal: ",result);
                setLearningSessionid(result.data.studentsessionid);
                localStorage.setItem("studentlearningsessionid", result.data.studentsessionid);
              }).catch((err:any)=>{
                setLearningSessionid("");
                console.error(err)}
              );
            }}
          >
            Click here to start Learning
          </Button>
        </Col>
        <Divider type="vertical" style={{ height: "40px", borderWidth: 3, borderColor: 'darkgrey' }}></Divider>
        <Col span={6}>
          <Button
            type="primary"
            disabled={
              slideData.length <= 3 ||
              !slideData[2]?.content
            }
            size="large"
            block
            onClick={handleDownloadResult}
          >
            Click here to view results
          </Button>
        </Col>
      </Row>
      {/* <Divider />
      {Object.keys(cardData).length > 0 && (
        <Row>
          <Col span={24}>
            <List
              header={
                <div>
                  <Typography.Title level={5}>
                    Please select a Module to continue
                  </Typography.Title>
                </div>
              }
              footer={
                <div>
                  {" "}
                  <Typography.Title level={5}>
                    End Of This Module
                  </Typography.Title>
                </div>
              }
              bordered
              dataSource={cardData[Object.keys(cardData)[0]]}
              renderItem={(item: any) => (
                <List.Item
                  onClick={() => {
                    getTopics(item);
                  }}
                  className="chapter-item"
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text>{item}</Typography.Text>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )} */}

      <Modal
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          top: "10px",
          left: "0px",
          right: "0px",
        }}
        width={"100%"}
        open={learningModal}
        closable={true}
        onCancel={() => {
          setLearningModal(false);
          localStorage.setItem("studentlearningsessionid", '');
          togglePause();
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Row style={{ height: "90vh" }}>
          {/* <Col span={4} style={{ overflow: "scroll", height: "100%" }}>
            <List
              footer={null}
              header={
                <>
                  <Typography.Title level={5}>Topics</Typography.Title>
                </>
              }
              dataSource={topics[Object.keys(topics)[0]]}
              renderItem={(item: any) => (
                <List.Item
                  onClick={() => {
                    setSelectedTopic(item);
                    const selectData: any = {
                      ...selectedData,
                      "Topic / Concept": item,
                    };
                    setSelectedData({ ...selectData });
                    getSlidesData(item);
                  }}
                  className={
                    item == selectedTopic ? "selected-topic" : "chapter-item"
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text>{item}</Typography.Text>
                </List.Item>
              )}
            ></List>
          </Col> */}
          <Col span={20}>
            <PreviewModal
              lessons={slideData}
              selectData={selectedData}
            ></PreviewModal>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Reveiw Results Data"
        open={isreviewResultsCsvModalVisible}
        // onOk={handlereviewResultsCsvModalOk}
        onCancel={handlereviewResultsCsvModalCancel}
        width={"95%"}
        // okButtonProps={{disabled: true}}
        footer={null}
      >
        <Table dataSource={reviewResultscsvData} columns={reviewresultsviewcolumns} />
      </Modal>
    </div>
  );
}
